/* eslint-disable max-len */
import {
  React, useContext, useState, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import {
  Button, Divider, Typography, List, ListItem, ListItemIcon, ListItemText,
  Modal, Grid, Badge, Chip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { UserContext } from './UserContext';
import ToolBar from './toolBar';
import MyAlert from './myAlert';
import '../assets/paymentOptionsPage.css';

// Database functions
import {
  getPayingCustomers,
  getSubscriptions,
  changeTierStripe,
  cancelSubscription,
  axiosGetSomeCities,
  axiosSetCurrentAndNextPaidLocations,
} from '../modules/api';

export default function ChangeTierPage() {
  const [infoShow, setInfoShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [cityPickerShow, setCityPickerShow] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [newTierState, setNewTierState] = useState('');
  const {
    nextLocationsPaidFor, setNextLocationsPaidFor, subscriptionTier, email,
  } = useContext(UserContext);
  const [userPaidLocationInfo, setUserPaidLocationInfo] = useState([]);

  const navigate = useNavigate();

  // a way of comparing subscription types to see if it is an upgrade or downgrade
  const hierarchy = {
    /* eslint-disable */
    'Free': 1,
    'Sailvest Basic': 1.25,
    'Sailvest Plus': 3,
    'Sailvest Premium': 5,
    'Enterprise': 10,
  };

  // Style of modal
  const style = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 550,
    height: 'auto',
    maxHeight: '80vh',
    border: '2px solid black',
  };

  const paperStyle = {
    padding: '25px',
    borderRadius: '10px',
    // margin: '100px',
    width: '90%',
  };

  const paymentOptionPaperStyle = {
    border: '2px solid #144a77',
    backgroundColor: 'white',
    color: 'black',
    margin: '10px',
    minWidth: '225px',
    minHeight: '300px',
    padding: '10px',
  };

  function handleSubscribeClick(newTier) {
    setNewTierState(newTier);

    // Determine if user is downgrading and needs to select the cities they remain subscribed to
    let upgrade;
    if (hierarchy[newTier] >= hierarchy[subscriptionTier]) {
      upgrade = true;
    } else {
      upgrade = false;
    }

    if (!upgrade) {
      setCityPickerShow(true);
    } else {
      if (newTier === 'Free') {
        setInfoMessage('Are you sure you want to cancel your paid subscription?');
      } else {  
        setInfoMessage('Are you sure you want to change your subscription?');
      }
      setInfoShow(true);
    } 
  }

  // Get info for all locations that user paid for
  useEffect(() => {
    axiosGetSomeCities(nextLocationsPaidFor).then((result) => {
      setUserPaidLocationInfo(result.data);
    });
    // if (locationsPaidFor && nextLocationsPaidFor && nextLocationsPaidFor.length > 0) {

    // }
  }, [nextLocationsPaidFor]);

  function handleDowngradeSubmit() {
    axiosSetCurrentAndNextPaidLocations(localStorage.getItem('currUserId'), nextLocationsPaidFor).then(() => {
      // Navigate to stripe link if paid location updating was successful
      setCityPickerShow(false);
      executeChangeSubscription();
    }).catch(() => {
      // Handle error
    });
    ;
  }

  async function executeChangeSubscription() {
    // direct them to payments page if their current subscription is the free one
    if (subscriptionTier === 'Free') {
      if (newTierState === 'Sailvest Basic') {
        window.location.replace(`https://buy.stripe.com/3csg1c1fRh2R1LW5kl?prefilled_email=${email}`);
      } else if (newTierState === 'Sailvest Plus') {
        window.location.replace(`https://buy.stripe.com/eVa2amaQr4g53U4eUY?prefilled_email=${email}`);
      } else if (newTierState === 'Sailvest Premium') {
        window.location.replace(`https://buy.stripe.com/dR6dT4aQr4g50HS28d?prefilled_email=${email}`);
      } else {
        window.location.href = '/account';
      }
    } else {
      const payingCustomers = await getPayingCustomers();
      const allSubscriptions = await getSubscriptions();

      /* eslint-disable */
      const customerStripeId = payingCustomers.data.data.filter((obj) => {
        return obj.email === email;
      })[0].id;

      const subscriptionId = allSubscriptions.data.data.data.filter((obj) => {
        return obj.customer === customerStripeId;
      })[0].id;

      if (newTierState === 'Free') {
        // Cancel the subscription
        cancelSubscription(subscriptionId);
      } else {
        changeTierStripe(subscriptionId, newTierState);
      }

      setShowAlert(true);
      setTimeout(() => navigate('/account'), 4000);
      
    }
    setNewTierState(null);
    setInfoMessage('');
    setInfoShow(false);
  }

  return (
    <div className="paymentOptionsPage">
      <ToolBar isLoggedIn />
      <MyAlert show={showAlert} setShow={setShowAlert} severity="success" message="Subscription successfully changed and billing updated. Now navigating to Account Page..." />
      <Paper elevation={1} sx={paperStyle}>
        <h2 style={{ marginBottom: '25px' }}><b>Subscription Tiers</b></h2>
        <div className="paymentOptionsContainer">
          <Paper elevation={4} sx={paymentOptionPaperStyle} style={{ backgroundColor: subscriptionTier === 'Free' ? '#CCE5FF' : '' }}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Free</b></Typography>
            <Typography variant="h4">$0</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              disabled={subscriptionTier === 'Free'}
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={subscriptionTier === 'Free' ? () => {} : () => { handleSubscribeClick('Free'); }}
            >
              {subscriptionTier === 'Free' ? 'Current Plan' : 'Subscribe'}
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for 3 properties in 1 town"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle} style={{ backgroundColor: subscriptionTier === 'Sailvest Basic' ? '#CCE5FF' : '' }}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Basic</b></Typography>
            <Typography variant="h4">$10</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              disabled={subscriptionTier === 'Sailvest Basic'}
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={subscriptionTier === 'Sailvest Basic' ? () => {} : () => { handleSubscribeClick('Sailvest Basic'); }}
            >
              {subscriptionTier === 'Sailvest Basic' ? 'Current Plan' : 'Subscribe'}
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 1 town"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle} style={{ backgroundColor: subscriptionTier === 'Sailvest Plus' ? '#CCE5FF' : '' }}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Plus</b></Typography>
            <Typography variant="h4">$25</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              disabled={subscriptionTier === 'Sailvest Plus'}
              variant="contained"
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={subscriptionTier === 'Sailvest Plus' ? () => {} : () => { handleSubscribeClick('Sailvest Plus'); }}
            >
              {subscriptionTier === 'Sailvest Plus' ? 'Current Plan' : 'Subscribe'}
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 3 towns"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle} style={{ backgroundColor: subscriptionTier === 'Sailvest Premium' ? '#CCE5FF' : '' }}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Premium</b></Typography>
            <Typography variant="h4">$40</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              disabled={subscriptionTier === 'Sailvest Premium'}
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={subscriptionTier === 'Sailvest Premium' ? () => {} : () => { handleSubscribeClick('Sailvest Premium'); }}
            >
              {subscriptionTier === 'Sailvest Premium' ? 'Current Plan' : 'Subscribe'}
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 5 towns"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Recommended markets based on Sailvest market data analysis"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Enterprise</b></Typography>
            <Typography variant="h4">---</Typography>
            <Typography variant="caption">Per month</Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: '30px', }}
            >
              Email us at sailvest.contact@gmail.com
            </Typography>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in more than 5 towns"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Recommended markets based on Sailvest market data analysis"
                />
              </ListItem>
            </List>
          </Paper>
        </div>
      </Paper>
      <Modal
        open={cityPickerShow}
        onClose={() => setCityPickerShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: '5px', padding: '15px' }}
          sx={style}
        >
          <Typography id="modal-modal-title" variant="h6">
            <strong>
              Choose which cities you would like to remain subscribed to.
            </strong>
          </Typography>
          <Typography id="modal-modal-title" variant="caption">
            Note: Choose up to {Math.floor(hierarchy[newTierState])} max.
          </Typography>
          <Grid container spacing={2}  sx={{marginTop: '5px'}}>
                    {userPaidLocationInfo && userPaidLocationInfo.map((cityInfo) => 
                    <Grid key={cityInfo._id} item >
                      {/* <Paper elevation={2} sx={{borderRadius: '10px', backgroundColor: '#f4f4f4', padding: '10px', }}>
                        <div className="sideBySide centeredRow" style={{justifyContent: 'space-between'}}>
                          <div>
                            <Typography><b>{`${cityInfo.city}, ${cityInfo.state_id}`}</b></Typography>
                        <Typography color={theme.palette.primary.main} variant="body2"><em>{`${cityInfo.county_name} County`}</em></Typography>
                        </div>
                        <IconButton onClick={handleDelete}>
                        <HighlightOffIcon color="error"/>
                        </IconButton>
                        
                        </div>
                        
                      </Paper> */}
                      <Badge color="secondary" variant="dot" >
                      <Chip
                      onDelete={() => setNextLocationsPaidFor(nextLocationsPaidFor.filter((location) => location !== cityInfo._id))}
                    label={<Typography variant="subtitle2">
                      <b>{`${cityInfo.city}, ${cityInfo.state_id}`}</b>
                    {` | `}
                    <em>{`${cityInfo.county_name} County`}</em></Typography>}
                    sx={{ backgroundColor: "#eeeeee", color: "black" , }}
                  />
                      </Badge>
                    
                    </Grid>)}
                  </Grid>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '3em' }}
          />
          <Button
            onClick={() => handleDowngradeSubmit()}
            variant="contained"
            disabled={(nextLocationsPaidFor.length > Math.floor(hierarchy[newTierState]))}
            color="primary"
          >
            Submit
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={infoShow}
        onClose={() => setInfoShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: '5px', padding: '15px' }}
          sx={style}
        >
          <Typography id="modal-modal-title" variant="h6">
            <strong>
              {infoMessage}
            </strong>
          </Typography>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '3em' }}
          />
          <Button
            onClick={() => setInfoShow(false)}
            sx={{ marginRight: '1em' }}
            variant="contained"
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => executeChangeSubscription()}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}
