/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import '../assets/researchPage.css';
import '../assets/comparisonView.css';
import {
  styled,
  TableContainer,
  Table, TableRow, TableBody, TableHead, TableCell, Paper, tableCellClasses,
  TextField, Autocomplete, Box, CircularProgress, Typography,
} from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ResearchContext } from './ResearchContext';
import { axiosFindRegionRecord, axiosFindHistoricalRegionData } from '../modules/api';
import { allMetrics, formatMetricValue } from '../utils/allMetrics';
import BookmarkButton from './bookmarkButton';
import { getRegionRecordValue } from '../utils/explorePageFunctions';
import ToolBar from './toolBar';
import AdvancedSideBar from './advancedSideBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eee',
    color: theme.palette.common.black,
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

export default function ComparisonView() {
  const [selectedRegion1, setSelectedRegion1] = useState('63e4478fe9a9a32574f1b0ac');
  const [selectedRegion2, setSelectedRegion2] = useState('63e4478de9a9a32574f1b09f');
  const [region1Record, setRegion1Record] = useState('');
  const [region2Record, setRegion2Record] = useState('');

  const { regions } = useContext(ResearchContext);

  useEffect(() => {
    if (selectedRegion1 !== '') {
      const source = axios.CancelToken.source();

      // Temp value between loads
      setRegion1Record('');

      axiosFindRegionRecord(selectedRegion1, source).then(
        (regionRecordResult) => {
          axiosFindHistoricalRegionData(selectedRegion1, 'fed_funds_rate', source).then((fedFunds) => {
            const preData = regionRecordResult.data;

            const fedFundsData = fedFunds.data;
            preData.fed_funds_rate = fedFundsData[fedFundsData.length - 1].fed_funds_rate;
            setRegion1Record(preData);
          });
        },
      ).catch((e) => {
        console.log(e);
      });
    }
  }, [selectedRegion1]);

  useEffect(() => {
    if (selectedRegion2 !== '') {
      const source = axios.CancelToken.source();

      // Temp value between loads
      setRegion2Record('');

      axiosFindRegionRecord(selectedRegion2, source).then(
        (regionRecordResult) => {
          axiosFindHistoricalRegionData(selectedRegion2, 'fed_funds_rate', source).then((fedFunds) => {
            const preData = regionRecordResult.data;

            const fedFundsData = fedFunds.data;
            preData.fed_funds_rate = fedFundsData[fedFundsData.length - 1].fed_funds_rate;
            setRegion2Record(preData);
          });
        },
      ).catch((e) => {
        console.log(e);
      });
    }
  }, [selectedRegion2]);

  return (
    <div>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Comparison" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody"><strong>Comparison View</strong></Typography>
          <div className="mainBody">
            <Paper
              variant="outlined"
              style={{ borderRadius: '10px', padding: '0.6em' }}
            >
              {(regions === undefined || regions.length === 0) ? (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: 100,
                  height: '100%',
                  alignItems: 'center',
                }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 400 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell><b>Metric</b></StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="centeredRow" style={{ justifyContent: 'end' }}>
                            <Autocomplete
                              id="grouped-demo"
                              value={selectedRegion1}
                              onChange={(e, newValue) => { if (newValue !== '' && newValue !== null) { setSelectedRegion1(newValue); } }}
                              options={regions.sort((a, b) => -b.region_state.localeCompare(a.region_state)).map((region) => region._id)}
                              groupBy={(regionId) => (regions.filter((region) => region._id === regionId))[0].region_state}
                              getOptionLabel={(regionId) => `${(regions.filter((region) => region._id === regionId))[0].region_name}, ${(regions.filter((region) => region._id === regionId))[0].region_state}`}
                              sx={{ minWidth: '300px', marginRight: '5px' }}
                              renderInput={(params) => <TextField {...params} label="Region 1" />}
                              size="small"
                            />
                            <BookmarkButton selectedRegion={selectedRegion1} />
                          </div>

                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="centeredRow" style={{ justifyContent: 'end' }}>
                            <Autocomplete
                              id="grouped-demo"
                              value={selectedRegion2}
                              onChange={(e, newValue) => { if (newValue !== '' && newValue !== null) { setSelectedRegion2(newValue); } }}
                              options={regions.sort((a, b) => -b.region_state.localeCompare(a.region_state)).map((region) => region._id)}
                              groupBy={(regionId) => (regions.filter((region) => region._id === regionId))[0].region_state}
                              getOptionLabel={(regionId) => `${(regions.filter((region) => region._id === regionId))[0].region_name}, ${(regions.filter((region) => region._id === regionId))[0].region_state}`}
                              sx={{ minWidth: '300px', marginRight: '5px' }}
                              renderInput={(params) => <TextField {...params} label="Region 2" />}
                              size="small"
                            />
                            <BookmarkButton selectedRegion={selectedRegion2} />
                          </div>

                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(allMetrics).map((row) => (
                        <TableRow key={row}>
                          <StyledTableCell component="th" scope="row">
                            {allMetrics[row].display_name}
                          </StyledTableCell>
                          <StyledTableCell align="right">{formatMetricValue(getRegionRecordValue(region1Record, row), row)}</StyledTableCell>
                          <StyledTableCell align="right">{formatMetricValue(getRegionRecordValue(region2Record, row), row)}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
