/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ToolBar from './toolBar';
import '../assets/login.css';
import {
  axiosFindUsersWithGivenEmail,
  axiosSendResetCodeEmail,
} from '../modules/api';
import { RecoveryContext } from './loginPage';

export default function OTPInputPage() {
  const { email, otp, setPage } = useContext(RecoveryContext);
  const [timerCount, setTimer] = React.useState(60);
  const [OTPInput, setOTPInput] = useState('');
  const [disable, setDisable] = useState(true);

  function resendOTP() {
    axiosFindUsersWithGivenEmail(email).then((result) => {
      if (result.data.length === 0) {
        // console.log('Error Occurred');
      } else {
        axiosSendResetCodeEmail(email, 'Sailvest Verification Code', otp).then(() => {
          setDisable(true);
          setTimer(60);
        }).catch((err) => {
          // console.log('Sending Mail Error');
          // console.log(err);
        });
      }
    });
  }

  function verifyOTP() {
    if (parseInt(OTPInput, 10) === otp) {
      setPage('reset');
    } else {
      alert('Incorrect Code Entered');
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          setDisable(false);
          clearInterval(interval);
        }
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); // each count lasts for a second
    // cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  const paperStyle = {
    padding: '25px',
    width: '20%',
    minWidth: '350px',
    borderRadius: '10px',
    justifyContent: 'center',
    flex: 'column',
  };

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { marginTop: '25px', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Paper elevation={10} style={paperStyle}>
        <div style={{ display: 'flex', justifyContent: 'center ' }}>
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
        </div>
        <h2 style={{ marginBottom: '35px' }}>Enter Code</h2>
        <TextField
          label="Emailed Code"
          placeholder="Enter code"
          style={textFieldStyle}
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          value={OTPInput}
          onChange={(e) => setOTPInput(e.target.value)}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={verifyOTP}
        >
          Verify
        </Button>
        <div>
          <Typography variant="caption">Code not received?</Typography>
          <Button
            variant="text"
            size="small"
            disabled={disable}
            onClick={() => resendOTP()}
          >
            {disable ? `Resend OTP in ${timerCount}s` : 'Resend OTP'}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
