import {
  Grid, Paper, Typography, Box, styled, Button, Alert, CircularProgress,
} from '@mui/material';
import React, { useContext } from 'react';
import SendIcon from '@mui/icons-material/Send';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import PropertyBookmarkButton from './propertyBookmarkButton';
import '../assets/properties.css';
import { ResearchContext } from './ResearchContext';
import { formatMetricValue } from '../utils/allMetrics';

// Styling the tabs within the Single Market View (snapshot, fundamentals, etc)
const MyCustomButton = styled(Button)(() => ({
  color: 'primary',
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingRight: '15px',
  paddingLeft: '15px',
  borderRadius: '10px',
  fontSize: '14px',
  textTransform: 'none',
  // '&:hover': {
  //   backgroundColor: '#eee',
  //   color: 'black',
  // },
}));

function PropertySummary({ specificProperty }) {
  const { regions } = useContext(ResearchContext);
  const navigate = useNavigate();

  // console.log('this is the specific property');
  // console.log(specificProperty);

  if (!specificProperty || !regions) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <Alert severity="error">Property Not Found</Alert>
      </Box>
    );
  }

  if (specificProperty === 'Default' || !regions) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Paper
          sx={{
            borderRadius: '10px', position: 'relative', padding: '1em', textAlign: 'left',
          }}
        >
          <div className="sideBySideSpacedOut centeredRow">
            <div>
              <div className="centeredRow">
                <Typography variant="h5">
                  <b>
                    {specificProperty.house_number}
                    {' '}
                    {specificProperty.street}
                  </b>
                </Typography>
                <PropertyBookmarkButton propertyID={specificProperty._id} />
              </div>
              <Typography color="primary" variant="h6">{`${specificProperty.town}, ${specificProperty.state}`}</Typography>
            </div>
            <div>
              <MyCustomButton
                variant="contained"
                endIcon={<PublicIcon />}
                sx={{
                  textTransform: 'none', borderRadius: '10px', marginLeft: 'auto', marginRight: '10px',
                }}
                onClick={() => navigate(`/explore/${specificProperty.region_id}`)}
              >
                {`View ${(regions.filter((region) => region._id === specificProperty.region_id))[0].region_name} Details`}
              </MyCustomButton>
              <MyCustomButton
                variant="outlined"
                sx={{
                  '&:hover': {
                    backgroundColor: '#144a77',
                    color: 'white',
                  },
                }}
                onClick={() => { window.open(specificProperty.zillow_url, '_blank'); }}
                endIcon={<SendIcon />}
              >
                View Property on Zillow

              </MyCustomButton>
            </div>
          </div>

        </Paper>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper sx={{
          padding: '10px', borderRadius: '10px', position: 'relative', height: '100%',
        }}
        >
          <div className="propertyImage" style={{ backgroundImage: `url(${specificProperty.listing_image})` }} />
          <Paper sx={{
            position: 'absolute', padding: '10px', borderRadius: '10px', bottom: '50px', right: '50px', border: '1px gray solid',
          }}
          >
            <Typography sx={{ fontSize: '1.5em' }}><b>{`$${specificProperty.listing_price.toLocaleString('en-us')}`}</b></Typography>

          </Paper>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper sx={{
          padding: '10px', borderRadius: '10px', paddingBottom: '50px', height: 'auto',
        }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginBottom: '25px' }}>
            <Typography sx={{ fontSize: '22px' }}>
              <b>Key Info</b>
            </Typography>
          </Box>
          <Typography>
            <b>Monthly Rent:</b>
            {' '}
            {formatMetricValue(Math.abs(specificProperty.rent_zestimate.toFixed(2)), 'dollar')}
          </Typography>
          <Typography>
            <b>Bedrooms:</b>
            {' '}
            {specificProperty.beds}
          </Typography>
          <Typography>
            <b>Bathrooms:</b>
            {' '}
            {specificProperty.baths}
          </Typography>
          <Typography>
            <b>
              Sqft:
              {' '}
            </b>
            {specificProperty.size ? specificProperty.size : '---'}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PropertySummary;
