/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import ToolBar from './toolBar';
import '../assets/login.css';
import {
  axiosFindUsersWithGivenEmail,
  axiosSendResetCodeEmail,
  addNewUser,
} from '../modules/api';
import { RecoveryContext } from './loginPage';
import { SignUpContext } from './signupPage';
import MyAlert from './myAlert';

export default function SignUpOTPInputPage() {
  const {
    setEmail,
    email,
    otp,
    user,
  } = useContext(SignUpContext);
  const [timerCount, setTimer] = React.useState(60);
  const [OTPInput, setOTPInput] = useState('000000');
  const [disable, setDisable] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState('error');
  const [numWrongEntry, setNumWrongEntry] = useState(0);
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();

  async function addUser(newUser) {
    await addNewUser(newUser);
  }

  function resendOTP() {
    axiosSendResetCodeEmail(email, 'Sailvest Verification Code', otp).then(() => {
      setDisable(true);
      setTimer(60);
    }).catch((err) => {
      console.log('Sending Mail Error');
      console.log(err);
    });
  }

  function navigateToStripePaymentsPage(subscriptionTier) {
    if (subscriptionTier === 'Sailvest Basic') {
      window.location.replace(`https://buy.stripe.com/3csg1c1fRh2R1LW5kl?prefilled_email=${email}`);
    } else if (subscriptionTier === 'Sailvest Plus') {
      window.location.replace(`https://buy.stripe.com/eVa2amaQr4g53U4eUY?prefilled_email=${email}`);
    } else if (subscriptionTier === 'Sailvest Premium') {
      window.location.replace(`https://buy.stripe.com/dR6dT4aQr4g50HS28d?prefilled_email=${email}`);
    } else {
      window.location.href = '/account';
    }
    setEmail('');
  }

  function verifyOTP() {
    if (parseInt(OTPInput, 10) === otp) {
      setErrorMessage('Successful registration. Navigating to login page...');
      setSeverity('success');
      setShowAlert(true);
      setTimeout(() => {
        addUser(user);
        navigateToStripePaymentsPage(localStorage.getItem('signup_subscription'));
      }, 2000);
    } else if (numWrongEntry >= 3) {
      setErrorMessage('Too many incorrect entries. Navigating to homepage...');
      setShowAlert(true);
      setTimeout(() => navigate('/'), 2000);
    } else {
      setErrorMessage(`Incorrect code entered. ${3 - numWrongEntry} ${(numWrongEntry === 2) ? 'try' : 'tries'} remaining.`);
      setNumWrongEntry(numWrongEntry + 1);
      setShowAlert(true);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          setDisable(false);
          clearInterval(interval);
        }
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); // each count lasts for a second
    // cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  const paperStyle = {
    padding: '20px',
    borderRadius: '10px',
    width: '20%',
    minWidth: '300px',
    margin: '20px auto',
    justifyContent: 'center',
    flex: 'column',
  };

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { margin: '5px 0', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" sx={{ marginBottom: '2em' }}>
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>Enter Code</h2>
          </Grid>
          <TextField
            label="Emailed Code"
            placeholder="Enter code"
            style={textFieldStyle}
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={OTPInput}
            onChange={(e) => setOTPInput(e.target.value)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            onClick={verifyOTP}
          >
            Verify
          </Button>
          <MyAlert
            show={showAlert}
            setShow={setShowAlert}
            severity={severity}
            message={errorMessage}
          />
          <div>
            <Typography variant="caption">Code not received?</Typography>
            <Button
              variant="text"
              size="small"
              disabled={disable}
              onClick={() => resendOTP()}
            >
              {disable ? `Resend OTP in ${timerCount}s` : 'Resend OTP'}
            </Button>
          </div>
        </Paper>
      </Grid>
    </div>
  );
}
