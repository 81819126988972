import React, { useCallback, useState } from 'react';
// geoCentroid gives center of a feature
// geoAlbersUsa gives projection of US and Alaska and Hawaii
import { geoCentroid, geoAlbersUsa } from 'd3-geo';
import {
  useTooltip,
  useTooltipInPortal,
  defaultStyles,
} from '@visx/tooltip';
// import { localPoint } from '@visx/event';
import { AlbersUsa } from '@visx/geo';
import * as topojson from 'topojson-client';
import { scaleOrdinal } from '@visx/scale';
import {
  LegendOrdinal,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { Checkbox, FormControlLabel } from '@mui/material';
import topology from './usa-topo.json';
import stateAbbrs from './us-abbr.json';
import '../assets/researchPage.css';

const background = '#EBF4F3';
const purple = '#c998ff';

// Set styles of tooltip
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

// Stores features from US JSON file in unitedStates variable
const { features: unitedStates } = topojson.feature(topology, topology.objects.states);

export const colors = ['#3064dd', '#d1d5e7'];

const legendGlyphSize = 15;

const ordinalColorScale = scaleOrdinal({
  domain: ['Covered', 'Not Covered'],
  range: [colors[0], colors[1]],
});

function LegendContainer({ title, children }) {
  return (
    <div className="legend">
      <div className="title">{title}</div>
      {children}
      <style jsx>
        {`
        .legend {
          width: 250px;
          line-height: 0.9em;
          color: #000;
          font-size: 15px;
          padding: 10px 10px;
          float: left;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          margin: 20px 0 10px;
        }
        .title {
          font-size: 1em;
          margin-bottom: 0.75em;
          font-weight: 100;
        }
      `}
      </style>
    </div>
  );
}

// X and Y adjustments to individual states' labels
const coordOffsets = {
  FL: [11, 3],
  AK: [0, -4],
  CA: [-7, 0],
  NY: [5, 0],
  MI: [13, 20],
  LA: [-10, -3],
  HI: [-10, 10],
  ID: [0, 10],
  WV: [-2, 4],
  KY: [10, 0],
  TN: [0, 4],
};

/**
 * These states are too small to have text labels
 * inside of them and are usually displayed with pointers.
 * For simplicity they are omitted from this demo.
 */
const ignoredStates = ['VT', 'NH', 'MA', 'RI', 'CT', 'NJ', 'DE', 'MD'];

export default function GeoAlbersUsa({
  width,
  height,
  markers,
  coveredStates,
}) {
  const [displayLabels, setDisplayLabels] = useState(false);

  const handleChange = (event) => {
    setDisplayLabels(event.target.checked);
  };

  // Get variables to be able to use tooltip in portal without any z-index issues
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Create tooltip functions and attributes for you to use
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();

  const handleMouseOver = useCallback((event, city) => {
    // TooltipInPortal expects coordinates to be relative to containerRef
    // localPoint returns coordinates relative to the nearest SVG, which
    // is what containerRef is set to in this example.
    // const eventSvgCoords = localPoint(event);
    // const left = eventSvgCoords.x;
    // const top = eventSvgCoords.y;
    showTooltip({
      tooltipData: markers[city],
      tooltipTop: event.clientY,
      tooltipLeft: event.clientX,
    });
  });

  // Get coordinates of center of parent
  const centerX = width / 2;
  const centerY = (height - 200) / 2;

  // Scale projection to increase size in viewport
  const visxScale = 2 * Math.min((height - 200), width / 1.5);

  // Obtain projection for markers using d3 geoAlbersUsa
  const markerProjection = geoAlbersUsa().scale(visxScale).translate([centerX, centerY]);

  return width < 10 ? null : (
    <div>
      <svg width={width} height={height - 200}>
        <AlbersUsa
          data={unitedStates}
          scale={visxScale}
          translate={[centerX, centerY]}
        >
          {({ features }) => features.map(({ feature, path, projection }, i) => {
            // Get coordinates and abbreviation of state
            const coords = projection(geoCentroid(feature));
            const abbr = stateAbbrs[feature.id];

            // Offset coordinates if necessary
            if (coordOffsets[abbr] && coords) {
              coords[0] += coordOffsets[abbr][0];
              coords[1] += coordOffsets[abbr][1];
            }

            // Set styling of states and labels
            const stylesObj = {
              fill: '#000',
              fontFamily: 'sans-serif',
              cursor: 'default',
            };

            if (coveredStates.includes(abbr)) {
              stylesObj.fill = '#FFF';
            }

            // Display states without label if state is in ignoredStates array
            if (ignoredStates.includes(abbr)) {
              return (
                <path
                  key={`map-feature-${i + 1}`}
                  d={path || ''}
                  fill={(coveredStates.includes(abbr)) ? colors[0] : colors[1]}
                  stroke={background}
                  strokeWidth={0.5}
                />
              );
            }

            // Display remaining states with label
            return (
              <React.Fragment key={`map-fragment-${i + 1}`}>
                <path
                  key={`map-feature-${i + 1}`}
                  d={path || ''}
                  fill={(coveredStates.includes(abbr)) ? colors[0] : colors[1]}
                  stroke={background}
                  strokeWidth={0.5}
                />
                {displayLabels && (
                <text
                  transform={`translate(${coords})`}
                  key={`map-text-${i + 1}`}
                  fontSize={Math.max(width / 75, 9)}
                  style={stylesObj}
                  textAnchor="middle"
                >
                  {abbr}
                </text>
                )}
              </React.Fragment>
            );
          })}
        </AlbersUsa>

        <g>
          {/* Display markers */}
          {Object.keys(markers).map((city, i) => (
            <circle
              r={height / 90}
              fill="#C630FD"
              stroke="#333"
              onClick={() => {
                alert(`clicked: ${markers[city].name}`);
              }}
              onMouseLeave={hideTooltip}
              onMouseMove={(event) => handleMouseOver(event, city)}
              key={`map-marker-${i + 1}`}
              strokeWidth={3}
              transform={`translate(${markerProjection([
                markers[city].lng,
                markers[city].lat,
              ])})`}
            />
          ))}
        </g>
        {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>
            <strong style={{ color: background }}>
              {tooltipData.rank}
              :
            </strong>
          </div>
          <div>
            <strong style={{ color: purple }}>
              {tooltipData.name}
              ,
              {' '}
            </strong>
            <strong style={{ color: purple }}>{tooltipData.state}</strong>
          </div>
          <div />
        </TooltipInPortal>
        )}
      </svg>
      <div className="mapBottomInfo">
        <LegendContainer title="Legend">
          <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label}`}>
            {(labels) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {labels.map((label, i) => (
                  <LegendItem
                    key={`legend-quantile-${i + 1}`}
                    margin="0 15px 0 0"
                  >
                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px">
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                ))}
              </div>
            )}
          </LegendOrdinal>
        </LegendContainer>
        <FormControlLabel
          control={(
            <Checkbox
              checked={displayLabels}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
)}
          label="Display state labels"
        />
      </div>

    </div>
  );
}
