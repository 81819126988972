/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { ResearchContext } from './ResearchContext';

const mapStyles = {
  height: '100%',
  width: '100%',
};

// Sets markers for different cities based on latitude and longitude (in degrees)
// const markers = {
//   Austin: {
//     name: 'Austin', lat: 30.2672, lng: -97.7431, rank: 1, state: 'TX',
//   },
//   Parsippany: {
//     name: 'Parsippany', lat: 40.8653, lng: -74.4174, rank: 2, state: 'NJ',
//   },
//   SanFrancisco: {
//     name: 'San Francisco', lat: 37.7749, lng: -122.4194, rank: 3, state: 'CA',
//   },
//   Juneau: {
//     name: 'Juneau', lat: 58.3005, lng: -134.4201, rank: 4, state: 'AK',
//   },
//   Detroit: {
//     name: 'Detroit', lat: 42.3314, lng: -83.0458, rank: 5, state: 'MI',
//   },
//   Honolulu: {
//     name: 'Honolulu', lat: 21.3099, lng: -157.8581, rank: 6, state: 'HI',
//   },
// };

// Change code so API key isn't pushed to Github! - this is a security issue

function displayMarkers(markers) {
  if (typeof markers !== 'undefined') {
    return markers.map((marker) => (
      <Marker
        key={`${marker.name}, ${marker.state}`}
        color="#8a32a8"
        name={marker.name}
        position={{ lat: marker.lat, lng: marker.lng }}
      />
    ));
  }
  return <div />;
}

function MyGoogleMap({
  markers, center, zoom, regionId,
}) {
  const [centerCoords, setCenterCoords] = useState(center);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD89L2ExNNXpo8jpOaHvxqdJPvzIxryEYg',
  });

  const { regions } = useContext(ResearchContext);

  useEffect(async () => {
    if (!center && regionId) {
      const region = regions.filter((tempRegion) => tempRegion._id === regionId)[0];

      if (region.region_name !== null && region.region_state !== null) {
        setCenterCoords(region.coordinates);
      }
    }
  }, [regions, regionId]);

  let mapCoordinates;

  // eslint-disable-next-line max-len
  if (markers === undefined) {
    mapCoordinates = centerCoords;
  } else {
    // eslint-disable-next-line max-len
    const xCoordSum = markers.reduce((accumulator, currentValue) => (accumulator + currentValue.lat), 0);
    const xMean = xCoordSum / markers.length;
    // eslint-disable-next-line max-len
    const yCoordSum = markers.reduce((accumulator, currentValue) => (accumulator + currentValue.lng), 0);
    const yMean = yCoordSum / markers.length;

    mapCoordinates = { lat: xMean, lng: yMean };
  }

  if (!isLoaded) {
    return <div>Loading</div>;
  }

  return (
    <GoogleMap
      mapContainerClassName="map-container"
      zoom={zoom}
      center={mapCoordinates}
      mapContainerStyle={mapStyles}
    >
      {displayMarkers(markers)}
    </GoogleMap>
  );
}
export default MyGoogleMap;
