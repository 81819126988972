import React, { useContext } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { UserContext } from './UserContext';
import { axiosUpdateUserSavedMarkets } from '../modules/api';

function BookmarkButton({ selectedRegion, sizeInPixels }) {
  const { userID, savedMarkets, updateUserProfile } = useContext(UserContext);

  function handleBookmarkClick(e) {
    if (savedMarkets.includes(selectedRegion)) {
      // Bookmark is on
      const newSavedMarkets = savedMarkets.filter((market) => market !== selectedRegion);
      axiosUpdateUserSavedMarkets(userID, newSavedMarkets).then(() => updateUserProfile());
    } else {
      const newSavedMarkets = [...savedMarkets];
      newSavedMarkets.push(selectedRegion);
      axiosUpdateUserSavedMarkets(userID, newSavedMarkets).then(() => updateUserProfile());
    }

    e.stopPropagation();
  }

  return (
    <Tooltip title="Save Market">
      <IconButton aria-label="favorite" onClick={(e) => handleBookmarkClick(e)} disabled={selectedRegion === undefined || selectedRegion === ''}>
        {savedMarkets.includes(selectedRegion) ? <TurnedInIcon sx={{ color: '#b96332', fontSize: (!sizeInPixels ? '30px' : sizeInPixels) }} /> : <TurnedInNotIcon sx={{ fontSize: (!sizeInPixels ? '30px' : sizeInPixels) }} />}
      </IconButton>
    </Tooltip>
  );
}

export default BookmarkButton;
