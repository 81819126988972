/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';

import {
  Modal, Chip, Paper, FormControl, Select, InputLabel, MenuItem,
  Typography, Collapse, Alert, IconButton, Box, CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import { Map, Marker, Popup } from 'react-map-gl';
import { useTheme } from '@mui/system';
import GoogleMap from './googleMap';
import CountryWideTable from './countrywideTable';
import CountryMap from './countryMap';
import '../assets/researchPage.css';
import { ResearchContext } from './ResearchContext';
import { axiosFindRegionCoverage, axiosFindRegionRankingForMetric } from '../modules/api';
import CustomGoogleMap from './customGoogleMap';
import allMetricNames, { allMetrics } from '../utils/allMetrics';
import stateAbbreviations from '../utils/stateAbbreviations';
import AdvancedCountrywideTable from './advancedCountrywideTable';
import ToolBar from './toolBar';
import AdvancedSideBar from './advancedSideBar';

// Style of modal
const style = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: 'calc(0.67 * 70vw + 150px)',
  maxHeight: '80vh',
  border: '2px solid #144a77',
};

// Used for ideal zoom of map
function horizontalRange(values) {
  if (values.length !== 0) {
    const interval = values.reduce((prev, current) => {
      const result = prev;
      if (current > prev.max) {
        result.max = current;
      }
      if (current < prev.min) {
        result.min = current;
      }
      return result;
    }, { min: values[0], max: values[0] });

    return (interval.max - interval.min);
  }
  return 0;
}

function calculateOptimalZoom(range) {
  return (-3.75 / 14) * range + (6.25 + 3.75 / 14);
}

export default function CountryWideView() {
  const { regions } = useContext(ResearchContext);
  const [coveredStates, setCoveredStates] = React.useState([]);
  const [metric, setMetric] = React.useState('median_days_on_market');
  const [coverageShow, setCoverageShow] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [regionRankings, setRegionRankings] = React.useState([]);
  const [markers, setMarkers] = React.useState([]);
  const [chosenMarker, setChosenMarker] = React.useState(null);
  const [reversed, setReversed] = React.useState(false);
  const [viewport, setViewport] = React.useState({
    latitude: 39,
    longitude: -98,
    zoom: 3,
    width: '90%',
    height: '90%',
  });
  const theme = useTheme();

  function handleOrderChange(order) {
    setReversed(order);
  }

  useEffect(() => {
    // console.log(chosenMarker);
  }, [chosenMarker]);

  /*
  Find region coverage
  Create cleanup function (the return value of useEffect)
  Returned function is what runs when component is unmounted
  */
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (metric !== '') {
      axiosFindRegionCoverage(metric, source).then((returnedRegionIDs) => {
        let coveredRegionIDs = returnedRegionIDs.data.map((original) => original.region_id);

        coveredRegionIDs = [...new Set(coveredRegionIDs)];

        const myCoveredStates = regions.reduce((accumulator, region) => {
          if (coveredRegionIDs.includes(region._id)) {
            accumulator.push(stateAbbreviations[region.region_state]);
          }
          return accumulator;
        }, []);

        const uniqueCoveredStates = [...new Set(myCoveredStates)];

        setCoveredStates(uniqueCoveredStates);
      });
    }

    /*
      I guess this is technically not needed since useEffect
      runs only when component is rendered anyway
    */

    return () => {
      source.cancel('Component unmounted');
    };
  }, [metric, regions, regionRankings]);

  // Find region ranking for metric
  useEffect(() => {
    if (metric !== '') {
      const source = axios.CancelToken.source();

      axiosFindRegionRankingForMetric(metric, source).then(
        (regionRanking) => {
          setRegionRankings(regionRanking.data);
        },
      ).catch((e) => {
        console.log(e);
      });
    }
  }, [metric]);

  // Change markers any time region ranking changes
  useEffect(async () => {
    if (regionRankings !== undefined && regions.length !== 0) {
      let tempMarkers;
      if (reversed) {
        tempMarkers = regionRankings.slice(regionRankings.length - 5, regionRankings.length);
      } else {
        tempMarkers = regionRankings.slice(0, 5);
      }

      tempMarkers = tempMarkers.map((tempMarker, i) => {
        const whichRegion = regions.filter((region) => region._id === tempMarker._id)[0];
        const regionName = whichRegion.region_name;
        const state = whichRegion.region_state;

        const { lat, lng } = whichRegion.coordinates;

        const markerObject = {
          name: regionName,
          rank: i,
          lat,
          lng,
          state,
        };

        return markerObject;
      });

      setMarkers(tempMarkers);
    }
  }, [regionRankings, regions, reversed]);

  function handleCoverageClick() {
    if (metric !== '') {
      setCoverageShow(true);
    } else {
      setShowAlert(true);
    }
  }

  // function getCoveredStates() {
  //   return coveredStates;
  // }

  return (
    <>
      <Collapse in={showAlert}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
            )}
          sx={{ mb: 0, mt: 2 }}
        >
          No Metric Selected
        </Alert>
      </Collapse>
      <div>
        <ToolBar isLoggedIn />
        <div className="Main">
          <AdvancedSideBar page="Countrywide" />
          <div className="RightSide">
            <Typography variant="h3" className="mainBody"><strong>Countrywide View</strong></Typography>
            <div className="mainBody" style={{ marginTop: '1em' }}>
              <div className="centeredRow">
                <FormControl sx={{ minWidth: '250px', marginRight: '15px' }} size="small">
                  <InputLabel id="demo-simple-select-label">Metric</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="metric-select"
                    value={metric}
                    onChange={(event) => setMetric(event.target.value)}
                    label="Metric"
                  >
                    {Object.keys(allMetrics).filter((tempMetric) => (tempMetric !== 'fed_funds_rate' && tempMetric !== 'price_to_rent_ratio')).map(
                      (metricKey) => (
                        <MenuItem
                          key={metricKey}
                          value={metricKey}
                        >
                          {allMetrics[metricKey].display_name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <Chip
                  label="View Coverage"
                  onClick={handleCoverageClick}
                />
              </div>
              <Grid container spacing={2}>
                <Grid item sm={12} md={7}>
                  <Paper
                    variant="outlined"
                    style={{
                      marginTop: '1em', borderRadius: '10px', padding: '0.6em', height: '60vh',
                    }}
                  >
                    <Map
                      {...viewport}
                      mapStyle="mapbox://styles/neiljohnmathew/clj32h0nc02yw01qpc0z39at0"
                      mapboxAccessToken="pk.eyJ1IjoibmVpbGpvaG5tYXRoZXciLCJhIjoiY2xqMmQzNjR4MGx4NjNsbzR5YTRnYXgzaSJ9.Iv8052Dwij0A00Iqi_j8GQ"
                      onMove={(evt) => setViewport(evt.viewport)}
                    >
                      {markers && markers.map((tempMarker) => (
                        <Marker
                          latitude={tempMarker.lat}
                          longitude={tempMarker.lng}
                        >

                          <div
                            onMouseEnter={() => setChosenMarker(tempMarker)}
                            onMouseOutCapture={() => setChosenMarker(null)}
                            style={{
                              width: '15px',
                              height: '15px',
                              backgroundColor: '#3ea2fa',
                              zIndex: 20,
                              color: 'black',
                              borderRadius: '50%',
                              border: '2px white solid',
                              position: 'relative',
                              transform: 'translate(-50%, -50%)',
                              textAlign: 'center',
                            }}
                          />

                        </Marker>
                      ))}
                      {chosenMarker && (
                        <Popup
                          latitude={chosenMarker.lat}
                          longitude={chosenMarker.lng}
                        >

                          <Typography sx={{ marginTop: '10px' }}>
                            <b>{chosenMarker.name}</b>

                          </Typography>
                          <Typography color={theme.palette.primary.main}>
                            {chosenMarker.state}

                          </Typography>

                        </Popup>
                      )}
                    </Map>
                  </Paper>
                </Grid>
                <Grid item sm={12} md={5}>
                  {regionRankings ? (
                    <Paper
                      variant="outlined"
                      style={{
                        marginTop: '1em', borderRadius: '10px', padding: '0.6em', height: '60vh',
                      }}
                    >
                      <ParentSize>
                        {({ height }) => (
                          <AdvancedCountrywideTable
                            height={height - 45}
                            rows={regionRankings}
                            metric={metric}
                            handleOrderChange={handleOrderChange}
                          />
                        )}
                      </ParentSize>
                    </Paper>
                  ) : (
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      minHeight: 100,
                      height: '95vh',
                      alignItems: 'center',
                    }}
                    >
                      <CircularProgress />
                    </Box>
                  )}

                </Grid>
              </Grid>
              <Modal
                open={coverageShow}
                onClose={() => setCoverageShow(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Paper
                  variant="outlined"
                  className="mainBody"
                  style={{ borderRadius: '5px', padding: '15px' }}
                  sx={style}
                >
                  <Typography id="modal-modal-title" variant="h6">
                    <strong>
                      Coverage for
                      {' '}
                      {allMetrics[metric].display_name}
                      {' '}
                      Metric
                    </strong>
                  </Typography>
                  <ParentSize>
                    {({ width, height }) => (
                      <CountryMap
                        width={width}
                        height={height}
                        markers={{}}
                        coveredStates={coveredStates}
                      />
                    )}
                  </ParentSize>
                </Paper>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}
