/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
// React
import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';

import { useNavigate } from 'react-router-dom';

// MUI
import {
  Modal,
  Typography,
  Box,
  Button,
  Paper,
  Collapse,
  Alert,
  IconButton,
  Grid,
  Chip,
  Tooltip,
  Autocomplete,
  TextField,
  CircularProgress,
  Badge,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// My components
import ToolBar from './toolBar';
import MyAlert from './myAlert';
import '../assets/main.css';
import '../assets/accountPage.css';
import { sleep } from './propertiesPage';

// Database functions
import {
  cancelSubscription,
  getPayingCustomers,
  getComplimentaryCustomers,
  getSubscriptions,
  axiosFindUsersWithGivenUsername,
  axiosGetSomeCities,
  axiosGetAllCities,
  axiosSetCurrentAndNextPaidLocations,
} from '../modules/api';
import AdvancedSideBar from './advancedSideBar';
import { UserContext } from './UserContext';

const moment = require('moment');

// Style of modal
const style = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  maxHeight: '80vh',
  border: '2px solid black',
};

export default function accountPage() {
  const [cityToDelete, setCityToDelete] = useState();

  // For alerts
  const [successShow, setSuccessShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // For modals
  const [cancelShow, setCancelShow] = useState(false);
  const [addLocationShow, setAddLocationShow] = useState(false);
  const [unsubscribeShow, setUnsubscribeShow] = useState(false);
  const [lockChangesShow, setLockChangesShow] = useState(false);

  const {
    locationsPaidFor, subscriptionTier, setLocationsPaidFor, dateUserUpdatedLocations, setDateUserUpdatedLocations,
  } = useContext(UserContext);
  const [paidLocationsDetails, setPaidLocationsDetails] = useState([]);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);
  const [townData, setTownData] = useState([]);
  const [lockChangesDisabled, setLockChangesDisabled] = useState(true);

  const loading = autocompleteOpen && options.length === 0;

  const presentDate = moment();
  // They are eligible if the two weeks have passed, they haven't ever updated, or if they have unlimited tier
  const eligibleToUpdateLocations = (presentDate.diff(moment(dateUserUpdatedLocations), 'days', true) >= 14
    || subscriptionTier === 'Unlimited' || dateUserUpdatedLocations === undefined);

  /* eslint-disable */
  let nextUpdateDateText = ""
  if (dateUserUpdatedLocations) {
    nextUpdateDateText = 'Can only be changed after ' + moment(dateUserUpdatedLocations).add(14, 'days').format('MM-DD-YYYY');
  }
   
  const navigate = useNavigate();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For enough time to load.

      if (active) {
        setOptions(townData.filter((town) => !(town.city === 'New York' && town.state_name === 'New York')));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!autocompleteOpen) {
      setOptions([]);
    }
  }, [autocompleteOpen]);

  // Loading in towns
  useEffect(() => {
    axiosGetAllCities().then((val) => {
      const retrievedTowns = val.data.data;
      setTownData(retrievedTowns);
      setSelectedSearchItem(retrievedTowns[1]);
    }).catch((e) => console.log(e));
  }, []);

  const handleSearch = useCallback((event, newValue) => {
    setSelectedSearchItem(newValue);
  }, []);

  // const theme = useTheme();

  function getNumberOfAllowedLocations() {
    switch (subscriptionTier) {
      case 'Free':
        return 1;
      case 'Sailvest Basic':
        return 1;
      case 'Sailvest Plus':
        return 3;
      case 'Sailvest Premium':
        return 5;
      case 'Unlimited':
        return 10000;
      case 'Enterprise':
        // Placeholder for now
        return 10;
      default:
        return 1;
    }
  }

  function handleAddLocation() {
    if (!selectedSearchItem) {
      // Determine if user actually picked a location
      setAddLocationShow(false);
      setAlertMessage('Please choose a location.');
      setShowAlert(true);
    } else if (locationsPaidFor.length >= getNumberOfAllowedLocations()) {
      // Determine if user exceeded limit
      setAddLocationShow(false);
      setAlertMessage('Subscription limit has been reached. Unsubscribe from another location before adding a new one.');
      setShowAlert(true);
    } else if (locationsPaidFor.includes(selectedSearchItem._id)) {
      // Determine if user already picked this location
      setAddLocationShow(false);
      setAlertMessage('You are already subscribed to this location.');
      setShowAlert(true);
    } else {
      // Add to temporary paid locations in state variable
      setLocationsPaidFor([...locationsPaidFor, selectedSearchItem._id]);
      setAddLocationShow(false);
    }

    setLockChangesDisabled(false);
  }

  function handleRemoveLocation() {
    setLocationsPaidFor(locationsPaidFor.filter((location) => location !== cityToDelete._id));
    setUnsubscribeShow(false);
    setCityToDelete(null);
    setLockChangesDisabled(false);
  }

  function handleLockChanges() {
    axiosSetCurrentAndNextPaidLocations(localStorage.getItem('currUserId'), locationsPaidFor).then(() => {
      setLockChangesDisabled(true);
      setLockChangesShow(false);
      setSuccessShow(true);
    }).catch(() => {
      // Handle error
      setAlertMessage('Error in updating paid locations.');
      setShowAlert(true);
    });
    setDateUserUpdatedLocations(presentDate);
  }

  // Get name, state, etc. for all locations that user paid for
  useEffect(() => {
    axiosGetSomeCities(locationsPaidFor).then((result) => {
      setPaidLocationsDetails(result.data);
    });
  }, [locationsPaidFor]);

  const handleCancelSubscription = async () => {
    const username = localStorage.getItem('currUser');

    let complimentaryUsers = await getComplimentaryCustomers();
    complimentaryUsers = complimentaryUsers.data.data;

    // For these users, we have to manually cancel because some of them are on the old Stripe account
    if (complimentaryUsers.includes(username)) {
      setAlertMessage(
        'Cancel subscription failed. Please text Kevin at 973-495-0113 to cancel your subscription.',
      );
      setShowAlert(true);
    } else {
      const resultCheckByUsername = await axiosFindUsersWithGivenUsername(
        username,
      );
      const userEmail = resultCheckByUsername.data[0].email;
      const payingCustomers = await getPayingCustomers();
      const allSubscriptions = await getSubscriptions();

      /* eslint-disable */
      const customerStripeId = payingCustomers.data.data.filter((obj) => {
        return obj.email === userEmail;
      })[0].id;
      const subscriptionId = allSubscriptions.data.data.data.filter((obj) => {
        return obj.customer === customerStripeId;
      })[0].id;

      cancelSubscription(subscriptionId);

      // localStorage.removeItem("currUser");
      // window.location.href = "/";
      e.preventdefault();
    }
  };

  return (
    <div>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Account" />
        <div className="RightSide">
        <MyAlert marginTop="0px" show={successShow} setShow={setSuccessShow} severity="success" message="Paid locations successfully changed." />
          <Box sx={{ width: "100%" }}>
            <Collapse in={showAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{mb: 2}}
              >
                {alertMessage}
              </Alert>
            </Collapse>
          </Box>
          <Typography variant="h3" className="mainBody">
            <strong>Account</strong>
          </Typography>
          <div className="mainBody">
            <Grid container spacing={2}>
              <Grid item container spacing={2} xs={12} sm={4}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      borderRadius: "10px",
                      padding: "1em 1.5em 1em 1.5em",
                      height: '100%'
                    }}
                  >
                    <Typography>
                      <b>Username:</b>
                    </Typography>
                    <Typography>{localStorage.getItem("currUser")}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      borderRadius: "10px",
                      padding: "1em 1.5em 1em 1.5em",
                      height: '100%'
                    }}
                  >
                    <Typography>
                      <b>Email:</b>
                    </Typography>
                    <Typography>{localStorage.getItem("userEmail")}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      borderRadius: "10px",
                      padding: "1em 1.5em 1em 1.5em",
                      height: '100%'
                    }}
                  >
                    <Typography>
                      <b>Tier:</b>
                    </Typography>
                    <Typography
                    >
                      {subscriptionTier}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Paper
                  sx={{
                    borderRadius: "10px",
                    height: "100%",
                    minHeight: '300px',
                    padding: "1em 1.5em 1em 1.5em",
                    position: 'relative'
                  }}
                >
                  <Typography variant="caption" sx={{position: 'absolute', bottom: '10px', right: '20px'}}>
                    Up to {getNumberOfAllowedLocations()} max
                  </Typography>
                  <Typography variant="caption" sx={{position: 'absolute', bottom: '10px', left: '20px'}}>
                    {(dateUserUpdatedLocations === undefined || subscriptionTier === "Unlimited") ? "" : nextUpdateDateText}
                  </Typography>
                  <div className='centeredRow sideBySideSpacedOut' style={{marginBottom: '5px'}}>
                  <Typography>
                    <b>Subscribed Locations:</b>
                  </Typography>
                  <Tooltip title="Add Location" placement="top" >
                  <IconButton 
                  onClick={() => {
                    if (eligibleToUpdateLocations) {
                      setAddLocationShow(true);
                    } else {
                      // console.log(dateUserUpdatedLocations)
                      setAlertMessage(`Paid locations can only be updated after ${moment(dateUserUpdatedLocations).add(14, 'days').format('MM-DD-YYYY')}.`);
                      setShowAlert(true);
                    }
                  }}
                  color="primary" 
                  >
                        <AddCircleIcon />
                  </IconButton>
                  </Tooltip>
                  </div>
                  <Grid container spacing={2}>
                    {paidLocationsDetails && paidLocationsDetails.map((cityInfo) => 
                    <Grid key={cityInfo._id} item >
                      <Badge color="secondary" variant="dot" >
                      <Chip
                      onClick={() => navigate(`/properties/${cityInfo._id}`)}
                    label={<Typography variant="subtitle2">
                      <b>{`${cityInfo.city}, ${cityInfo.state_id}`}</b>
                    {` | `}
                    <em>{`${cityInfo.county_name} County`}</em></Typography>}
                    sx={{ backgroundColor: "#eeeeee", color: "black" , }}
                    onDelete={() => 
                      {
                        if (eligibleToUpdateLocations) {
                          setUnsubscribeShow(true);
                          setCityToDelete(cityInfo);
                        } else {
                          // console.log(dateUserUpdatedLocations)
                          setAlertMessage(`Paid locations can only be updated after ${moment(dateUserUpdatedLocations).add(14, 'days').format('MM-DD-YYYY')}.`);
                          setShowAlert(true);
                        }
                      }}
                  />
                      </Badge>
                    </Grid>)}
                  </Grid>
                  <Button 
                  size="small" 
                  variant="contained" 
                  disabled={lockChangesDisabled} 
                  startIcon={<LockIcon></LockIcon>}
                  onClick={() => setLockChangesShow(true)}
                  sx={{marginTop: '25px', marginBottom: '50px'}} 
                  >Lock Changes</Button>
                </Paper>
              </Grid>
            </Grid>
            {(subscriptionTier !== 'Free') && <Button
              onClick={() => setCancelShow(true)}
              sx={{
                marginTop: "2em",
                marginRight: '1em',
                visibility:
                  subscriptionTier !== "Free" ? "visible" : "hidden",
              }}
              variant="outlined"
              color="error"
            >
              Cancel Subscription
            </Button>}
            <Button
              onClick={ subscriptionTier !== "Unlimited" ? () =>  {navigate('/change_tier')} : () => {}}
              sx={{
                marginTop: '2em',
              }}
              variant="contained"
              color="secondary"
            >
              Change Tier
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={lockChangesShow}
        onClose={() => setLockChangesShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: "5px", padding: "15px" }}
          sx={style}
        >
          <Typography id="modal-modal-title" sx={{fontSize: '18px'}}>
            <strong>
              Are you sure you wish to lock these changes to your paid location subscriptions?
            </strong>
          </Typography>
          <Typography id="modal-modal-title" variant="caption">
          After changes are locked, you will then only be able to update your paid locations after two weeks.
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3em" }}
          />
          <Button
            onClick={() => setLockChangesShow(false)}
            sx={{ marginRight: "1em" }}
            variant="contained"
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleLockChanges()}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={cancelShow}
        onClose={() => setCancelShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: "5px", padding: "15px" }}
          sx={style}
        >
          <Typography id="modal-modal-title" variant="h6">
            <strong>
              Are you sure you wish to cancel your Sailvest subscription?
            </strong>
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3em" }}
          />
          <Button
            onClick={() => setCancelShow(false)}
            sx={{ marginRight: "1em" }}
            variant="contained"
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={handleCancelSubscription}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={unsubscribeShow}
        onClose={() => setUnsubscribeShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: "5px", padding: "15px" }}
          sx={style}
        >
          <Typography id="modal-modal-title" sx={{fontSize: '18px'}}>
            <strong>
              Are you sure you wish to unsubscribe from this location?
            </strong>
          </Typography>
          <Typography id="modal-modal-title" variant="caption">
          Note: Changes are only locked in if the <em>Lock Changes</em> button is clicked next. After changes are locked, you will then only be able to update your paid locations after two weeks.
          </Typography>
          {cityToDelete && <div style={{textAlign: 'center', width: '100%', marginTop: '20px'}}>
            <Chip
                    label={<Typography variant="subtitle2">
                      <b>{`${cityToDelete.city}, ${cityToDelete.state_id}`}</b>
                    {` | `}
                    <em>{`${cityToDelete.county_name} County`}</em></Typography>}
                    sx={{ backgroundColor: "#eeeeee", color: "black" , }}
                  />
            </div>}
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3em" }}
          />
          <Button
            onClick={() => setUnsubscribeShow(false)}
            sx={{ marginRight: "1em" }}
            variant="contained"
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleRemoveLocation()}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={addLocationShow}
        onClose={() => setAddLocationShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody"
          style={{ borderRadius: "5px", padding: "15px" }}
          sx={style}
        >
          <Typography id="modal-modal-title" sx={{fontSize: '18px'}}>
            <strong>
              Choose which location you would like to subscribe to below.
            </strong>
          </Typography>
          <Typography id="modal-modal-title" variant="caption">
          Note: Changes are only locked in if the <em>Lock Changes</em> button is clicked next. After changes are locked, you will then only be able to update your paid locations after two weeks.
          </Typography>
          <Autocomplete
                        id="town-picker"
                        isOptionEqualToValue={(option, value) => (option._id === value._id)}
                        size="small"
                        sx={{ width: '400px', marginTop: '20px' }}
                        open={autocompleteOpen}
                        onOpen={() => {
                          setAutocompleteOpen(true);
                        }}
                        onClose={() => {
                          setAutocompleteOpen(false);
                        }}
                        loading={loading}
                        onChange={handleSearch}
                        value={selectedSearchItem}
                        options={options}
                        // groupBy={(searchItem) => (searchItem.state)}
                        getOptionLabel={(townRow) => (
                          `${townRow.city}, ${townRow.state_name} (${townRow.county_name} County)`)}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <Typography>{`${option.city}, ${option.state_name} (${option.county_name} County)`}</Typography>
                            {locationsPaidFor.includes(option._id) && (
                            <div
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '5px',
                                backgroundColor: '#6530a2',
                                borderRadius: '50%',
                                border: '2px white solid',
                              }}
                            />
                            )}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Location"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "3em" }}
          />
          <Button
            onClick={handleAddLocation}
            variant="contained"
            color="primary"
          >
            Subscribe
          </Button>
        </Paper>
      </Modal>
    </div>
  );
}
