import React from 'react';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import {
  useTooltipInPortal, useTooltip, TooltipWithBounds, defaultStyles,
} from '@visx/tooltip';
import { Typography, Box, CircularProgress } from '@mui/material';
import { GridRows } from '@visx/grid';
import { Text } from '@visx/text';
import { allMetrics } from '../utils/allMetrics';

const accentColor = '#545d65';

// accessors for x and y axes
const xAccessor = (d) => d.regionLevel;
const yAccessor = (d) => d.growthValue;

// Different labels for growth vs. value vs. other metrics
function formatTicks(metric) {
  switch (allMetrics[metric].comparison) {
    case 'Growth':
      return (value) => `${value} %`;
    case 'Value':
      return (value) => value;
    default:
      return (value) => value;
  }
}

export default function ComparisonBarChart({
  regionName, regionState, metric, data,
}) {
  // Show loading symbol when data still being retrieved
  if (data === undefined || data === '' || data.length === 0 || metric === undefined) {
    return (
      <Box sx={{
        width: '100%',
        height: 'calc(100% - 25px)',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ParentSize>
      {({ width, height }) => (
        <BarChart
          width={width}
          height={height}
          regionName={regionName}
          regionState={regionState}
          metric={metric}
          data={data}
        />
      )}
    </ParentSize>
  );
}

function BarChart({
  width, height, metric, data, regionName, regionState,
}) {
  const axisBottomSpace = 70;
  const axisTopSpace = 30;
  const horizontalMargin = 175;

  const barChartWidth = width;
  const barChartHeight = height;

  const barChartXMax = barChartWidth - horizontalMargin;
  const barChartYMax = barChartHeight - axisBottomSpace - axisTopSpace;

  const barChartXScale = scaleBand({
    domain: [regionName, regionState, 'United States'],
    range: [0, barChartXMax],
    round: true,
    padding: 0.2,
  });

  const barChartYScale = scaleLinear({
    domain: [
      Math.min(...data.map(yAccessor)) > 0 ? 0 : Math.min(...data.map(yAccessor)),
      Math.max(...data.map(yAccessor)) < 0 ? 0 : Math.max(...data.map(yAccessor))],
    range: [barChartYMax, 0],
    round: true,
  });

  const colorScale = scaleOrdinal({
    domain: [regionName, regionState, 'United States'],
    range: ['#2255b5', '#073264', '#7c1ba6'],
  });

  const { containerRef, containerBounds } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  // Create tooltip functions and attributes for you to use
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip();

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: '#4b4b4b',
    borderRadius: '5px',
    color: 'white',
    opacity: 1,
    width: 'auto',
    height: 'auto',
    padding: 12,
  };

  return width < 10 ? null : (
    <div style={{ position: 'relative' }}>
      <svg width={barChartWidth} height={barChartHeight} ref={containerRef}>
        {/* <GradientPinkBlue id="bar-chart-background" /> */}
        <rect fill="#ffffff" width={barChartWidth} height={barChartHeight} rx={14} />
        <Group top={axisTopSpace} left={horizontalMargin / 2}>
          <GridRows
            scale={barChartYScale}
            width={barChartWidth - horizontalMargin}
            stroke={accentColor}
            strokeWidth={2}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          {data.map((d, i) => {
            const regionLevel = xAccessor(d);
            const growthValue = yAccessor(d);
            const barWidth = barChartXScale.bandwidth();
            // We do 'YMax -' because we reversed our y scale
            const barHeight = barChartYScale(0) - barChartYScale(growthValue);
            const newRegionLevel = [regionName, regionState, 'United States'][i];
            const barX = barChartXScale(newRegionLevel);
            const barY = barChartYScale(growthValue);
            if (growthValue === null || growthValue === undefined) {
              return <Text x={barX + barWidth / 2} y={barChartYScale(0) - 20} textAnchor="middle">Unavailable</Text>;
            }
            return (
              <rect
                key={`bar-${regionLevel}`}
                x={barX}
                y={growthValue < 0 ? barChartYScale(0) : barY}
                rx={10}
                width={barWidth}
                height={growthValue < 0 ? -barHeight : barHeight}
                fill={colorScale(regionLevel)}
                onMouseLeave={hideTooltip}
                onMouseMove={(event) => {
                  const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
                  const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

                  showTooltip({
                    tooltipData: growthValue,
                    tooltipTop: containerY,
                    tooltipLeft: containerX,
                  });
                }}
              />
            );
          })}
          <AxisLeft
            label={`${allMetrics[metric].display_name} ${allMetrics[metric].comparison === 'Growth' ? 'Growth' : ''}`}
            scale={barChartYScale}
            tickFormat={formatTicks(metric)}
            stroke="#000"
            strokeWidth={2}
            tickLabelProps={() => ({
              fontSize: 15,
              dy: 5,
              dx: -5,
              textAnchor: 'end',
            })}
            labelOffset={50}
            labelProps={{
              fontSize: 15,
              fontWeight: 'bold',
              fontFamily: 'Noto Sans',
              textAnchor: 'middle',
            }}
          />
          <AxisBottom
            // label="Region Level"
            hideTicks
            top={barChartYScale(0)}
            scale={barChartXScale}
            stroke="#000"
            strokeWidth={2}
            tickLabelProps={() => ({
              fontSize: 15,
              dy: 5,
              textAnchor: 'middle',
            })}
            labelOffset={20}
            labelProps={{
              fontSize: 15,
              fontWeight: 'bold',
              fontFamily: 'Noto Sans',
              textAnchor: 'middle',
            }}
          />
        </Group>
      </svg>
      {tooltipOpen && (
      <TooltipWithBounds
          // set this to random so it correctly updates with parent bounds
        style={tooltipStyles}
        key={Math.random()}
        top={tooltipTop}
        left={tooltipLeft}
      >
        <Typography variant="body">
          <b>
            {allMetrics[metric].display_name}
            {' '}
            {allMetrics[metric].comparison === 'Growth' ? 'Growth: ' : ': '}
          </b>
          {tooltipData.toFixed(2)}
          {allMetrics[metric].comparison === 'Growth' ? '%' : ''}
        </Typography>
      </TooltipWithBounds>
      )}
    </div>

  );
}
