/* eslint-disable import/no-cycle */
import React, {
  useState, createContext, useMemo,
} from 'react';
import '../assets/login.css';
import Login from './login';
import OTPInput from './otpInput';
import Recovered from './recovered';
import Reset from './reset';
import Recover from './recover';

export const RecoveryContext = createContext();

export default function LoginPage() {
  const [page, setPage] = useState('login');
  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();

  const NavigateComponents = useMemo(() => {
    if (page === 'login') return Login;
    if (page === 'recover') return Recover;
    if (page === 'otp') return OTPInput;
    if (page === 'reset') return Reset;

    return Recovered;
  }, [page]);

  const memoValue = useMemo(() => ({
    page, setPage, otp, setOTP, setEmail, email,
  }), [page, setPage, otp, setOTP, setEmail, email]);

  return (
    <RecoveryContext.Provider
      value={memoValue}
    >
      <NavigateComponents />
    </RecoveryContext.Provider>
  );
}
