/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import {
  Button, Divider, Typography, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ToolBar from './toolBar';
import '../assets/paymentOptionsPage.css';
// import { sign } from 'crypto';

export default function PaymentOptionsPage() {
  const paperStyle = {
    padding: '25px',
    borderRadius: '10px',
    // margin: '100px',
    width: '90%',
  };

  const paymentOptionPaperStyle = {
    border: '2px solid #144a77',
    backgroundColor: 'white',
    color: 'black',
    margin: '10px',
    minWidth: '225px',
    minHeight: '300px',
    padding: '10px',
  };

  const navigate = useNavigate();

  const signUpFree = () => {
    localStorage.setItem('signup_subscription', 'Free');
    navigate('/signup');
  };

  const signUpBasic = () => {
    localStorage.setItem('signup_subscription', 'Sailvest Basic');
    navigate('/signup');
  };

  const signUpPlus = () => {
    localStorage.setItem('signup_subscription', 'Sailvest Plus');
    navigate('/signup');
  };

  const signUpPremium = () => {
    localStorage.setItem('signup_subscription', 'Sailvest Premium');
    navigate('/signup');
  };

  return (
    <div className="paymentOptionsPage">
      <ToolBar />
      {/* <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
      > */}
      <Paper elevation={1} sx={paperStyle}>
        <h2 style={{ marginBottom: '25px' }}><b>Subscription Tiers</b></h2>
        <div className="paymentOptionsContainer">
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Free</b></Typography>
            <Typography variant="h4">$0</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={signUpFree}
            >
              Sign Up
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for 3 properties in 1 town"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Basic</b></Typography>
            <Typography variant="h4">$10</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={signUpBasic}
            >
              Sign Up
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 1 town"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Plus</b></Typography>
            <Typography variant="h4">$25</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={signUpPlus}
            >
              Sign Up
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 3 towns"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Premium</b></Typography>
            <Typography variant="h4">$40</Typography>
            <Typography variant="caption">per month</Typography>
            <Button
              size="small"
              variant="contained"
              sx={{ marginTop: '20px', width: '95%' }}
              onClick={signUpPremium}
            >
              Sign Up
            </Button>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in 5 towns"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Recommended markets based on Sailvest market data analysis"
                />
              </ListItem>
            </List>
          </Paper>
          <Paper elevation={4} sx={paymentOptionPaperStyle}>
            <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Enterprise</b></Typography>
            <Typography variant="h4">---</Typography>
            <Typography variant="caption">Per month</Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: '30px' }}
            >
              Email us at sailvest.contact@gmail.com
            </Typography>
            <Divider sx={{ margin: '20px' }} />
            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                  primary="Market analytics"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Cash flow analysis for all properties in more than 5 towns"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary="Recommended markets based on Sailvest market data analysis"
                />
              </ListItem>
            </List>
          </Paper>
        </div>
        {/* <Grid container spacing={4}>
            <Grid item xs={12} sm={6} lg={2.4}>
              <Paper sx={paymentOptionPaperStyle}>
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Free</b></Typography>
                <Typography variant="h4">$0</Typography>
                <Typography variant="caption">per month</Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginTop: '20px', width: '95%' }}
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </Button>
                <Divider sx={{ margin: '20px' }} />
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                      primary="Market analytics"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cash flow analysis for 3 properties in 1 town"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={2.4}>
              <Paper sx={paymentOptionPaperStyle}>
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Tier 1</b></Typography>
                <Typography variant="h4">$10</Typography>
                <Typography variant="caption">per month</Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginTop: '20px', width: '95%' }}
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </Button>
                <Divider sx={{ margin: '20px' }} />
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                      primary="Market analytics"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cash flow analysis for all properties in 1 town"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={2.4}>
              <Paper sx={paymentOptionPaperStyle}>
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Tier 2</b></Typography>
                <Typography variant="h4">$25</Typography>
                <Typography variant="caption">per month</Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginTop: '20px', width: '95%' }}
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </Button>
                <Divider sx={{ margin: '20px' }} />
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                      primary="Market analytics"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cash flow analysis for all properties in 3 towns"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={2.4}>
              <Paper sx={paymentOptionPaperStyle}>
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Tier 3</b></Typography>
                <Typography variant="h4">$40</Typography>
                <Typography variant="caption">per month</Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginTop: '20px', width: '95%' }}
                  onClick={() => navigate('/signup')}
                >
                  Sign Up
                </Button>
                <Divider sx={{ margin: '20px' }} />
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                      primary="Market analytics"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cash flow analysis for all properties in 5 towns"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={2.4}>
              <Paper sx={paymentOptionPaperStyle}>
                <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}><b>Custom</b></Typography>
                <Typography variant="h4">---</Typography>
                <Typography variant="caption">Per month</Typography>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ marginTop: '20px', width: '95%' }}
                  onClick={() => console.log('contact us')}
                >
                  Contact Us
                </Button>
                <Divider sx={{ margin: '20px' }} />
                <List dense>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      // sx={{ paddingLeft: '5px' }}
                      primary="Market analytics"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 0, marginRight: '10px' }}>
                      <CheckCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Cash flow analysis for all properties in more than 5 towns"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid> */}
      </Paper>
      {/* </div> */}
    </div>
  );
}
