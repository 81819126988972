import React from 'react';
import ReactDOM from 'react-dom';
import {
  Navigate, Routes, Route, BrowserRouter,
} from 'react-router-dom';
import './index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import LoginPage from './components/loginPage';
import SignupPage from './components/signupPage';
import ExplorePage from './components/explorePage';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './components/errorPage';
import AccountPage from './components/accountPage';
import SavedPage from './components/savedPage';
import ResearchContextProvider from './components/ResearchContext';
import UserContextProvider from './components/UserContext';
import TestTowns from './components/testTowns';
import ChangeTierPage from './components/changeTierPage';
// import AdvancedSideBar from './components/advancedSideBar';
import PropertiesPage from './components/propertiesPage';
import CountryWideView from './components/countrywideView';
import ComparisonView from './components/comparisonView';
import PropertyContextProvider from './components/PropertyContext';
import PropertyDetailsPage from './components/propertyDetailsPage';
import 'mapbox-gl/dist/mapbox-gl.css';
import RecommendationsPage from './components/recommendationsPage';
import PaymentOptionsPage from './components/paymentOptionsPage';

// Defining Material UI theme for entire app
const theme = createTheme({
  palette: {
    primary: {
      main: '#144a77',
    },
    secondary: {
      main: '#6530a2',
    },
    error: {
      main: '#b92424',
    },
    tertiary: {
      main: '#e36e26',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
  },
  typography: {
    fontFamily: 'Noto Sans',
  },
});

// For only allowing access to certain URLs when user is logged in
const loggedIn = localStorage.getItem('currUser');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} exact />
        <Route path="/login" element={<LoginPage />} exact />
        <Route path="/signup" element={<SignupPage />} exact />
        <Route path="/explore/:regionID" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><ResearchContextProvider><ExplorePage /></ResearchContextProvider></UserContextProvider>} />
        <Route path="/countrywide" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><ResearchContextProvider><CountryWideView /></ResearchContextProvider></UserContextProvider>} />
        <Route path="/comparison" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><ResearchContextProvider><ComparisonView /></ResearchContextProvider></UserContextProvider>} />
        <Route path="/recommendations" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><RecommendationsPage /></UserContextProvider>} exact />
        <Route path="/account" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><AccountPage /></UserContextProvider>} exact />
        <Route path="/saved" element={!loggedIn ? <Navigate replace to="/login" /> : <PropertyContextProvider><UserContextProvider><ResearchContextProvider><SavedPage /></ResearchContextProvider></UserContextProvider></PropertyContextProvider>} exact />
        <Route path="/change_tier" element={!loggedIn ? <Navigate replace to="/login" /> : <PropertyContextProvider><UserContextProvider><ResearchContextProvider><ChangeTierPage /></ResearchContextProvider></UserContextProvider></PropertyContextProvider>} exact />
        <Route path="/properties/:cityID" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><PropertyContextProvider><ResearchContextProvider><PropertiesPage /></ResearchContextProvider></PropertyContextProvider></UserContextProvider>} exact />
        <Route path="/payment_options" element={<PaymentOptionsPage />} exact />
        <Route path="/property/:propertyID" element={!loggedIn ? <Navigate replace to="/login" /> : <UserContextProvider><PropertyContextProvider><ResearchContextProvider><PropertyDetailsPage /></ResearchContextProvider></PropertyContextProvider></UserContextProvider>} exact />
        <Route path="*" element={<ErrorPage />} exact />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);

reportWebVitals();
