/* eslint-disable import/no-cycle */
import React, { useState, createContext, useMemo } from 'react';
import Login from './login';
import SignUpOTPInput from './signupOTPInput';
// import Payment from './payment';
import '../assets/login.css';
import SignUp from './signup';

export const SignUpContext = createContext();

export default function SignupPage() {
  const [page, setPage] = useState('signup');
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState();
  const [user, setUser] = useState();

  const SignUpComponents = useMemo(() => {
    if (page === 'signup') return SignUp;
    if (page === 'otp') return SignUpOTPInput;

    return Login;
  }, [page]);

  const memoValue = useMemo(() => ({
    page, setPage, otp, setOTP, email, setEmail, user, setUser,
  }), [page, setPage, otp, setOTP, email, setEmail, user, setUser]);

  return (
    <SignUpContext.Provider value={memoValue}>
      <SignUpComponents />
    </SignUpContext.Provider>
  );
}
