/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import '../assets/main.css';
import '../assets/operations.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Typography, Pagination,
} from '@mui/material';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import MarketSummaryV2 from './marketSummaryv2';
import ResearchContextProvider from './ResearchContext';
import {
  getTopMarkets,
  axiosFindRegionRecord,
  findRegionGeneralInfo,
} from '../modules/api';
import MyAlert from './myAlert';

function getRegionRecord(regionId) {
  const source = axios.CancelToken.source();
  return axiosFindRegionRecord(regionId, source);
}

function LinearProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {
            `${Math.round(value)}%`
          }
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function insightsPage() {
  const numMarkets = 4;

  const [rank, setRank] = React.useState(1);
  const [showAlert, setShowAlert] = React.useState(false);
  const [allMarkets, setAllMarkets] = React.useState();
  const [market, setMarket] = React.useState();
  const [progress, setProgress] = React.useState(10);

  // eslint-disable-next-line no-unused-vars
  const [progressLabel, setProgressLabel] = React.useState('Loading Your Personalized Market Recommendations...');

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (allMarkets === null || allMarkets === undefined) {
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }
    }, 1400);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Change page
  const handleChange = (event, value) => {
    setRank(value);
  };

  // Load top region IDs from algorithm
  useEffect(() => {
    // check if top markets is in local storage
    if (JSON.parse(localStorage.getItem('top_markets')) !== null) {
      setAllMarkets(JSON.parse(localStorage.getItem('top_markets')));
    } else {
      getTopMarkets(localStorage.getItem('currUser')).then(
        async (regions) => {
          if (regions.data[3] === null) {
            setProgressLabel('No Markets Meet Criteria. Please Broaden Preferences Using the Account Page.');
          } else {
            setAllMarkets(regions.data);
            localStorage.setItem('top_markets', JSON.stringify(regions.data));
          }
        },
      ).catch(() => {
        setShowAlert(true);
        setProgressLabel('No Markets Meet Criteria. Please Broaden Preferences Using the Account Page.');
      });
    }
    // setAllMarkets(['63e4478de9a9a32574f1b09f',
    //   '63e4478ee9a9a32574f1b0a6', '6417f20ffab97e924cd06917', '6417f1fffab97e924cd023b5']);
  }, []);

  // Execute whenever page is changed
  useEffect(async () => {
    if (typeof allMarkets !== 'undefined' && allMarkets != null) {
      const chosenRegionID = allMarkets[rank - 1];
      const regionGeneralInfo = await findRegionGeneralInfo(chosenRegionID);

      setMarket({
        regionName: regionGeneralInfo.data.region_name,
        regionState: regionGeneralInfo.data.region_state,
        metrics: await getRegionRecord(chosenRegionID).then((response) => response.data),
        keyPoints: [''],
      });
    }
  }, [rank, allMarkets]);

  if (allMarkets == null) {
    return (
      <div>
        <MyAlert show={showAlert} setShow={setShowAlert} severity="error" message="Our website is experiencing heavy traffic. Please try again in a few minutes." />
        <Typography variant="h5" className="mainBody" style={{ marginTop: '50px' }}>
          {progressLabel}
        </Typography>
        <div style={{
          textAlign: 'center', top: '100px', marginTop: '10px',
        }}
        >
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MyAlert show={showAlert} setShow={setShowAlert} severity="error" message="There was an error retrieving your recommended markets. Make sure your preferences are on the Account Page are broad enough." />
      <div>
        <Pagination
          count={numMarkets}
          size="large"
          color="primary"
          page={rank}
          onChange={handleChange}
          sx={{
            marginTop: '0px',
            marginBottom: '20px',
            '& > *': {
              justifyContent: 'center',
              display: 'flex',
            },
          }}
        />
      </div>
      <ResearchContextProvider>
        <MarketSummaryV2
          rank={rank}
          regionId={allMarkets[rank - 1]}
          regionName={typeof market === 'undefined' ? '' : market.regionName}
          regionState={typeof market === 'undefined' ? '' : market.regionState}
          metrics={typeof market === 'undefined' ? {} : market.metrics}
          keyPoints={typeof market === 'undefined' ? [] : market.keyPoints}
        />
      </ResearchContextProvider>
    </div>
  );
}
