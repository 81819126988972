/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useEffect, useState } from 'react';
import {
  Paper, Typography, Chip, Grid, Button, ToggleButton,
  ToggleButtonGroup, Slide, List,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import RegionDetails from './regionDetails';
import '../assets/insightsPage.css';
import MarketDrivers from './marketDrivers';
import { axiosFindHistoricalRegionData } from '../modules/api';
import CustomLineChart from './customLineChart';

// Do not put this in the function marketSummary itself
const MyRegionDetails = React.forwardRef(
  (props, ref) => <div ref={ref}><RegionDetails {...props} /></div>,
);
const MyMarketDrivers = React.forwardRef(
  (props, ref) => <div ref={ref}><MarketDrivers {...props} /></div>,
);

function marketSummaryv2({
  rank, regionId, regionName, regionState, metrics, keyPoints,
}) {
  const [alignment, setAlignment] = React.useState('Region Details');
  const [alignment2, setAlignment2] = React.useState('Region Details');
  const [historicalRegionMetricData, setHistoricalRegionMetricData] = useState();

  useEffect(() => {
    if (regionId !== '') {
      const source = axios.CancelToken.source();
      axiosFindHistoricalRegionData(regionId, 'median_sales_price', source).then(
        (result) => {
          setHistoricalRegionMetricData(result.data);
        },
      );
    }
  }, [regionId]);

  const regionDetailsRef = React.useRef(null);

  const handleChange = (event) => {
    if (alignment === 'Region Details') {
      setAlignment('Key Market Drivers');
      setTimeout(() => setAlignment2('Key Market Drivers'), 200);
    } else {
      setAlignment2('Region Details');
      setTimeout(() => setAlignment('Region Details'), 200);
    }
  };

  return (
    <div className="mainBody marketSummaryContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              //   marginTop: '1em',
              borderRadius: '10px',
              padding: '5px 25px 25px 25px',
              height: 'auto',
              maxHeight: 'auto',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
            ref={regionDetailsRef}
          >
            <RegionDetails
              rank={rank}
              regionName={regionName}
              regionState={regionState}
              regionId={regionId}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              //   marginTop: '1em',
              borderRadius: '10px',
              padding: '25px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6"><b>Median Sales Price</b></Typography>
            <div style={{ flexGrow: 1 }}>
              <CustomLineChart
                seriesArray={[{
                  data: historicalRegionMetricData,
                  xAccessor: 'period_end',
                  yAccessor: (d) => d.median_sales_price,
                  metric: 'median_sales_price',
                }]}
                nice
              />
            </div>

          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper
            elevation={3}
            style={{
              //   marginTop: '1em',
              borderRadius: '10px',
              padding: '25px',
              height: 'auto',
            }}
          >
            <MarketDrivers
              regionId={regionId}
              regionName={regionName}
              regionState={regionState}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
export default marketSummaryv2;
