import React, { useContext } from 'react';
import {
  Paper, TableContainer, Table, TableBody, TableRow, TableHead, TableCell, TablePagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { styled } from '@mui/material/styles';
// import tableCellClasses from '@mui/material/TableCell';
import { ResearchContext } from './ResearchContext';

import stateAbbreviations from '../utils/stateAbbreviations';
import allMetricNames from '../utils/allMetrics';

const useStyles = makeStyles(() => ({
  henry: {
    '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
      alignItems: 'baseline',
      paddingRight: '0px',
      marginRight: '0px',
      marginLeft: 'auto',
    },

    '& .css-1juuzj4-MuiTableCell-root': {
      backgroundColor: '#eee',
    },

    '& .css-jdyvs4-MuiTableCell-root': {
      backgroundColor: '#eee',
    },
  },
}));

// const TableCell = styled(TableCell)(({ theme }) => ({
//   [`& .${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
// }));

export default function CountryWideTable({ rows, metric, height }) {
  const { regions } = useContext(ResearchContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <TableContainer sx={{ maxHeight: { height } }} component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: '#eee',
                }}
              >
                <b>Rank</b>

              </TableCell>
              <TableCell sx={{
                backgroundColor: '#eee',
              }}
              >
                <b>Region</b>

              </TableCell>
              <TableCell sx={{
                backgroundColor: '#eee',
              }}
              >
                <b>State</b>

              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#eee',
                }}
                align="right"
              >
                <b>{(typeof allMetricNames[metric] === 'undefined') ? 'Metric' : allMetricNames[metric]}</b>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const regionName = regions.filter((region) => region._id === row._id)[0].region_name;
              const state = regions.filter((region) => region._id === row._id)[0].region_state;

              return (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {rows.indexOf(row) + 1}
                  </TableCell>
                  <TableCell>{regionName}</TableCell>
                  <TableCell>{stateAbbreviations[state]}</TableCell>
                  <TableCell align="right">{row[metric]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        className={classes.henry}
        component="div"
        sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px',
        }}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </div>

  );
}
