/* eslint-disable max-len */
// React
import React, { useEffect } from 'react';

// MUI
import {
  Typography, Box, FormGroup, FormControlLabel, Checkbox,
  FormControl, Select, MenuItem, Button, Paper, Collapse,
  Alert, IconButton,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import CloseIcon from '@mui/icons-material/Close';

// My components
import '../assets/main.css';
import '../assets/accountPage.css';

// Database functions
import {
  getInvestorProfile,
  updateInvestorProfile,
} from '../modules/api';
import stateAbbreviations from '../utils/stateAbbreviations';

export default function InvestorProfile() {
  const [targetGeographicRegions, setTargetGeographicRegions] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [availableCash, setAvailableCash] = React.useState(0);
  const [investmentTimeline, setInvestmentTimeline] = React.useState('');
  const [financingPlan, setFinancingPlan] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    getInvestorProfile(localStorage.getItem('currUserId')).then((result) => {
      const returnedProfile = result.data[0];
      if (returnedProfile.target_geographic_regions) {
        setTargetGeographicRegions(returnedProfile.target_geographic_regions);
      }
      if (returnedProfile.available_cash) {
        setAvailableCash(returnedProfile.available_cash);
      }
      if (returnedProfile.investment_timeline) {
        setInvestmentTimeline(returnedProfile.investment_timeline);
      }
      if (returnedProfile.financing_plan) {
        setFinancingPlan(returnedProfile.financing_plan);
      }
    });
  }, []);

  const handleTargetGeographicRegionsChange = (event) => {
    const regionName = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setTargetGeographicRegions((prevSelectedRegions) => [...prevSelectedRegions, regionName]);
    } else {
      setTargetGeographicRegions((prevSelectedRegions) => prevSelectedRegions.filter(
        (name) => name !== regionName,
      ));
    }

    setDisabled(false);
  };

  const handleAvailableCashChange = (event) => {
    let cash;
    if (event.target.value === 100) {
      cash = 500000;
    } else {
      cash = 250000 * (event.target.value / 100);
    }
    setAvailableCash(cash);
    setDisabled(false);
  };

  const handleInvestmentTimelineChange = (event) => {
    const timeline = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setInvestmentTimeline((prev) => [...prev, timeline]);
    } else {
      setInvestmentTimeline((prev) => prev.filter(
        (name) => name !== timeline,
      ));
    }

    setDisabled(false);
  };

  const handleFinancingPlanChange = (event) => {
    setFinancingPlan(event.target.value);
    setDisabled(false);
  };

  const handleSubmit = () => {
    const userPreferencesObject = {
      _id: localStorage.getItem('currUserId'),
      target_geographic_regions: targetGeographicRegions,
      available_cash: availableCash,
      investment_timeline: investmentTimeline,
      financing_plan: financingPlan,
    };

    if (targetGeographicRegions.length === 0) {
      setAlertMessage('Choose at least one preferred region.');
      setShowAlert(true);
    } else if (availableCash === 0) {
      setAlertMessage('Choose a non-zero amount of cash.');
      setShowAlert(true);
    } else if (investmentTimeline.length === 0 || investmentTimeline === '') {
      setAlertMessage('Choose at least one preferred investment timeline.');
      setShowAlert(true);
    } else {
      updateInvestorProfile(userPreferencesObject);
      setDisabled(true);
    }
  };

  const cashMarks = [
    {
      value: 0,
      label: '$0',
    },
    {
      value: 50,
      label: '$125,000',
    },
    {
      value: 100,
      label: '$250,000+',
    },
  ];

  const valuetext = (value) => `$${value * 2500}`;
  const valueLabelFormat = (value) => value * 2500;

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={showAlert}>
          <Alert
            severity="error"
            action={(
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
          )}
            sx={{ mb: 2, backgroundColor: '#8d3030', color: 'white' }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>
      {/* <Typography variant="h3" className="mainBody"><strong>Investor Profile</strong></Typography> */}
      <div className="mainBody">
        <Paper
          variant="outlined"
          style={{
            marginTop: '1em', borderRadius: '10px', padding: '20px',
          }}
        >

          <Typography>
            <b>Target Geographic Regions:</b>
          </Typography>
          <FormGroup sx={{
            marginBottom: '3em',
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            {Object.keys(stateAbbreviations).map((state) => <FormControlLabel key={state} control={<Checkbox value={state} checked={targetGeographicRegions.includes(state)} onChange={handleTargetGeographicRegionsChange} />} label={state} />)}
            {/* <FormControlLabel control={<Checkbox value="Northeast" checked={targetGeographicRegions.includes('Northeast')} onChange={handleTargetGeographicRegionsChange} />} label="Northeast" />
                <FormControlLabel control={<Checkbox value="Southwest" checked={targetGeographicRegions.includes('Southwest')} onChange={handleTargetGeographicRegionsChange} />} label="Southwest" />
                <FormControlLabel control={<Checkbox value="Southeast" checked={targetGeographicRegions.includes('Southeast')} onChange={handleTargetGeographicRegionsChange} />} label="Southeast" />
                <FormControlLabel control={<Checkbox value="West" checked={targetGeographicRegions.includes('West')} onChange={handleTargetGeographicRegionsChange} />} label="West" />
                <FormControlLabel control={<Checkbox value="Midwest" checked={targetGeographicRegions.includes('Midwest')} onChange={handleTargetGeographicRegionsChange} />} label="Midwest" /> */}
          </FormGroup>
          <Typography sx={{ marginTop: '5em' }}>
            <b>Available Cash for Real Estate Investment:</b>
          </Typography>
          <Box sx={{ marginBottom: '3em' }}>
            <Slider
              sx={{ width: 550, marginLeft: '0.5em' }}
              aria-label="Custom marks"
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              marks={cashMarks}
              value={(typeof availableCash === 'string') ? 100 : ((availableCash / 250000) * 100)}
              onChange={handleAvailableCashChange}
            />
          </Box>
          <Typography sx={{ marginTop: '5em' }}>
            <b>Preferred Investment Timeline:</b>
          </Typography>
          <FormGroup sx={{
            marginBottom: '3em',
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <FormControlLabel control={<Checkbox value="Short Term" checked={investmentTimeline.includes('Short Term')} onChange={handleInvestmentTimelineChange} />} label="Short Term" />
            <FormControlLabel control={<Checkbox value="Long Term" checked={investmentTimeline.includes('Long Term')} onChange={handleInvestmentTimelineChange} />} label="Long Term" />
          </FormGroup>
          <Typography sx={{ marginTop: '5em' }}>
            <b>Financing Plan:</b>
          </Typography>
          <div className="sideBySide lastRow">
            <FormControl sx={{ minWidth: 350 }}>
              {/* <InputLabel id="demo-simple-select-label">Financing Types</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                size="small"
                id="demo-simple-select"
                value={financingPlan}
                placeholder="Financing Types"
                onChange={handleFinancingPlanChange}
                sx={{ marginTop: '0.5em' }}
              >
                <MenuItem value="Mortgage">Mortgage</MenuItem>
                <MenuItem value="All Cash">All Cash</MenuItem>
              </Select>
            </FormControl>
            <Button disabled={disabled} onClick={handleSubmit} variant="contained">Save Changes</Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}
