import React from 'react';
import './App.css';
import LandingPage from './components/landingPage';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>

  );
}

export default App;
