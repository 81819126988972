/* eslint-disable max-len */
/* eslint-disable prefer-const */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Typography, Grid, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import ToolBar from './toolBar';
import { UserContext } from './UserContext';
import MarketCard from './marketCard';
import AdvancedSideBar from './advancedSideBar';
import PropertyCard from './propertyCard';
import { axiosGetSomeProperties } from '../modules/api';

function SavedPage() {
  // const { regions } = useContext(ResearchContext);
  const { savedMarkets, savedProperties } = useContext(UserContext);
  const [allPropertyObjects, setAllPropertyObjects] = useState([]);
  const [marketsOrProperties, setMarketsOrProperties] = React.useState('Markets');

  // console.log('These are the saved property IDs');
  // console.log(savedProperties);

  // For dropdown
  const handleChange = (event) => {
    setMarketsOrProperties(event.target.value);
  };

  // Retrieve all property data for saved property IDs
  useEffect(async () => {
    axiosGetSomeProperties(savedProperties).then((result) => {
      setAllPropertyObjects(result.data);
    });
  }, [savedProperties]);

  const SavedItems = useCallback(() => {
    if (marketsOrProperties === 'Properties') {
      if (!allPropertyObjects || allPropertyObjects.length === 0) {
        return (
          <div style={{ textAlign: 'center' }}>
            <Typography>No Saved Properties</Typography>
          </div>
        );
      }

      // console.log('all properties saved');
      // console.log(allPropertyObjects);
      return (
        <Grid container spacing={4}>
          {allPropertyObjects && allPropertyObjects.map((property) => (
            <Grid key={property} item xs={12} sm={6} md={4} lg={3}>
              <PropertyCard property={property} />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (!savedMarkets || savedMarkets.length === 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Typography>No Saved Markets</Typography>
        </div>
      );
    }
    return (
      <Grid container spacing={4}>
        {savedMarkets && savedMarkets.map((market) => (
          <Grid key={market} item xs={12} sm={6} md={4}>
            <MarketCard regionId={market} />
          </Grid>
        ))}
      </Grid>
    );
  }, [marketsOrProperties, allPropertyObjects, savedMarkets]);

  return (
    <div>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Saved" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody"><strong>Saved</strong></Typography>
          <div className="mainBody">
            <div>
              <FormControl sx={{ m: 1, width: 200 }} size="small">
                <InputLabel id="demo-select-small-label">Markets or Properties</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={marketsOrProperties}
                  label="Markets or Properties"
                  onChange={handleChange}
                >
                  <MenuItem value="Markets">Markets</MenuItem>
                  <MenuItem value="Properties">Properties</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}><b>{marketsOrProperties}</b></Typography>
              <div>
                <SavedItems />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SavedPage;
