import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import '../assets/researchPage.css';
import LaunchIcon from '@mui/icons-material/Launch';

function NewsCard({ date, headline, source }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Paper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => { window.location.href = 'https://wsj.com'; }}
      sx={{
        position: 'relative',
        borderRadius: '10px',
        borderColor: '#a71e1e',
        borderLeft: '5px inset',
        padding: '1em',
        backgroundColor: '#eee',
        height: '100%',
        '&:hover': {
          backgroundColor: '#dae7ed',
          transform: 'scale(1.01)',

        // border: 'unset',
        },
      }}
    >
      <LaunchIcon sx={{
        color: 'gray',
        fontSize: '25px',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        display: `${hovered ? 'inline-block' : 'none'}`,
        // transform: 'translate(-50%, -50%)',
      }}
      />
      <div className="sideBySide" style={{ justifyContent: 'space-between' }}>
        <div>
          <Typography color="primary">
            <b>
              {date}
            </b>
          </Typography>
          <Typography variant="h5">
            {headline}
          </Typography>
          <div className="source">
            <Typography variant="subtitle1" sx={{ marginRight: '3px' }} color="#3c3c3c">
              Source:
              {' '}
              {source}
            </Typography>
          </div>
        </div>
        <img
          src="/background.jpg"
          alt="House"
          style={{ width: '200px', height: '200px' }}
        />
      </div>

    </Paper>
  );
}

export default NewsCard;
