import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../assets/toolbar.css';
import '../assets/landing.css';
import {
  Tooltip, IconButton, Avatar, Menu, MenuItem, ListItemIcon,
  Divider,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

export default function ToolBar({ isLoggedIn }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function goToLoginPage(e) {
    navigate('/login');
    e.preventdefault();
  }

  function goToPaymentOptionsPage(e) {
    navigate('/payment_options');
    e.preventdefault();
  }

  function goToSignupPage(e) {
    navigate('/signup');
    e.preventdefault();
  }

  function goToLandingPage(e) {
    window.location.href = '/';
    e.preventdefault();
  }

  // function scrollToBottom() {
  //   document.getElementById('keyFeaturesSection').scrollIntoView();
  // }

  return (
    <Box className="Tool" sx={{ backgroundColor: 'primary.main', zIndex: 20 }}>
      <Button
        style={{
          color: 'white',
          fontSize: '20px',
          marginLeft: '20px',
          marginRight: 'auto',
        }}
        onClick={() => goToLandingPage()}
      >
        SAILVEST
      </Button>
      {isLoggedIn ? (
        <>
          <Tooltip title="Account Settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ mr: 3 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
            >
              <Avatar
                src="/broken-image.jpg"
                sx={{
                  width: 32, height: 32, bgcolor: '#eee', color: 'black',
                }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => navigate('/account')}>
              <Avatar sx={{
                width: 5, height: 5, bgcolor: '#eee', color: 'black',
              }}
              />
              {' '}
              Account
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
              localStorage.removeItem('currUser');
              localStorage.clear();
              goToLandingPage();
            }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>

      // <Button
      //   variant="outlined"
      //   style={{
      //     color: 'white',
      //     fontSize: '17px',
      //     marginLeft: 'auto',
      //     marginRight: '20px',
      //     marginTop: 'auto',
      //     marginBottom: 'auto',
      //     border: '1px solid rgba(255, 255, 255, 0.5)',
      //   }}
      //   onClick={() => {
      //     localStorage.removeItem('currUser');
      //     localStorage.clear();
      //     goToLandingPage();
      //   }}
      // >
      //   Log Out
      // </Button>
      )
        : (
          <>
            {/* <Button
              variant="outlined"
              sx={{
                display: { xs: 'none', sm: 'block' },
                color: 'white',
                fontSize: '17px',
                marginLeft: 'auto',
                marginRight: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
                border: '1px solid rgba(255, 255, 255, 0.5)',

              }}
              onClick={() => scrollToBottom()}
            >
              Key Features
            </Button> */}
            <Button
              variant="outlined"
              sx={{
                display: { xs: 'none', sm: 'block' },
                color: 'white',
                fontSize: '17px',
                marginRight: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
                borderRadius: '10px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
              }}
              onClick={() => goToPaymentOptionsPage()}
            >
              Pricing
            </Button>
            <Button
              variant="outlined"
              className="hideOnMobile"
              sx={{
                display: { xs: 'none', sm: 'block' },
                color: 'white',
                fontSize: '17px',
                marginRight: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
                borderRadius: '10px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
              }}
              onClick={() => goToLoginPage()}
            >
              Log In
            </Button>
            <Button
              variant="outlined"
              className="hideOnMobile"
              sx={{
                display: { xs: 'none', sm: 'block' },
                color: 'white',
                fontSize: '17px',
                marginRight: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
                borderRadius: '10px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
              }}
              onClick={() => goToSignupPage()}
            >
              Sign Up
            </Button>
          </>
        )}
    </Box>
  );
}

ToolBar.defaultProps = {
  isLoggedIn: false,
};
