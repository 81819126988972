/* eslint-disable max-len */
// Formatted names of metrics
const allMetricNames = {
  // rental_vacancy_rate: 'Rental Vacancy Rate',
  // //   percent_built_after_2005: '',
  // //   percent_built_between_2000_to_2004: '',
  // //   percent_built_between_1990_to_1999: '',
  // //   percent_built_between_1980_to_1989: '',
  // //   percent_built_between_1970_to_1979: '',
  // //   percent_built_between_1960_to_1969: '',
  // //   percent_built_between_1950_to_1959: '',
  // //   percent_built_between_1940_to_1949: '',
  // //   percent_built_before_1939: '',
  // //   percent_no_bedroom: '',
  // //   percent_1_bedroom: '',
  // //   percent_2_bedrooms: '',
  // //   percent_3_bedrooms: '',
  // //   percent_4_bedrooms: '',
  // //   percent_more_than_5_bedrooms: '',
  // percent_owner_occupied: 'Percent Homes Owner Occupied',
  // percent_renter_occupied: 'Percent Homes Renter Occupied',
  // //   percent_value_less_than_50000: '',
  // //   percent_value_between_50000_to_99999: '',
  // //   percent_value_between_100000_to_149999: '',
  // //   percent_value_between_150000_to_199999: '',
  // //   percent_value_between_200000_to_299999: '',
  // //   percent_value_between_300000_to_499999: '',
  // //   percent_value_between_500000_to_999999: '',
  // //   percent_value_more_than_1000000: '',
  // median_value: 'Median Home Value (USD)',
  // //   median_SMOC: '',
  // median_rent: 'Median Rent (USD)',
  // population_estimate: 'Population Estimate',
  // total_buildings: 'Total Building Permits Issued',
  // // total_units: 'Total Units',
  // // total_value: 'Total Home Value (USD)',
  // // single_family_units: 'New Single Family Permits Issued (Monthly)',
  // // single_family_value: 'Singe Family Units Total Value (USD)',
  // // qtrly_estabs: '',
  // // month1_emplvl: '',
  // // month2_emplvl: '',
  // month3_emplvl: 'Employment Level',
  // // total_qtrly_wages: '',
  // avg_wkly_wage: 'Average Weekly Wage',
  // // new_listings: 'New Listings',
  // // closed_sales: 'Closed Sales',
  // // days_on_market: 'Days on Market',
  // // days_to_close: 'Days to Close',
  // // median_sales_price: 'Median Sales Price (USD)',
  // // percent_list_price_received: 'Percent List Price Received',
  // // active_listings: 'Active Listings',
  // // months_supply_inventory: 'Months Supply Inventory',
  median_value: 'Median Home Value (USD)',
  median_rent: 'Median Rent (USD)',
  population_estimate: 'Population Estimate',
  total_buildings: 'New Building Permits Issued',
  month3_emplvl: 'People Employed',
  avg_wkly_wage: 'Average Weekly Wage',
  rental_vacancy_rate: 'Rental Vacancy Rate',
  percent_owner_occupied: 'Percent Homes Owner Occupied',
  percent_renter_occupied: 'Percent Homes Renter Occupied',
  median_sales_price: 'Median Sales Price (USD)',
  price_to_rent_ratio: 'Price to Rent Ratio',
  median_days_on_market: 'Median Days on Market',
};

// export const currentSnapshotMetrics = {
//   median_sales_price: { display_name: 'Median Sales Price', category: 'Current Snapshot' },
//   median_rent: { display_name: 'Median Rent', category: 'Current Snapshot' },
//   price_to_rent_ratio: { display_name: 'Price to Rent Ratio', category: 'Current Snapshot' },
//   rental_vacancy_rate: { display_name: 'Rental Vacancy Rate', category: 'Current Snapshot' },
//   percent_owner_occupied: { display_name: 'Percent Homes Owner Occupied', category: 'Current Snapshot' },
//   percent_renter_occupied: { display_name: 'Percent Homes Renter Occupied', category: 'Current Snapshot' },
//   fed_funds_rate: { display_name: 'Federal Funds Rate', category: 'Current Snapshot' },
// };

// export const marketFundamentalsMetrics = {
//   total_buildings: { display_name: 'New Building Permits Issued', category: 'Market Fundamentals' },
//   wrluri_level: { display_name: 'Wharton Residential Land Use Regulation Index', category: 'Market Fundamentals' },
//   population_estimate: { display_name: 'Population', category: 'Market Fundamentals' },
//   month3_emplvl: { display_name: 'People Employed', category: 'Market Fundamentals' },
//   avg_wkly_wage: { display_name: 'Average Weekly Wage', category: 'Market Fundamentals' },
// };

// export const liquidityMetrics = [
//   {
//     metric: 'closed_sales',
//     display_name: 'Closed Sales',
//     category: 'Liquidity',
//   },
//   {
//     metric: 'days_on_market',
//     display_name: 'Days on Market',
//     category: 'Liquidity',
//   },
// ];

export const allMetrics = {
  median_sales_price: {
    display_name: 'Median Sales Price', comparison: 'Growth', graph: 'Yes', category: 'Current Snapshot',
  },
  median_rent: {
    display_name: 'Median Rent', comparison: 'Growth', graph: 'Yes', category: 'Current Snapshot',
  },
  price_to_rent_ratio: {
    display_name: 'Price to Rent Ratio', comparison: 'Value', graph: 'Yes', category: 'Current Snapshot',
  },
  rental_vacancy_rate: {
    display_name: 'Rental Vacancy Rate', comparison: 'Value', graph: 'Yes', category: 'Current Snapshot',
  },
  percent_owner_occupied: {
    display_name: 'Percent Homes Owner Occupied', comparison: 'Value', graph: 'Yes', category: 'Current Snapshot',
  },
  percent_renter_occupied: {
    display_name: 'Percent Homes Renter Occupied', comparison: 'Value', graph: 'Yes', category: 'Current Snapshot',
  },
  fed_funds_rate: {
    display_name: 'Federal Funds Rate', comparison: 'None', graph: 'Yes', category: 'Current Snapshot',
  },
  population_estimate: {
    display_name: 'Population', comparison: 'Growth', graph: 'Yes', category: 'Market Fundamentals',
  },
  month3_emplvl: {
    display_name: 'People Employed', comparison: 'Growth', graph: 'Yes', category: 'Market Fundamentals',
  },
  avg_wkly_wage: {
    display_name: 'Average Weekly Wage', comparison: 'Growth', graph: 'Yes', category: 'Market Fundamentals',
  },
  total_buildings: {
    display_name: 'New Building Permits Issued', comparison: 'Growth', graph: 'Yes', category: 'Market Fundamentals',
  },
  wrluri_level: {
    display_name: 'Wharton Residential Land Use Regulation Index', comparison: 'None', graph: 'No', category: 'Market Fundamentals',
  },
  closed_sales: {
    display_name: 'Closed Sales', comparison: 'Value', graph: 'No', category: 'Liquidity',
  },
  median_days_on_market: {
    display_name: 'Days on Market', comparison: 'Value', graph: 'No', category: 'Liquidity',
  },
};

export function formatMetricValue(value, metric) {
  if (value === 'Unavailable' || value === '...') {
    return value;
  }

  if (value === undefined || value === null) {
    return 'Unavailable';
  }

  switch (metric) {
    case 'median_sales_price':
      return `$${value.toLocaleString('en-US')}`;
    case 'median_rent':
      return `$${value.toLocaleString('en-US')}`;
    case 'dollar':
      return `$${value.toLocaleString('en-US')}`;
    case 'rental_vacancy_rate':
      return `${value}%`;
    case 'percent':
      return `${value}%`;
    case 'percent_owner_occupied':
      return `${value}%`;
    case 'percent_renter_occupied':
      return `${value}%`;
    case 'fed_funds_rate':
      return `${value}%`;
    case 'avg_wkly_wage':
      return `$${value.toLocaleString('en-US')}`;
    case 'wrluri_level':
      return value.charAt(0).toUpperCase() + value.slice(1);
    default:
      return value.toLocaleString('en-US');
  }
}
export default allMetricNames;
