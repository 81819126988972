/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, Paper, Typography, Box, CircularProgress,
} from '@mui/material';
import axios from 'axios';
import LiquidityIndicator from './liquidityIndicator';
import { generateInterpretation } from '../utils/explorePageFunctions';
import { axiosScaleMetricZeroToOne } from '../modules/api';
import SMVTitleCard from './smvTitleCard';
import { ResearchContext } from './ResearchContext';

function liquidity({ regionRecord }) {
  const [liquidityValue, setLiquidityValue] = useState();

  const { regions } = useContext(ResearchContext);
  const regionName = (regionRecord !== undefined && regionRecord !== '' && regions !== undefined && regions.length !== 0) ? (regions.filter((region) => region._id === regionRecord.region_id))[0].region_name : '...';

  useEffect(() => {
    const source = axios.CancelToken.source();

    axiosScaleMetricZeroToOne(regionRecord.region_id, 'median_days_on_market', source).then(
      (result) => {
        setLiquidityValue(result.data);
      },
    );
  }, []);

  function getRegionRecordValue(value) {
    if (regionRecord === '' || regionRecord === undefined) {
      return '';
    }
    if (typeof regionRecord[value] === 'undefined') {
      return 'N/A';
    }
    return regionRecord[value];
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SMVTitleCard regionRecord={regionRecord} />
      </Grid>
      <Grid item container xs={12} sm={7} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ borderRadius: '10px', padding: '0.6em' }}
          >
            <Typography><b>Closed Sales</b></Typography>
            <Typography variant="h4">{getRegionRecordValue('closed_sales')}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ borderRadius: '10px', padding: '0.6em' }}
          >
            <Typography><b>Days on Market</b></Typography>
            <Typography variant="h4">{getRegionRecordValue('median_days_on_market')}</Typography>
          </Paper>

        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper
            variant="outlined"
            sx={{ borderRadius: '10px', padding: '0.6em', whiteSpace: 'pre-wrap' }}
          >
            <Typography><b>Explanation</b></Typography>
            <Typography>
              {generateInterpretation(regionName, 'liquidity', null)}
            </Typography>
          </Paper>

        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', padding: '0.6em', height: '100%' }}
        >
          <Typography><b>Overall Liquidity Indicator</b></Typography>
          {
            (regionRecord === undefined || regionRecord === '' || liquidityValue === undefined)
              ? (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: 100,
                  alignItems: 'center',
                }}
                >
                  <CircularProgress />
                </Box>
              )
              : <LiquidityIndicator value={liquidityValue} />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default liquidity;
