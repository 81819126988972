/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Paper, Chip,
} from '@mui/material';
import '../assets/insightsPage.css';
import GoogleMap from './googleMap';
import { ResearchContext } from './ResearchContext';

function regionDetails({
  rank, regionName, regionState, regionId,
}) {
  const [centerCoordinates, setCenterCoordinates] = useState();
  const { regions } = useContext(ResearchContext);

  const navigate = useNavigate();

  useEffect(async () => {
    if (regions) {
      setCenterCoordinates(regions.filter((region) => region._id === regionId)[0].coordinates);
    }

    // console.log(centerCoordinates);
  }, [regionName, regionState, regions]);

  return (
    <div className="mainBody regionDetailsSection">
      <div className="regionDetailsComponents">
        <div className="sideBySide nameAndRank">
          <Typography variant="h3"><strong>{regionName}</strong></Typography>
          <Chip
            label={rank}
            variant="contained"
            color="primary"
            sx={{
              height: '50px',
              borderRadius: '25px',
              width: '50px',
              fontSize: '25px',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: '10px',
            }}
          />
        </div>
        <Typography variant="h4" color="primary">{regionState}</Typography>
        <Chip
          label="View More Details"
          variant="contained"
          onClick={() => navigate(`/explore/${regionId}`)}
          sx={{ marginTop: '10px' }}
        />
        <Paper
          variant="outlined"
          sx={{
            height: '300px', padding: '10px', backgroundColor: '#eee', marginTop: '25px',
          }}
        >
          {centerCoordinates !== undefined ? (
            <GoogleMap
              center={centerCoordinates}
              markers={[{
                name: regionName,
                state: regionState,
                lat: centerCoordinates.lat,
                lng: centerCoordinates.lng,

              }]}
              zoom={7}
            />
          ) : 'Map Loading'}
        </Paper>
      </div>
    </div>
  );
}

export default regionDetails;
