/* eslint-disable import/no-cycle */
import React, {
  useState, useContext,
} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import {
  MenuItem, Select, FormControl, InputLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { hash } from '../modules/hash';
import ToolBar from './toolBar';
import {
  getAllUsers, axiosSendResetCodeEmail,
} from '../modules/api';
import '../assets/login.css';
import { SignUpContext } from './signupPage';

export default function SignUp() {
  const {
    email, setEmail, setPage, setOTP, setUser,
  } = useContext(SignUpContext);
  const paperStyle = {
    borderRadius: '10px',
    // border: '2px solid black',
    width: '750px',
    margin: '20px auto',
    padding: '25px',
    justifyContent: 'center',
    flex: 'column',
  };

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { marginTop: '25px', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  const [newusername, setNewusername] = useState('');
  const [newpassword, setNewpassword] = useState('');
  const [newfirstname, setNewfirstname] = useState('');
  const [newTier, setNewTier] = useState(localStorage.getItem('signup_subscription'));
  const [newlastname, setNewlastname] = useState('');
  const [registrationShow, setRegistrationShow] = useState(false);
  const [registrationText, setRegistrationText] = useState('');
  const [successShow, setSuccessShow] = useState(false);
  const users = React.useRef([]);

  async function fetchUsers() {
    try {
      const val = await getAllUsers();
      users.current = val.data;
      return val;
    } catch (e) {
      return e;
    }
  }

  /* eslint-disable */
  async function addUser(user) {
    const OTP = Math.floor(Math.random() * 900000 + 100000);
    // console.log('OTP');
    // console.log(OTP);
    setOTP(OTP);

    axiosSendResetCodeEmail(email, 'Sailvest Verification Code', OTP).then(() => {
      // Switch to OTP page
      // console.log(user);
      setPage('otp');
    }).catch((err) => {
      console.log('Sending Mail Error');
      console.log(err);
    });
  }

  function validateCreation() {
    if (newfirstname.length <= 0 || newlastname.length <= 0) {
      setRegistrationText('Name cannot be empty.');
      return false;
    }

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.length <= 0 || !validRegex.test(email)) {
      setRegistrationText('Please enter a valid email.');
      return false;
    }
    if (newusername.length <= 0) {
      setRegistrationText('Username cannot be empty.');
      return false;
    }
    let i;
    for (i = users.current.length - 1; i >= 0; i -= 1) {
      if (users.current[i].username === newusername) {
        setRegistrationText('Username already exists.');
        return false;
      }
    }
    i = 0;
    for (i = users.current.length - 1; i >= 0; i -= 1) {
      if (users.current[i].email === email) {
        setRegistrationText('Email already exists.');
        return false;
      }
    }
    if (newpassword.length <= 7
        || newpassword.toLowerCase() === newpassword
        || newpassword.toUpperCase() === newpassword) {
      setRegistrationText('Password doesn\'t meet requirements (Must be more than 7 characters and contain upper and lowercase characters).');
      return false;
    }

    if (!newTier) {
      setRegistrationText('No subscription tier chosen.');
      return false;
    }
    return true;
  }

  async function handleCreateAccount() {
    if (!validateCreation()) {
      setRegistrationShow(true);
    } else {
      const newUser = {
        username: newusername,
        password: hash(newpassword),
        email,
        first_name: newfirstname,
        last_name: newlastname,
        available_cash: 0,
        financing_plan: 'Mortgage',
        investment_timeline: ['Long Term'],
        target_geographic_regions: [],
      };
      setUser(newUser);
      setSuccessShow(true);
      setRegistrationShow(false);
      setTimeout(() => addUser(newUser), 1000);
      setNewusername('');
      setNewpassword('');
      setNewlastname('');
      setNewfirstname('');
      // setEmail('');
    }
  }

  const tripleCreateCall = async () => {
    await fetchUsers();
    await handleCreateAccount();
  };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Grid style={{ marginTop: '0%', width: '100vw' }}>
        <Paper elevation={10} style={paperStyle}>
            <div>
              <Grid align="center" sx={{marginBottom: '35px'}}>
                <Avatar style={avatarStyle}>
                  <LockOutlinedIcon />
                </Avatar>
                <h2>Sign Up</h2>
              </Grid>
              <TextField
                label="First Name"
                // placeholder="Enter First Name"
                style={textFieldStyle}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={newfirstname}
                onChange={(e) => setNewfirstname(e.target.value)}
              />
              <TextField
                label="Last Name"
                // placeholder="Enter Last Name"
                style={textFieldStyle}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={newlastname}
                onChange={(e) => setNewlastname(e.target.value)}
              />
              <TextField
                label="Email Address"
                // placeholder="Enter Email Address"
                style={textFieldStyle}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Username"
                // placeholder="Enter username"
                style={textFieldStyle}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={newusername}
                onChange={(e) => setNewusername(e.target.value)}
              />
              <TextField
                label="Password"
                // placeholder="Enter password"
                style={textFieldStyle}
                type="password"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
              />
              <FormControl fullWidth sx={{marginTop: '10px'}}>
              <InputLabel id="demo-simple-select-label">Subscription Tier</InputLabel>
              <Select 
              fullWidth
              label="Subscription Tier"
              onChange={(e) => {
                setNewTier(e.target.value);
                localStorage.setItem('signup_subscription', e.target.value);
              }}
              value={newTier}>
                <MenuItem value="Free">Sailvest Free</MenuItem>
                <MenuItem value="Sailvest Basic">Sailvest Basic | $10/month</MenuItem>
                <MenuItem value="Sailvest Plus">Sailvest Plus | $25/month</MenuItem>
                <MenuItem value="Sailvest Premium">Sailvest Premium | $40/month</MenuItem>
              </Select>
              </FormControl>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
                onClick={tripleCreateCall}
              >
                Sign up
              </Button>
              <Stack sx={{ width: '100%' }} spacing={2}>
            <Collapse in={successShow}>
              <Alert
                style={{ marginTop: '10px' }}
                action={
                  (
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setSuccessShow(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                sx={{ mb: 2 }}
              >
                Navigating to verification page...
              </Alert>
            </Collapse>
            <Collapse in={registrationShow}>
              <Alert
                severity="error"
                style={{ marginTop: '10px' }}
                action={
                  (
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setRegistrationShow(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                sx={{ mb: 2 }}
              >
                {registrationText}
              </Alert>
            </Collapse>
          </Stack>
            </div>
        </Paper>
      </Grid>
    </div>
  );
}
