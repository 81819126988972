/* eslint-disable max-len */
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box, styled, Button,
} from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import stateAbbreviations from '../utils/stateAbbreviations';
// import { makeStyles } from '@mui/styles';
import { ResearchContext } from './ResearchContext';
import BookmarkButton from './bookmarkButton';
import { formatMetricValue } from '../utils/allMetrics';

// Material UI comparator doesn't seem to support greater than or equal to, so adding the small constant is necessary
const metricComparator = (v1, v2) => v1 - v2 - 0.0000000001;

const MyButton = styled(Button)(() => ({
  color: '#000000',
  paddingRight: '15px',
  paddingLeft: '15px',
  borderRadius: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#1a5c92',
    color: '#ffffff',
  },
}));

export default function AdvancedCountrywideTable({
  rows, metric, height, handleOrderChange,
}) {
  const navigate = useNavigate();

  const renderRegionWithBookmark = (params) => (
    <div className="centeredRow">
      <MyButton onClick={() => { navigate(`/explore/${params.row.region_id}`); }}>{params.row.regionName}</MyButton>
      <BookmarkButton selectedRegion={params.row.region_id} sizeInPixels={20} />
    </div>
  );

  const columns = [
    {
      field: 'regionRank',
      headerName: 'Rank',
      sortable: false,
      minWidth: 75,
      flex: 0.2,
      renderHeader: () => (
        <b>
          Rank
        </b>
      ),
      valueGetter: (params) => `${params.row.regionRank || ''}`,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'regionName',
      headerName: 'Region',
      sortable: false,
      minWidth: 250,
      flex: 0.5,
      renderHeader: () => (
        <b>
          Region
        </b>
      ),
      valueGetter: (params) => `${params.row.regionName || ''}`,
      renderCell: renderRegionWithBookmark,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'regionState',
      headerName: 'State',
      sortable: false,
      minWidth: 75,
      flex: 0.2,
      renderHeader: () => (
        <b>
          State
        </b>
      ),
      valueGetter: (params) => `${params.row.regionState || ''}`,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'regionMetric',
      headerName: 'Metric',
      headerClassName: 'super-app-theme--header',
      minWidth: 175,
      flex: 0.1,
      renderHeader: () => (
        <b>
          Metric
        </b>
      ),
      valueFormatter: (params) => `${formatMetricValue(params.value, metric)}`,
      type: 'number',
      sortComparator: metricComparator,
    },
  ];

  const handleSortChange = (model) => {
    const sortOrder = model[0]?.sort;
    if (sortOrder === 'asc') {
      handleOrderChange(true);
    } else if (sortOrder === 'desc') {
      handleOrderChange(false);
    }
  };

  const { regions } = useContext(ResearchContext);

  const processedRows = rows.map((row, i) => {
    if ((regions.filter((region) => region._id === row._id))[0] === undefined) {
      // console.log(row);
    }
    const name = (regions.filter((region) => region._id === row._id))[0].region_name;
    const regionState = regions.filter((region) => region._id === row._id)[0].region_state;
    return {
      id: i + 1,
      region_id: row._id,
      regionRank: i + 1,
      regionName: name,
      regionState: stateAbbreviations[regionState],
      regionMetric: row[metric],
    };
  });

  return (
    <Box
      sx={{
        height,
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: '#eee',
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        rows={processedRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        onSortModelChange={handleSortChange}
        rowsPerPageOptions={[10, 25, 100]}
      />
    </Box>
  );
}
