/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Grid, Tabs, Tab, Box,
} from '@mui/material';
import '../assets/insightsPage.css';
import '../assets/main.css';
import axios from 'axios';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import moment from 'moment';
import HistoricalBarChart from './historicalBarChart';
import { axiosFindHistoricalRegionData, getNationalAverageForMetric } from '../modules/api';
import CustomLineChart from './customLineChart';

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {
        value === index && (<div>{children}</div>)
}
    </div>
  );
}

function marketDrivers({ regionId }) {
  const [populationGrowth, setPopulationGrowth] = useState();
  const [populationIncrease, setPopulationIncrease] = useState();
  // const [averagePopulationGrowth, setAveragePopulationGrowth] = useState();
  const [employmentGrowth, setEmploymentGrowth] = useState();
  // const [averageEmploymentGrowth, setAverageEmploymentGrowth] = useState();
  const [wageGrowth, setWageGrowth] = useState();
  // const [averageWageGrowth, setAverageWageGrowth] = useState();
  const [buildingPermits, setBuildingPermits] = useState();
  const [populationBullet1, setPopulationBullet1] = useState();
  const [populationBullet2, setPopulationBullet2] = useState();
  const [populationBullet3, setPopulationBullet3] = useState();

  const [employmentBullet1, setEmploymentBullet1] = useState();
  const [employmentBullet2, setEmploymentBullet2] = useState();
  const [employmentBullet3, setEmploymentBullet3] = useState();

  const [wageBullet1, setWageBullet1] = useState();
  const [wageBullet2, setWageBullet2] = useState();
  const [wageBullet3, setWageBullet3] = useState();

  const [supplyBullet1, setSupplyBullet1] = useState();
  const [supplyBullet2, setSupplyBullet2] = useState();
  const [supplyBullet3, setSupplyBullet3] = useState();

  const [populationPositive, setPopulationPositive] = useState(true);
  const [employmentPositive, setEmploymentPositive] = useState(true);
  const [wagePositive, setWagePositive] = useState(true);
  const [supplyPositive, setSupplyPositive] = useState(true);

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (regionId !== '') {
      const source = axios.CancelToken.source();
      axiosFindHistoricalRegionData(regionId, 'population_estimate', source).then(
        (result) => {
          setPopulationGrowth(result.data);

          // calculating the amount of data points we have
          const numYears = result.data.length;

          // calculating growth rate of population
          const popGrowthRate = (((result.data[numYears - 1].population_estimate
            / result.data[0].population_estimate) - 1) * 100).toFixed(2);

          setPopulationBullet1(`The population changed by ${popGrowthRate}% over the past ${numYears} years.`);

          getNationalAverageForMetric('population_estimate').then((resultAvg) => {
            // setAveragePopulationGrowth(result.data);

            // calculating the amount of data points we have
            const numYearsAvg = resultAvg.data.length;

            // calculating growth rate of population
            const averagePopGrowthRate = (((resultAvg.data[numYearsAvg - 1].average_value
              / resultAvg.data[0].average_value) - 1) * 100).toFixed(2);
            setPopulationBullet2(`The average county population changed by 
              ${averagePopGrowthRate}% over the past ${numYearsAvg} years.`);

            if (parseFloat(popGrowthRate, 10) > parseFloat(averagePopGrowthRate, 10)) {
              setPopulationBullet3('This county has a higher growth rate than average, which could indicate a high demand for housing.');
              setPopulationPositive(true);
            } else {
              setPopulationBullet3('This county has a lower growth rate than average, which could indicate a low demand for housing.');
              setPopulationPositive(false);
            }
          });

          axiosFindHistoricalRegionData(regionId, 'total_buildings', source).then(
            (resultSupply) => {
              // only keep the years that match the population data
              const output = resultSupply.data.filter((datapoint) => (moment(datapoint.period_end) >= moment('2011-01-31T00:00:00.000Z')
               && moment(datapoint.period_end) <= moment('2021-12-31T23:59:59.999+00:00')));

              // sum up building permits
              let sum = 0;
              let finalOutput = [];
              Object.values(output).forEach((datapoint) => {
                // ignore 2020 because there is no population data for that year,
                // we just sum it up with the 2021 data
                if ((moment(datapoint.period_end).year())) {
                  if ((moment(datapoint.period_end).month() + 1) !== 12
                  || (moment(datapoint.period_end).date() + 1) !== 31) {
                    sum += datapoint.total_buildings;
                  } else {
                    sum += datapoint.total_buildings;
                    if (moment(datapoint.period_end).year() !== 2020) {
                      finalOutput.push(
                        {
                          _id: datapoint._id,
                          period_start: `${moment(datapoint.period_end).year().toString()}-01-01T00:00:00.000+00:00`,
                          period_end: `${moment(datapoint.period_end).year().toString()}-12-31T23:59:59.999+00:00`,
                          total_buildings: sum,
                        },
                      );
                      sum = 0;
                    }
                  }
                }
              });

              // the building permits some times has gaps in it
              // so we find the first continuous block
              // the exception is 2020 which was no data
              let firstBuildingYearPosition = finalOutput.length - 1;
              for (let i = firstBuildingYearPosition; i > 0; i -= 1) {
                if ((moment(finalOutput[i - 1].period_start).year()
                  === moment(finalOutput[i].period_start).year() - 1)
                  || (moment(finalOutput[i - 1].period_start).year()) === 2018) {
                  firstBuildingYearPosition = i - 1;
                } else {
                  break;
                }
              }
              // calculating the increase in population
              // and make sure the dates match with building permits
              // we set 2021 as null because we don't have 2020 data
              const popIncrease = [];
              for (let i = 1; i < result.data.length; i += 1) {
                if (moment(result.data[i].period_start)
                  >= moment(finalOutput[firstBuildingYearPosition].period_start)) {
                  if (moment(result.data[i].period_start).year() === 2020) {
                    popIncrease.push(
                      {
                        _id: result.data[i]._id,
                        period_start: result.data[i].period_start,
                        period_end: result.data[i].period_end,
                        population_increase: null,
                      },
                    );
                  } else {
                    popIncrease.push(
                      {
                        _id: result.data[i]._id,
                        period_start: result.data[i].period_start,
                        period_end: result.data[i].period_end,
                        population_increase: result.data[i].population_estimate
                        - result.data[i - 1].population_estimate,
                      },
                    );
                  }
                }
              }

              finalOutput = finalOutput.slice(firstBuildingYearPosition);
              setBuildingPermits(finalOutput);
              setPopulationIncrease(popIncrease);

              // calculating the amount of data points we have
              const numYearsSupply = finalOutput.length;

              // calculate total number of permits issued
              let totalSumSupply = 0;
              Object.values(finalOutput).forEach((datapoint) => {
                // ignore 2020 because there is no population data for that year,
                // we just sum it up with the 2021 data
                totalSumSupply += datapoint.total_buildings;
              });

              let totalSumPopIncrease = 0;
              Object.values(popIncrease).forEach((datapoint) => {
                // ignore 2020 because there is no population data for that year,
                // we just sum it up with the 2021 data
                totalSumPopIncrease += datapoint.population_increase;
              });

              setSupplyBullet1(`The total building permits issued over the past ${numYearsSupply} years was ${totalSumSupply}.`);
              setSupplyBullet2(`The total population change over the past ${numYearsSupply} years was ${totalSumPopIncrease}.`);

              if (parseInt(totalSumSupply, 10) < parseInt(totalSumPopIncrease, 10)) {
                setSupplyBullet3(`The total permit deficit of ${totalSumPopIncrease - totalSumSupply} 
                  indicates a potential supply constraint which could lift housing prices.`);
                setSupplyPositive(true);
              } else {
                setSupplyBullet3(`The total permits issued is greater than the population increase 
                  which could indicate a supply surplus, which could decrease housing prices.`);
                setSupplyPositive(false);
              }
            },
          );
        },
      );

      axiosFindHistoricalRegionData(regionId, 'month3_emplvl', source).then(
        (result) => {
          setEmploymentGrowth(result.data);

          // calculating the amount of data points we have
          const dataPoints = result.data.length;

          const numYears = moment(result.data[dataPoints - 1].period_end).year()
            - moment(result.data[0].period_end).year();

          // calculating growth rate of employment
          const empGrowthRate = (((result.data[dataPoints - 1].month3_emplvl
            / result.data[0].month3_emplvl) - 1) * 100).toFixed(2);

          setEmploymentBullet1(`The employment level changed by ${empGrowthRate}% over the past ${numYears} years.`);

          getNationalAverageForMetric('month3_emplvl').then((resultAvg) => {
            // calculating the amount of data points we have
            const dataPointsAvg = resultAvg.data.length;

            const numYearsAvg = moment(resultAvg.data[dataPointsAvg - 1]._id).year()
              - moment(resultAvg.data[0]._id).year();

            // calculating growth rate of employment
            const averageEmpGrowthRate = (((resultAvg.data[dataPointsAvg - 1].average_value
              / resultAvg.data[0].average_value) - 1) * 100).toFixed(2);
            setEmploymentBullet2(`The average county employment level changed by 
              ${averageEmpGrowthRate}% over the past ${numYearsAvg} years.`);

            if (parseFloat(empGrowthRate, 10) > parseFloat(averageEmpGrowthRate, 10)) {
              setEmploymentBullet3('This county has a higher employment growth rate than average, which could indicate a high demand for housing.');
              setEmploymentPositive(true);
            } else {
              setEmploymentBullet3('This county has a lower employment growth rate than average, which could indicate a low demand for housing.');
              setEmploymentPositive(false);
            }
          });
        },
      );
      axiosFindHistoricalRegionData(regionId, 'avg_wkly_wage', source).then(
        (result) => {
          setWageGrowth(result.data);

          // calculating the amount of data points we have
          const dataPoints = result.data.length;

          const numYears = moment(result.data[dataPoints - 1].period_end).year()
            - moment(result.data[0].period_end).year();

          // calculating growth rate of wages
          const wageGrowthRate = (((result.data[dataPoints - 1].avg_wkly_wage
            / result.data[0].avg_wkly_wage) - 1) * 100).toFixed(2);

          setWageBullet1(`The average weekly wage changed by ${wageGrowthRate}% over the past ${numYears} years.`);

          getNationalAverageForMetric('avg_wkly_wage').then((resultAvg) => {
            // calculating the amount of data points we have
            const dataPointsAvg = resultAvg.data.length;

            const numYearsAvg = moment(resultAvg.data[dataPointsAvg - 1]._id).year()
              - moment(resultAvg.data[0]._id).year();

            // calculating growth rate of wages
            const averageWageGrowthRate = (((resultAvg.data[dataPointsAvg - 1].average_value
              / resultAvg.data[0].average_value) - 1) * 100).toFixed(2);
            setWageBullet2(`The average county weekly wage level changed by 
              ${averageWageGrowthRate}% over the past ${numYearsAvg} years.`);

            if (parseFloat(wageGrowthRate, 10) > parseFloat(averageWageGrowthRate, 10)) {
              setWageBullet3('This county has a higher wage growth rate than average, which could indicate a high demand for housing.');
              setWagePositive(true);
            } else {
              setWageBullet3('This county has a lower wage growth rate than average, which could indicate a low demand for housing.');
              setWagePositive(false);
            }
          });
        },
      );
    }
  }, [regionId]);

  return (
    <div className="mainBody marketDriversSection">
      <Typography
        variant="h5"
        sx={{
          marginLeft: 'auto', marginRight: 'auto', width: '250px', marginBottom: '1.5em',
        }}
      >
        <b>Key Market Drivers</b>
      </Typography>
      {/* <Paper
        variant="outlined"
        sx={{
          height: 'auto',
          width: '100%',
          minWidth: '500px',
          padding: '1.25em',
          backgroundColor: '#eee',
          marginBottom: '2.5em',
        }}
      >
        <Button>Single Market View</Button>
        <Button>Realtors</Button>
      </Paper> */}
      <TabPanel value={tabValue} index={0}>
        <Paper
          variant="outlined"
          sx={{
            height: 'auto',
            width: '100%',
            minWidth: '500px',
            padding: '15px',
            backgroundColor: '#eee',
            marginRight: '2.5em',
            marginBottom: '2.5em',
          }}
        >
          <div className="sideBySideSpacedOut">
            <Typography variant="h6"><b>Population Growth</b></Typography>
            {populationPositive ? <div className="squareGreen" /> : <div className="squareRed" />}
          </div>
          <Grid container spacing={2} sx={{ marginTop: '5px', height: '90%' }}>
            <Grid item xs={7}>
              <CustomLineChart
                seriesArray={[{
                  data: populationGrowth,
                  xAccessor: 'period_end',
                  yAccessor: (d) => d.population_estimate,
                  metric: 'population_estimate',
                }]}
                nice
              />
              {/* <ParentSize>
                {({ width, height }) => (
                  <HistoricalLineChart
                    width={width + 10}
                    height={height - 30}
                    seriesArray={[{
                      data: populationGrowth,
                      xaccessor: 'period_end',
                      yaccessor: 'population_estimate',
                    }]}
                    metric="population_estimate"
                  />
                )}
              </ParentSize> */}
            </Grid>
            <Grid item xs={5}>
              <Paper sx={{
                height: '90%',
                minHeight: '375px',
                padding: '15px',
                backgroundColor: '#fff',
              }}
              >
                <Typography variant="h6"><u>Key Points</u></Typography>
                <Typography variant="h6">
                  {populationBullet1}
                  {' '}
                  {populationBullet2}
                  {' '}
                  {populationBullet3}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Paper
          variant="outlined"
          sx={{
            height: 'auto',
            width: '100%',
            minWidth: '500px',
            padding: '15px',
            backgroundColor: '#eee',
            marginRight: '2.5em',
            marginBottom: '2.5em',
          }}
        >
          <div className="sideBySideSpacedOut">
            <Typography variant="h6"><b>Employment Growth</b></Typography>
            {employmentPositive ? <div className="squareGreen" /> : <div className="squareRed" />}
          </div>
          <Grid container spacing={2} sx={{ marginTop: '5px', height: '90%' }}>
            <Grid item xs={7}>
              <CustomLineChart
                seriesArray={[{
                  data: employmentGrowth,
                  xAccessor: 'period_end',
                  yAccessor: (d) => d.month3_emplvl,
                  metric: 'month3_emplvl',
                }]}
                nice
              />
              {/* <ParentSize>
                {({ width, height }) => (
                  <HistoricalLineChart
                    width={width + 10}
                    height={height - 30}
                    seriesArray={[{ data: employmentGrowth,
                      xaccessor: 'period_end',
                      yaccessor: 'month3_emplvl' }]}
                    metric="month3_emplvl"
                  />
                )}
              </ParentSize> */}
            </Grid>
            <Grid item xs={5}>
              <Paper sx={{
                height: '90%',
                minHeight: '375px',
                padding: '15px',
                backgroundColor: '#fff',
              }}
              >
                <Typography variant="h6"><u>Key Points</u></Typography>
                <Typography variant="h6">
                  {employmentBullet1}
                  {' '}
                  {employmentBullet2}
                  {' '}
                  {employmentBullet3}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

        </Paper>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Paper
          variant="outlined"
          sx={{
            height: 'auto',
            width: '100%',
            minWidth: '500px',
            padding: '15px',
            backgroundColor: '#eee',
            marginRight: '2.5em',
            marginBottom: '2.5em',
          }}
        >
          <div className="sideBySideSpacedOut">
            <Typography variant="h6"><b>Wage Growth</b></Typography>
            {wagePositive ? <div className="squareGreen" /> : <div className="squareRed" />}
          </div>
          <Grid container spacing={2} sx={{ marginTop: '5px', height: '90%' }}>
            <Grid item xs={7}>
              <CustomLineChart
                seriesArray={[{
                  data: wageGrowth,
                  xAccessor: 'period_end',
                  yAccessor: (d) => d.avg_wkly_wage,
                  metric: 'avg_wkly_wage',
                }]}
                nice
              />
              {/* <ParentSize>

                {({ width, height }) => (
                  <HistoricalLineChart
                    width={width + 10}
                    height={height - 30}
                    seriesArray={[{ data: wageGrowth,
                      xaccessor: 'period_end',
                      yaccessor: 'avg_wkly_wage' }]}
                    metric="avg_wkly_wage"
                  />
                )}
              </ParentSize> */}
            </Grid>
            <Grid item xs={5}>
              <Paper sx={{
                height: '90%',
                padding: '15px',
                minHeight: '375px',
                backgroundColor: '#fff',
              }}
              >
                <Typography variant="h6"><u>Key Points</u></Typography>
                <Typography variant="h6">
                  {wageBullet1}
                  {' '}
                  {wageBullet2}
                  {' '}
                  {wageBullet3}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Paper
          variant="outlined"
          sx={{
            height: 'auto',
            width: '100%',
            minWidth: '500px',
            padding: '15px',
            backgroundColor: '#eee',
            marginRight: '2.5em',
            marginBottom: '2.5em',
          }}
        >
          <div className="sideBySideSpacedOut">
            <Typography variant="h6"><b>Supply Constraints</b></Typography>
            {supplyPositive ? <div className="squareGreen" /> : <div className="squareRed" />}
          </div>
          <Grid container spacing={2} sx={{ marginTop: '5px', height: '90%' }}>
            <Grid item xs={7}>
              <ParentSize>
                {({ width, height }) => {
                  if (width !== 0 && height !== 0) {
                    return (
                      <HistoricalBarChart
                        width={width + 10}
                        height={height - 30}
                        seriesArray={[{ data: buildingPermits, xaccessor: 'period_end', yaccessor: 'total_buildings' },
                          { data: populationIncrease, xaccessor: 'period_end', yaccessor: 'population_increase' }]}
                        metric="total_buildings"
                      />
                    );
                  }
                  return <div>Loading</div>;
                }}
              </ParentSize>
            </Grid>
            <Grid item xs={5}>
              <Paper sx={{
                height: '90%',
                padding: '15px',
                minHeight: '375px',
                backgroundColor: '#fff',
              }}
              >
                <Typography variant="h6"><u>Key Points</u></Typography>
                <Typography variant="h6">
                  {supplyBullet1}
                  {' '}
                  {supplyBullet2}
                  {' '}
                  {supplyBullet3}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
      <Box sx={{ marginTop: '2em' }}>
        <Tabs
          centered
          value={tabValue}
          onChange={handleTabChange}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Population Growth" />
          <Tab label="Employment Growth" />
          <Tab label="Wage Growth" />
          <Tab label="Supply Constraints" />
        </Tabs>
      </Box>
    </div>
  );
}

export default marketDrivers;
