/* eslint-disable max-len */
import React, { useEffect } from 'react';
import '../assets/landing.css';
// import '../assets/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Button, Typography, Grid, Paper,
} from '@mui/material';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
// import { flexbox } from '@mui/system';
import ToolBar from './toolBar';
import recImage from '../img/recImage.png';
import countrywideviewImage from '../img/countrywideviewImage.png';
import comparisonviewImage from '../img/comparisonviewImage.png';
import singlemarketviewImage from '../img/singlemarketviewImage.png';

function goToSignupPage() {
  window.location.href = '/signup';
}

function scrollToKeyFeatures() {
  document.getElementById('keyFeaturesSection').scrollIntoView(true);
}

export default function LandingPage() {
  useEffect(() => {
    // clear localStorage anytime they come on the landing page
    localStorage.clear();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });
    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));
  }, []);

  return (
    <div className="landingEntireBody">
      <ToolBar />
      <div className="landingMainSection">
        <div className="title">
          <strong>
            Sailvest
          </strong>
        </div>
        <div className="subtitle">
          Single Family Rental Analytics
          {/* Real Estate Market Data Analytics */}
          {/* <br />
          Data Analytics */}
        </div>
        <div className="sideBySide">

          <Button
            variant="contained"
            className="hideOnMobile"
            sx={{
              display: { xs: 'none', sm: 'block' },
              color: 'white',
              fontSize: '18px',
              marginTop: '2.5em',
              marginRight: '1em',
            }}
            onClick={() => scrollToKeyFeatures()}
          >
            Learn More
          </Button>
          <Button
            variant="contained"
            className="hideOnMobile"
            sx={{
              display: { xs: 'none', sm: 'block' },
              color: 'white',
              fontSize: '18px',
              marginTop: '2.5em',
            }}
            onClick={() => goToSignupPage()}
          >
            Get Started
          </Button>
        </div>

        <Paper sx={{
          display: { xs: 'block', sm: 'none' },
          fontSize: '18px',
          marginTop: '2.5em',
          padding: '0.5em',
          border: '2px solid black',
        }}
        >
          <i>Note: Sailvest available on desktop only.</i>
        </Paper>
      </div>
      <div className="explanationSection" id="keyFeaturesSection">
        {/* <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="paper" sx={{ borderRadius: '10px', backgroundColor: '#d1e7ff' }}>
              <Typography variant="h4" sx={{ marginBottom: '0.25em' }}><b>View Tailored Top Markets</b></Typography>
              <Typography variant="body">Once you sign up and fill out your investor profile, Sailvest highlights the top markets that match your criteria.</Typography>
            </Paper>

          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="paper" sx={{ borderRadius: '10px', backgroundColor: '#d1e7ff' }}>
              <Typography variant="h4" sx={{ marginBottom: '0.25em' }}><b>Analyze Market Drivers</b></Typography>
              <Typography variant="body">For each recommended market, Sailvest analyzes historical data and provides a summary of the main factors predicted to drive future market growth.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className="paper" sx={{ borderRadius: '10px', backgroundColor: '#d1e7ff' }}>
              <Typography variant="h4" sx={{ marginBottom: '0.25em' }}><b>Examine Historical Market Trends</b></Typography>
              <Typography variant="body">Sailvest synthesizes data from sources such as government agencies and realtor association reports. Using this data, you can examine markets you are interested in.</Typography>
            </Paper>
          </Grid>
        </Grid> */}
        <Typography variant="h3" sx={{ marginTop: '0.5em', marginBottom: '1.75em', textAlign: 'center' }}>Key Features</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <div className="sectionLeft hidden">
              <Typography variant="h4" sx={{ fontSize: 40, marginBottom: '0.4em' }}><i>View Tailored Top Markets.</i></Typography>
              <Typography sx={{ fontSize: 18 }}>Sailvest highlights the top markets that match your investment criteria. For each recommended market, Sailvest analyzes historical data and provides a summary of the main factors predicted to drive future market growth.</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}><img src={recImage} alt="Recommendations Page" className="recImage" /></Grid>
          <Grid item xs={12} sm={8}><img src={countrywideviewImage} alt="Countrywide View Page" className="recImage" /></Grid>
          <Grid item xs={12} sm={4}>
            <div className="sectionRight hidden">
              <Typography align="right" variant="h4" sx={{ fontSize: 40, marginBottom: '0.4em' }}><i>View Rankings for Counties Nationwide.</i></Typography>
              <Typography sx={{ fontSize: 18 }} align="right">Sailvest lets you automatically sort counties based on metrics like population estimates and median rents. This allows you to view the best investment opportunities across the nation in one intuitive view.</Typography>
            </div>

          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="sectionLeft hidden">
              <Typography variant="h4" sx={{ fontSize: 40, marginBottom: '0.4em' }}><i>Compare Counties at a Glance.</i></Typography>
              <Typography sx={{ fontSize: 18 }}>Sailvest allows you to easily view how counties differ for certain metrics in a simple, side by side format.</Typography>
            </div>

          </Grid>
          <Grid item xs={12} sm={8}><img src={comparisonviewImage} alt="Comparison View Page" className="recImage" /></Grid>
          <Grid item xs={12} sm={8}><img src={singlemarketviewImage} alt="Single Market View Page" className="recImage" /></Grid>
          <Grid item xs={12} sm={4}>
            <div className="sectionRight hidden">
              <Typography align="right" variant="h4" sx={{ fontSize: 40, marginBottom: '0.4em' }}><i>Examine Historical Market Trends.</i></Typography>
              <Typography sx={{ fontSize: 18 }} align="right">Sailvest enables you to examine individual markets you are interested in by synthesizing data from sources such as government agencies and realtor association reports.</Typography>
            </div>

          </Grid>
          {/* <Grid item xs={12} sm={8}>
            <Typography variant="h4" sx={{ marginBottom: '0.4em' }}>View Tailored Top Markets</Typography>
            <Typography variant="body">Sailvest highlights the top markets that match your investment criteria.</Typography>
            <Typography variant="h4" sx={{ marginBottom: '0.4em', marginTop: '1.5em' }}>Analyze Market Drivers</Typography>
            <Typography variant="body">For each recommended market, Sailvest analyzes historical data and provides a summary of the main factors predicted to drive future market growth.</Typography>
            <Typography variant="h4" sx={{ marginBottom: '0.4em', marginTop: '1.5em' }}>Examine Historical Market Trends</Typography>
            <Typography variant="body">Sailvest enables you to examine markets you are interested in by synthesizing data from sources such as government agencies and realtor association reports.</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              textAlign: 'center',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <QueryStatsIcon sx={{
              width: '10em', height: '10em',
            }}
            />
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}
