import React from 'react';
import '../assets/liquidity.css';
import { scaleLinear } from '@visx/scale';

function liquidityIndicator({ value }) {
  if (value === undefined || value === null) {
    return (<div>N/A</div>);
  }

  const colorScale = scaleLinear()
    .domain([0, 1]) // Define the input domain
    .range(['#ff6600', '#04c104']);

  let category = '';
  switch (true) {
    case (value >= 0.66):
      category = 'High';
      break;
    case (value >= 0.33):
      category = 'Moderate';
      break;
    default:
      category = 'Low';
      break;
  }
  return (
    <div className="liquidityContainer" style={{ '--numDegrees': `${((value * 180) - 90)}deg`, '--finalColor': colorScale(value) }}>
      <div className="liquidityGauge">
        <div className="liquidityProgress">
          <div className="liquidityBar" />
          <div className="needle" />
        </div>
        <div className="liquidityCategory">
          {category.toUpperCase()}
        </div>
      </div>
    </div>
  );
}

export default liquidityIndicator;
