/* eslint-disable import/no-cycle */
import React, { useState, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import {
  Collapse, IconButton, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import Collapse from '@mui/material/Collapse';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import ToolBar from './toolBar';
import '../assets/login.css';
import { axiosFindUsersWithGivenEmail, axiosSendResetCodeEmail } from '../modules/api';
import { RecoveryContext } from './loginPage';

export default function Recover() {
  const {
    email, setEmail, setPage, setOTP,
  } = useContext(RecoveryContext);

  const paperStyle = {
    padding: '25px',
    width: '20%',
    minWidth: '350px',
    borderRadius: '10px',
    margin: '20px auto',
    justifyContent: 'center',
    flex: 'column',
  };

  const [errorShow, setErrorShow] = useState(false);

  const handleEmailEnter = () => {
    // console.log(email);
    // Check if email in our Users database
    axiosFindUsersWithGivenEmail(email).then((result) => {
      // console.log('In Database?');
      if (result.data.length === 0) {
        setErrorShow(true);
      } else {
        setErrorShow(false);
        // console.log('Yes');
        // Send email with OTP
        const OTP = Math.floor(Math.random() * 900000 + 100000);
        // console.log('OTP');
        // console.log(OTP);
        setOTP(OTP);

        axiosSendResetCodeEmail(email, 'Sailvest Verification Code', OTP).then(() => {
          // Switch to OTP page
          setPage('otp');
        }).catch((err) => {
          console.log('Sending Mail Error');
          console.log(err);
        });
      }
    });
  };

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { marginTop: '25px', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Collapse in={errorShow}>
        <Alert
          severity="error"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorShow(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ position: 'relative', top: 0 }}
        >
          There is no user with this email. Please register on the Sign Up page.
        </Alert>
      </Collapse>
      <Paper elevation={10} style={paperStyle}>
        <div style={{ display: 'flex', justifyContent: 'center ' }}>
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
        </div>
        <h2 style={{ marginBottom: '35px' }}>Recover Password</h2>
        <TextField
          label="Email"
          placeholder="Enter email"
          style={textFieldStyle}
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={handleEmailEnter}
        >
          Submit
        </Button>
      </Paper>
    </div>
  );
}
