import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
// import { useParams } from 'react-router-dom';
import {
  Button, styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import '../assets/main.css';
import '../assets/operations.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import ToolBar from './toolBar';
import AdvancedSideBar from './advancedSideBar';
import '../assets/properties.css';
import PropertySummary from './propertySummary';
import PropertyCards from './propertyCards';
import PropertyAnalysis from './propertyAnalysis';
import { PropertyContext } from './PropertyContext';
import { axiosGetCity } from '../modules/api';

// Styling the tabs within the Single Market View (snapshot, fundamentals, etc)
const MyTab = styled(Button)(() => ({
  color: '#555454',
  paddingRight: '15px',
  paddingLeft: '15px',
  borderRadius: '10px',
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#ededed',
    color: '#000000',
  },
}));

const selectedTabStyling = {
  color: 'white',
  '&:hover': {
    backgroundColor: '#144a77',
    color: 'white',
  },
};

export default function PropertyDetailsPage() {
  const [tab, setTab] = useState('Summary');
  const [exactProperty, setExactProperty] = useState('Default');
  const { properties } = useContext(PropertyContext);
  const { propertyID } = useParams();

  useEffect(() => {
    if (properties && propertyID) {
      const propertyWithoutRegionID = properties.filter(
        (property) => property._id === propertyID,
      )[0];
      let propertyWithRegionID;

      // console.log(propertyWithoutRegionID);

      if (propertyWithoutRegionID) {
        axiosGetCity(propertyWithoutRegionID.city_id).then((cityInfo) => {
          // console.log(cityInfo.data[0]);
          const regionID = (cityInfo.data[0]).region_id;
          propertyWithRegionID = { ...propertyWithoutRegionID };
          propertyWithRegionID.region_id = regionID;
          // console.log(propertyWithRegionID);
          setExactProperty(propertyWithRegionID);
        });
      } else {
        setExactProperty(null);
      }
    }
  }, [properties, propertyID]);

  // Shows different tabs within Single Market View
  const TabPanel = useCallback(() => {
    switch (tab) {
      case 'Details':
        return <PropertySummary specificProperty={exactProperty} />;
      case 'Analysis':
        return <PropertyAnalysis specificProperty={exactProperty} />;
      case 'Comps':
        return <PropertyCards />;
      default:
        return <PropertySummary specificProperty={exactProperty} />;
    }
  }, [tab, exactProperty]);

  return (
    <div style={{ flex: 'column' }}>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Properties" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody">
            <strong>Property Details</strong>
          </Typography>
          <div className="mainBody">
            <div className="sideBySide smvTabs" style={{ marginTop: '0em' }}>
              <MyTab
                variant={(tab === 'Summary') ? 'contained' : 'text'}
                sx={(tab === 'Summary') ? selectedTabStyling : {}}
                value="Summary"
                onClick={(e) => setTab(e.target.value)}
              >
                Summary
              </MyTab>
              <div className="vl" />
              <MyTab
                variant={(tab === 'Analysis') ? 'contained' : 'text'}
                sx={(tab === 'Analysis') ? selectedTabStyling : {}}
                value="Analysis"
                onClick={(e) => setTab(e.target.value)}
              >
                Analysis
              </MyTab>
              {/* <div className="vl" />
              <MyTab
                variant={(tab === 'Comps') ? 'contained' : 'text'}
                sx={(tab === 'Comps') ? selectedTabStyling : {}}
                value="Comps"
                onClick={(e) => setTab(e.target.value)}
              >
                Comps

              </MyTab> */}
            </div>
            <TabPanel sx={{ marginTop: '1em' }} />
          </div>
        </div>
      </div>
    </div>
  );
}
