/* eslint-disable react-hooks/rules-of-hooks */
import React, { } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/main.css';
import '../assets/operations.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Typography,
} from '@mui/material';
import ToolBar from './toolBar';
import SingleMarketView from './singlemarketView';
import AdvancedSideBar from './advancedSideBar';

export default function explorePage() {
  const { regionID } = useParams();

  return (
    <div>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Explore" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody"><strong>Market Explorer</strong></Typography>
          <SingleMarketView defaultRegionID={regionID} />
        </div>
      </div>
    </div>
  );
}
