import React from 'react';
import {
  Box, Collapse, Alert, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function MyAlert({
  show, setShow, message, severity, marginTop,
}) {
  return (
    <Box sx={{ width: '100%', marginTop, marginBottom: '15px' }}>
      <Collapse in={show}>
        <Alert
          severity={severity}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShow(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          // sx={{ mb: 2, backgroundColor: '#8d3030', color: 'white' }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}

export default MyAlert;
