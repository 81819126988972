const { axiosSetMostRecentLogin } = require('./api');

function logIn(username, userId, userEmail) {
  localStorage.setItem('currUser', username);
  localStorage.setItem('currUserId', userId);
  localStorage.setItem('userEmail', userEmail);
  axiosSetMostRecentLogin(userId);
}

function logOut() {
  localStorage.removeItem('currUserId');
  localStorage.removeItem('currUser');
  localStorage.removeItem('userEmail');
}

module.exports = {
  logOut,
  logIn,
};
