/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useContext } from 'react';
import {
  CircularProgress, Paper, TableContainer, Table, TableHead,
  TableRow, TableBody, TableCell, styled, tableCellClasses, Grid,
  Typography, Box,
} from '@mui/material';
import { allMetrics, formatMetricValue } from '../utils/allMetrics';
import { getRegionRecordValue, generateInterpretation } from '../utils/explorePageFunctions';
import MetricGrowthCard from './metricGrowthCard';
import MetricComparisonCard from './metricComparisonCard';
import SMVTitleCard from './smvTitleCard';
import { ResearchContext } from './ResearchContext';

// Styling table cells
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eee',
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

export default function MarketFundamentals({ regionRecord }) {
  // State variables
  const [metric, setMetric] = useState('population_estimate');

  const { regions } = useContext(ResearchContext);
  const regionName = (regionRecord !== undefined && regionRecord !== '' && regions !== undefined && regions.length !== 0) ? (regions.filter((region) => region._id === regionRecord.region_id))[0].region_name : '...';

  // Historical Data (gets set by child component)
  const [historicaldata, setHistoricalData] = useState();

  // Gets current snapshot metrics from allMetrics array for table
  const marketFundamentalsMetrics = Object.keys(allMetrics).filter((key) => (allMetrics[key].category === 'Market Fundamentals'));

  // Determines whether to show the comparison card
  const showComparison = useCallback(() => allMetrics[metric].comparison !== 'None', [metric]);
  const showGraph = useCallback(() => allMetrics[metric].graph !== 'No', [metric]);

  // Show loading symbol when data still being retrieved
  if (regionRecord === undefined || regionRecord === '') {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SMVTitleCard regionRecord={regionRecord} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Paper
          variant="outlined"
          sx={{ borderRadius: '10px', padding: '0.6em' }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell><b>Metric</b></StyledTableCell>
                  <StyledTableCell align="right">
                    <b>Value</b>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {marketFundamentalsMetrics.map((row) => (
                  <TableRow
                    key={row}
                    onClick={() => setMetric(row)}
                    sx={{
                      '&:hover': { backgroundColor: '#f2f2f2' }, backgroundColor: `${(metric === row) ? '#eee' : 'transparent'}`, transitionDuration: '50ms', height: '75px',
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {(row !== undefined) ? allMetrics[row].display_name : ''}
                    </StyledTableCell>
                    <StyledTableCell align="right">{formatMetricValue(getRegionRecordValue(regionRecord, row), row)}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {!showGraph(metric) ? null : (
        <Grid item xs={12} sm={7}>
          <MetricGrowthCard
            metric={metric}
            regionRecord={regionRecord}
            setHistoricalData={setHistoricalData}
          />
        </Grid>
      )}
      {!showComparison(metric) ? null : (
        <Grid item xs={12} sm={6}>
          <MetricComparisonCard metric={metric} regionRecord={regionRecord} />
        </Grid>
      )}
      <Grid item xs={12} sm={showComparison(metric) ? 6 : 7}>
        <Paper
          variant="outlined"
          style={{
            borderRadius: '10px', padding: '1em', height: '100%',
          }}
        >
          <Typography>
            <b>
              {(metric !== undefined) ? allMetrics[metric].display_name : ''}
              {': '}
              Key Points
              {' '}
            </b>

          </Typography>
          <Typography sx={{ marginTop: '1em', whiteSpace: 'pre-wrap' }}>
            {generateInterpretation(regionName, metric, historicaldata)}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
