/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Grid, BarGroup, BarSeries, XYChart, Axis, Tooltip,
} from '@visx/xychart';

// import { scaleLinear } from '@visx/scale';

import moment from 'moment';

import allMetricNames from '../utils/allMetrics';
/* If I want to set specific domain for specific axis,
you add a 'domain' key to the yScale object (ex. domain: [-5000,5000])
*/
const chartConfig = {
  xScale: { type: 'band' },
  yScale: { type: 'linear', zero: false, nice: true },
  margin: {
    top: 20, right: 50, bottom: 50, left: 100,
  },
};

// const tickLabelProps = {
//   angle: 45,
// };

// function getMinAndMax(array) {
//   return ({
//     min: Math.min(...array),
//     max: Math.max(...array),
//   });
// }

export default function HistoricalBarChart({
  width, height, seriesArray, metric,
}) {
  // Since we don't include this metric for other pages, I have to set it manually here
  const allMetricNamesCopy = JSON.parse(JSON.stringify(allMetricNames));
  // allMetricNamesCopy.median_value = 'Median Value';
  // allMetricNamesCopy.population_estimate = 'Population Estimate';
  // allMetricNamesCopy.month3_emplvl = 'Month 3 Employment Level';
  // allMetricNamesCopy.avg_wkly_wage = 'Average Weekly Wage';
  // allMetricNamesCopy.total_buildings = 'New Building Permits';
  allMetricNamesCopy.population_increase = 'Change in Population';
  // allMetricNamesCopy.average_value = 'National Average';

  if (seriesArray.map((series) => series.data).includes(undefined)) {
    return <div key={Math.random()}> Loading</div>;
  }

  /* const minY = Math.min(seriesArray.map(
    (series) => getMinAndMax(series.data.map((entry) => entry[series.yaccessor])).min,
  ));

  const maxY = Math.max(seriesArray.map(
    (series) => getMinAndMax(series.data.map((entry) => entry[series.yaccessor])).max,
  ));

  const yScale = scaleLinear({
    range: [minY, maxY],
    nice: true,
  }); */

  return (
    <XYChart {...chartConfig} width={width} height={height}>
      <Grid numTicks={10} />
      <BarGroup>
        {seriesArray.map((series) => (
          <BarSeries
            key={Math.random()}
            dataKey={series.yaccessor}
            data={series.data}
              // xAccessor={(d) => (d).period_end.toDateString()}
            xAccessor={(d) => moment(d[series.xaccessor]).format('MM-DD-YYYY')}
            yAccessor={(d) => d[series.yaccessor]}
          />
        ))}
      </BarGroup>
      <Axis
        orientation="left"
        // tickLabelProps={tickLabelProps}
        numTicks={10}
        labelOffset={50}
        label={allMetricNamesCopy[metric]}
      />
      <Axis
        orientation="bottom"
        // tickLabelProps={tickLabelProps}
        // labelProps={{ padding: 50 }}
        labelOffset={15}
        numTicks={5}
        tickFormat={(v) => v}
        label="Time"
      />
      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        /* eslint-disable */
        renderTooltip={({ tooltipData }) => {
          return (
            <div>
              {moment(tooltipData.nearestDatum.datum.period_end).format('MM-DD-YYYY')}
              <br />
              {seriesArray.map((series) => {
                return (
                  <div key={Math.random()}>
                    {allMetricNamesCopy[series.yaccessor]}
                    :
                    {' '}
                    {(tooltipData.datumByKey[series.yaccessor].datum[series.yaccessor] === null) ? 'NA'
                      : Math.round(
                        tooltipData.datumByKey[series.yaccessor].datum[series.yaccessor],
                      )}
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </XYChart>
  );
}
