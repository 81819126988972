import React, {
  createContext, useEffect, useState, useMemo,
} from 'react';
import {
  axiosGetAllRegions,
} from '../modules/api';

// Create Property Context for all child elements of provider to access the value of the context
export const ResearchContext = createContext();

export default function ResearchContextProvider({ children }) {
  // Keep track of all region names
  const [regions, setRegions] = useState([]);

  // Load user's properties when component renders
  useEffect(() => {
    axiosGetAllRegions().then((val) => {
      // console.log('We; got here for sure');
      // console.log(val);
      setRegions(val.data);
    }).catch((e) => console.log(e));
  }, []);

  // useEffect(() => {
  //   console.log('These are the most recent retrieved regions:');
  //   console.log(regions);
  // }, [regions]);

  const memoValue = useMemo(() => ({ regions }), [regions]);

  return (
    <ResearchContext.Provider value={memoValue}>
      {children}
    </ResearchContext.Provider>
  );
}
