const moment = require('moment');

// Gets value from region record and handles null values
export function getRegionRecordValue(regionRecord, value) {
  if (regionRecord === '' || regionRecord === undefined) {
    return '...';
  }

  switch (value) {
    case 'price_to_rent_ratio':
      if (regionRecord.median_sales_price !== undefined && regionRecord.median_rent !== undefined) {
        return (regionRecord.median_sales_price / (12 * regionRecord.median_rent)).toFixed(2);
      }
      return 'Unavailable';
    default:
      if (regionRecord[value] === undefined) {
        return 'Unavailable';
      }
      return regionRecord[value];
  }
}

// Returns subset of data within timeline
export function generateCorrectTimelineData(someData, timeline) {
  if (someData.length === 0) {
    return [];
  }

  const presentDate = moment();

  if (timeline !== 'max') {
    return someData.filter((record) => (presentDate.diff(moment(record.period_end), 'years', true) <= timeline));
  }

  return someData;
}

// Returns the interpretation text for a given metric and region
/* eslint-disable */
export function generateInterpretation(county, metric, historicalData) {
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
  let text = "N/A";

  try{
    if(historicalData != undefined) {
      if (metric === 'median_sales_price') {
        const latestDateObj = historicalData[historicalData.length - 1];
        const latestDate = moment(latestDateObj["period_end"]);
        const month = months[latestDate.month()];
        
        const firstDateObj = historicalData.filter((record) => (latestDate.diff(moment(record.period_end), 'years', true) <= 1))[0];
    
        const growth = (((latestDateObj[metric] / firstDateObj[metric]) - 1) * 100).toFixed(2);
    
        text =  'Median sales prices are seasonal, typically peaking during the summer months. This is because demand is generally higher during this time. ' 
        + 'Many homebuyers prefer to buy a new house in the summer as it is a convenient time for their children to move to new school districts.'
        + '\n' + '\n'
        + "The median sales price in " + county + " changed by " + growth + "%" + " compared to " + month + " of last year.";
      } else if (metric === 'median_rent'){
        const latestDateObj = historicalData[historicalData.length - 1];
        const latestDate = moment(latestDateObj["period_end"]);
        
        const yearDiff = 2;
        const firstDateObj = historicalData.filter((record) => (latestDate.diff(moment(record.period_end), 'years', true) <= yearDiff))[0];
    
        const growth = (((latestDateObj[metric] / firstDateObj[metric]) - 1) * 100).toFixed(2);
    
        text =  "The median rent in " + county + " changed by " + growth + "%" + " compared to " + yearDiff + " years ago.";
      } else if (metric === 'price_to_rent_ratio'){
        text =  "Price to rent ratio is the ratio of home prices to annualized rent in a given location. \n \n"
        + "Real estate investors looking to rent our their property generally prefer markets with high price-to-rent ratios. "
        + "This is because many people living in high price-to-rent locations typically cannot afford to buy a home, so must rent instead. "
        + "This indicates that there will be strong rental demand in the future."
      } else if (metric === 'rental_vacancy_rate'){
        text =  "Rental vacancy rate is the proportion of homes for rent that are vacant for rent. \n \n"
        + "Areas with low vacancy rates generally have high rental demand as properties are rented out quickly."
      } else if (metric === 'percent_owner_occupied' || metric === 'percent_renter_occupied') {
        text =  "Typically high renter-occupied regions indicate potential for renting out a property as there is likely high rental demand. \n \n"
        + "High owner-occupied regions may be more suitable for a fix and flip investment."
      } else if (metric === 'fed_funds_rate') {
        text =  "In general when interest rates are higher or increasing, the housing market slows down. " +
        "This is because the cost of owning a home becomes more expensive due to higher interest payments on mortgages. " +
        "Thus, demand for homes is reduced, which typically leads to a drop in home prices. Conversely, when interest rates drop, " +
        "demand for owning a home increases, which can lift home prices."
      } else if (metric === 'population_estimate') {
        text =  "High population growth leads to a growing demand for housing in a given area. This typically leads to high " +
        "housing price appreciation as demand for housing increases. "
      } else if (metric === 'month3_emplvl') {
        text =  "High employment growth has historically been correlated with growing demand for housing in a given area. This typically leads to high " +
        "housing price appreciation as demand for housing increases. "
      } else if (metric === 'avg_wkly_wage') {
        text =  "High wage growth has historically been correlated with growing demand for housing in a given area. This typically leads to high " +
        "housing price appreciation as demand for housing increases. "
      } else if (metric === 'total_buildings') {
        text =  "The number of building permits issued in a county is an indicator of future supply of housing in that county. "
        + " A low amount of permits issued compared to population growth could indicate a potential supply constraint in the future "
        + "which would potentially lift housing prices while a high amount would potentially lower housing prices."
      } else if (metric === 'wrluri_level') {
        text =  "The Wharton Residential Land Use Index is a metric developed by The Wharton School at UPenn that provides "
        + "an estimate for how strong the local regulatory environment is for new development. \n \nThe metric is categorized as "
        + "light, moderate, and heavy. A heavy value indicates that it is difficult to build new construction, while a light value "
        + "indicates that it is relatively easier to build new construction in an area. This is an indicator of whether supply is "
        + "likely to increase in the future. A supply constraint could help lift housing prices while a supply surplus would "
        + "likely decrease housing prices. \n \n"
        + "Citation: \nGyourko, J., Hartley, J., & Krimmel, J. (2019). The Local Residential Land Use Regulatory Environment Across U.S. Housing Markets: "
        + "Evidence from a New Wharton Index. (No. w26573).  National Bureau of Economic Research.";
      } 
    }

    if (metric === 'liquidity') {
      text =  "Closed sales is the number of homes sold in a given area. \n \n" 
      + "Days on market is a measure of how long it takes to sell a home in a given area. \n \n"
      + "These are both measures of liquidity. Sailvest's liquidity indicator takes into account these metrics "
      + "in order to provide a summary of how liquid a market is. The metric considers the national "
      + "average for the days on market statistic in order to categorize how liquid the real estate market in a county is. \n \n"
      + "Real estate investors typically like to invest in liquid real estate markets so that they can feel confident "
      + "that they are able to buy and sell their properties quickly.";
    }
  } catch {
    // console.log("error w interpretation");
  }
  return text;
}