/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useCallback, useState, useEffect,
} from 'react';
import {
  Paper, Typography, Box, CircularProgress, Alert,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatMetricValue } from '../utils/allMetrics';
import { axiosFindHistoricalRegionData } from '../modules/api';

const { irr } = require('node-irr');
const moment = require('moment');

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const useStyles = makeStyles({
  hidePagination: {
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
});

function getTooltipText(lineItem) {
  switch (lineItem) {
    case 'Gross Rent':
      return (
        <em>
          Estimated based on rental comps.
          Grows based on median rent growth of county over past 10 years.
        </em>
      );
    case 'Vacancy':
      return (<em>Assumed to be 5%.</em>);
    case 'Operating Income':
      return (<em>Calculated based on gross potential rent and vacancy.</em>);
    case 'Property Tax':
      return (<em>Estimated based on online sources. Grows by property appreciation rate.</em>);
    case 'Insurance':
      return (<em>Estimated based on online sources. Grows by property appreciation rate.</em>);
    case 'Utilities':
      return (<em>Typically paid by tenant.</em>);
    case 'Repairs/Maintenance':
      return (<em>Assumed to be 5% of gross rent.</em>);
    case 'Property Management':
      return (<em>Assumed to be 8% of gross rent.</em>);
    case 'HOA':
      return (<em>Estimated based on online sources.</em>);
    case 'Other Expenses':
      return (<em>Covers miscellaneous expenses.</em>);
    case 'Operating Expenses':
      return (<em>Sum of expenses above.</em>);
    case 'Net Operating Income':
      return (<em>Operating income less operating expenses.</em>);
    case 'Mortgage Payments':
      return (
        <em>
          Assuming 25% down payment for a 30-year fixed loan
          with an annual interest rate of 7.21%.
        </em>
      );
    case 'Cash Flow':
      return (<em>Net operating income less mortgage payments.</em>);
    case 'Mortgage Interest':
      return (<em>Calculated assuming complete amortization schedule.</em>);
    case 'Depreciation':
      return (<em>Assumed to be constant over 22.5 years.</em>);
    case 'Annual Deductions':
      return (<em>Sum of operating expenses, interest payments, and depreciation.</em>);
    case 'Property Value':
      return (
        <em>
          Estimated based on comps.
          Grows by median sales price appreciation of county over past 10 years.
        </em>
      );
    case 'Mortgage Balance':
      return (<em>Calculated assuming complete amortization schedule.</em>);
    case 'Total Equity':
      return (<em>Property value less mortgage balance.</em>);
    case 'Cumulative Cash Flow':
      return (<em>Sum of cash flows over time.</em>);
    case 'Total Cash Invested':
      return (<em>Assumed to be down payment (25% of purchase price).</em>);
    case 'Total Profit':
      return (<em>Equity plus cumulative cash flow minus total cash invested.</em>);
    case 'Cap Rate':
      return (<em>Net operating income divided by purchase price.</em>);
    case 'Cash on Cash Return':
      return (<em>Cash flow divided by down payment.</em>);
    case 'IRR':
      return (<em>Discount rate of cash flows which results in a Net Present Value of 0.</em>);
    case 'Return on Investment':
      return (<em>Profit divided by down payment.</em>);
    default:
      return <div />;
  }
}

const incomeColumns = [
  {
    field: 'lineItem',
    headerName: '',
    sortable: false,
    minWidth: 250,
    flex: 0.2,
    renderCell: (params) => (
      <div className="centeredRow">
        <div>{params.row.lineItem}</div>
        <CustomTooltip
          title={(
            <>
              <Typography color="inherit">{params.row.lineItem}</Typography>
              {getTooltipText(params.row.lineItem)}
            </>
        )}
          placement="right"
        >
          {/* <IconButton > */}
          <InfoOutlinedIcon sx={{
            marginLeft: '5px',
            width: '15px',
            height: '15px',
            transform: 'translateY(0.5px)',
            '&:hover': {
              color: '#377bba',
            },
          }}
          />
          {/* </IconButton> */}
        </CustomTooltip>

      </div>
    ),
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year1',
    headerName: 'Year 1',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year2',
    headerName: 'Year 2',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year3',
    headerName: 'Year 3',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year4',
    headerName: 'Year 4',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year5',
    headerName: 'Year 5',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'year10',
    headerName: 'Year 10',
    sortable: false,
    minWidth: 125,
    flex: 0.2,
    headerClassName: 'super-app-theme--header',
  },
];

function calculateAnnualGeneral(base, growth) {
  const yearValuesObject = {};
  for (let i = 1; i <= 10; i++) {
    yearValuesObject[`year${i}`] = base * (growth ** (i - 1));
  }
  return yearValuesObject;
}

function formatTableValues(yearValueObject, type) {
  const formattedObject = {};
  Object.keys(yearValueObject).forEach((key) => {
    if (yearValueObject[key] < 0) {
      formattedObject[key] = `-${formatMetricValue(Math.abs(yearValueObject[key].toFixed(2)), type)}`;
    } else {
      formattedObject[key] = formatMetricValue(yearValueObject[key].toFixed(2), type);
    }
  });
  return formattedObject;
}

function PropertyAnalysis({ specificProperty }) {
  const classes = useStyles();
  const [historicalMarketAppreciationData, setHistoricalMarketAppreciationData] = useState(null);
  const [historicalRentGrowthData, setHistoricalRentGrowthData] = useState(null);

  if (!specificProperty) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <Alert severity="error">Property Not Found</Alert>
      </Box>
    );
  }

  useEffect(() => {
    // console.log(historicalMarketAppreciationData);
    // console.log(historicalRentGrowthData);
  }, [historicalMarketAppreciationData, historicalRentGrowthData]);

  // Load in historical rent and appreciation
  useEffect(() => {
    // console.log('historical data loading');
    if (specificProperty.region_id) {
      const source = axios.CancelToken.source();
      // console.log('did this run');

      axiosFindHistoricalRegionData(specificProperty.region_id, 'median_sales_price', source).then(
        (result) => {
          setHistoricalMarketAppreciationData(result.data);
        },
      );
      axiosFindHistoricalRegionData(specificProperty.region_id, 'median_rent', source).then(
        (result) => {
          setHistoricalRentGrowthData(result.data);
        },
      );
    }
  }, [specificProperty]);

  // Calculate growth percentage of metric across timeline
  const calculateCAGR = useCallback((dataset, metric) => {
    const presentDate = moment();
    const timeline = 10;
    const annualInflationRate = 1.03;

    let subset = dataset;
    subset = dataset.filter((record) => (presentDate.diff(moment(record.period_end), 'years', true) <= timeline));
    // console.log(subset);
    // Assuming growth with inflation if there's no historical data
    if (subset === undefined
            || subset === null
            || subset.length === 0) {
      return annualInflationRate;
    }

    const lastVal = subset[subset.length - 1];
    const firstVal = subset[0];

    if (lastVal[metric] === undefined || firstVal[metric] === undefined) {
      return annualInflationRate;
    }

    return (lastVal[metric] / firstVal[metric]) ** (1 / (moment(lastVal.period_end).diff(moment(firstVal.period_end), 'years', true)));
  }, []);

  if (!historicalMarketAppreciationData || !historicalRentGrowthData) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Income assumptions
  const annualRentGrowth = calculateCAGR(historicalRentGrowthData, 'median_rent');
  const annualAppreciation = calculateCAGR(historicalMarketAppreciationData, 'median_sales_price');
  const vacancy = 5;
  const vacancies = calculateAnnualGeneral(vacancy, 1);

  const annualRents = calculateAnnualGeneral(
    specificProperty.rent_zestimate * 12,
    annualRentGrowth,
  );

  // Set operating income values
  const totalOperatingIncome = {};
  for (let i = 1; i <= 10; i++) {
    totalOperatingIncome[`year${i}`] = annualRents[`year${i}`] * (1 - vacancies[`year${i}`] / 100);
  }

  const incomeRows = [
    {
      id: 1, lineItem: 'Gross Rent', ...formatTableValues(annualRents, 'dollar'),
    },
    {
      id: 2, lineItem: 'Vacancy', ...formatTableValues(vacancies, 'rental_vacancy_rate'),
    },
    {
      id: 3, lineItem: 'Operating Income', ...formatTableValues(totalOperatingIncome, 'dollar'),
    },

  ];

  const taxAppreciationRate = annualAppreciation;
  const insuranceAppreciationRate = annualAppreciation;
  const HOAAppreciationRate = 1;

  const annualPropertyTaxes = calculateAnnualGeneral(
    specificProperty.listing_price * (specificProperty.tax_rate_estimate / 100),
    taxAppreciationRate,
  );
  const annualInsuranceCosts = calculateAnnualGeneral(
    specificProperty.annual_insurance_estimate,
    insuranceAppreciationRate,
  );

  const annualUtilityCosts = calculateAnnualGeneral(
    0,
    0,
  );

  const repairPercentage = 0.05;
  const annualRepairCosts = calculateAnnualGeneral(
    specificProperty.rent_zestimate * 12 * repairPercentage,
    annualRentGrowth,
  );

  const propertyManagementPercentage = 0.08;
  const annualPropertyManagementCosts = calculateAnnualGeneral(
    specificProperty.rent_zestimate * 12 * propertyManagementPercentage,
    annualRentGrowth,
  );

  let baseHOA;
  if (Number.isNaN(specificProperty.monthly_hoa_estimate)) {
    baseHOA = 0;
  } else {
    baseHOA = specificProperty.monthly_hoa_estimate * 12;
  }
  const annualHOAFees = calculateAnnualGeneral(
    baseHOA,
    HOAAppreciationRate,
  );

  const otherExpenses = calculateAnnualGeneral(
    0,
    0,
  );

  const totalOperatingExpenses = {};
  for (let i = 1; i <= 10; i++) {
    totalOperatingExpenses[`year${i}`] = annualPropertyTaxes[`year${i}`]
    + annualInsuranceCosts[`year${i}`]
    + annualUtilityCosts[`year${i}`]
    + annualRepairCosts[`year${i}`]
    + annualPropertyManagementCosts[`year${i}`]
    + annualHOAFees[`year${i}`]
    + otherExpenses[`year${i}`];
  }

  const expensesRows = [
    {
      id: 1, lineItem: 'Property Tax', ...formatTableValues(annualPropertyTaxes, 'dollar'),
    },
    {
      id: 2, lineItem: 'Insurance', ...formatTableValues(annualInsuranceCosts, 'dollar'),
    },
    {
      id: 3, lineItem: 'Utilities', ...formatTableValues(annualUtilityCosts, 'dollar'),
    },
    {
      id: 4, lineItem: 'Repairs/Maintenance', ...formatTableValues(annualRepairCosts, 'dollar'),
    },
    {
      id: 5, lineItem: 'Property Management', ...formatTableValues(annualPropertyManagementCosts, 'dollar'),
    },
    {
      id: 6, lineItem: 'HOA', ...formatTableValues(annualHOAFees, 'dollar'),
    },
    {
      id: 7, lineItem: 'Other Expenses', ...formatTableValues(otherExpenses, 'dollar'),
    },
    {
      id: 8,
      lineItem: 'Operating Expenses',
      ...formatTableValues(totalOperatingExpenses, 'dollar'),

    },
  ];

  // Set net operating income values
  const netOperatingIncome = {};
  for (let i = 1; i <= 10; i++) {
    netOperatingIncome[`year${i}`] = totalOperatingIncome[`year${i}`] - totalOperatingExpenses[`year${i}`];
  }

  // 30 year fixed loan
  // Assuming complete amortization for mortgage payments later, assuming 25% down
  const averageAnnualInterestRate = 0.0721;
  const monthlyInterestRate = averageAnnualInterestRate / 12;
  const numPayments = 360;
  const downPayment = specificProperty.listing_price * 0.25;
  const principal = specificProperty.listing_price * (1 - 0.25);

  const totalMonthlyMortgage = (principal * monthlyInterestRate
  * ((1 + monthlyInterestRate) ** numPayments))
  / ((1 + monthlyInterestRate) ** numPayments - 1);

  const mortgagePayments = calculateAnnualGeneral(
    totalMonthlyMortgage * 12,
    1,
  );

  // Set cash flow values
  const beforeTaxLeveredCashFlow = {};
  for (let i = 1; i <= 10; i++) {
    beforeTaxLeveredCashFlow[`year${i}`] = netOperatingIncome[`year${i}`] - mortgagePayments[`year${i}`];
  }

  const cashflowRows = [
    {
      id: 1, lineItem: 'Operating Income', ...formatTableValues(totalOperatingIncome, 'dollar'),
    },
    {
      id: 2, lineItem: 'Operating Expenses', ...formatTableValues(totalOperatingExpenses, 'dollar'),
    },
    {
      id: 3, lineItem: 'Net Operating Income', ...formatTableValues(netOperatingIncome, 'dollar'),
    },
    {
      id: 4, lineItem: 'Mortgage Payments', ...formatTableValues(mortgagePayments, 'dollar'),
    },
    {
      id: 5, lineItem: 'Cash Flow', ...formatTableValues(beforeTaxLeveredCashFlow, 'dollar'),
    },
  ];

  const monthlyInterestPayments = [];
  const outstandingLoanBalanceArray = [];
  let outstandingLoanBalance = principal;

  // eslint-disable-next-line no-plusplus
  for (let month = 1; month <= numPayments; month++) {
    const interestPayment = outstandingLoanBalance * monthlyInterestRate;
    monthlyInterestPayments.push(interestPayment);
    outstandingLoanBalance -= (totalMonthlyMortgage - interestPayment);
    outstandingLoanBalanceArray.push(outstandingLoanBalance);
  }

  const annualInterestPayments = [];
  const annualMortgageBalance = [];

  // assuming 30 year fixed loan
  // eslint-disable-next-line no-plusplus
  for (let year = 1; year <= 30; year++) {
    let annualInterest = 0;
    monthlyInterestPayments.slice(year * 12, year * 12 + 12).forEach((monthPayment) => {
      annualInterest += monthPayment;
    });
    annualMortgageBalance.push(outstandingLoanBalanceArray[year * 12 - 1]);
    annualInterestPayments.push(annualInterest);
  }

  // Set annual interest payments
  const annualInterestPaymentsObject = {};
  for (let i = 1; i <= 10; i++) {
    annualInterestPaymentsObject[`year${i}`] = annualInterestPayments[i - 1];
  }

  const depreciationTerm = 22.5;
  const depreciation = calculateAnnualGeneral(specificProperty.listing_price / depreciationTerm, 1);

  // Set annual tax deductions
  const annualDeductions = {};
  for (let i = 1; i <= 10; i++) {
    annualDeductions[`year${i}`] = totalOperatingExpenses[`year${i}`] + annualInterestPaymentsObject[`year${i}`] + depreciation[`year${i}`];
  }

  const taxRows = [
    {
      id: 1, lineItem: 'Operating Expenses', ...formatTableValues(totalOperatingExpenses, 'dollar'),
    },
    {
      id: 2, lineItem: 'Mortgage Interest', ...formatTableValues(annualInterestPaymentsObject, 'dollar'),
    },
    {
      id: 3, lineItem: 'Depreciation', ...formatTableValues(depreciation, 'dollar'),
    },
    {
      id: 4, lineItem: 'Annual Deductions', ...formatTableValues(annualDeductions, 'dollar'),
    },

  ];

  // Set annual mortgage balance
  const annualMortgageBalanceObject = {};
  for (let i = 1; i <= 10; i++) {
    annualMortgageBalanceObject[`year${i}`] = annualMortgageBalance[i - 1];
  }

  const propertyValue = calculateAnnualGeneral(
    specificProperty.listing_price,
    annualAppreciation,
  );

  // Set total equity
  const totalEquity = {};
  for (let i = 1; i <= 10; i++) {
    totalEquity[`year${i}`] = propertyValue[`year${i}`] - annualMortgageBalanceObject[`year${i}`];
  }

  const equityRows = [
    {
      id: 1, lineItem: 'Property Value', ...formatTableValues(propertyValue, 'dollar'),
    },
    {
      id: 2, lineItem: 'Mortgage Balance', ...formatTableValues(annualMortgageBalanceObject, 'dollar'),
    },
    {
      id: 3, lineItem: 'Total Equity', ...formatTableValues(totalEquity, 'dollar'),
    },

  ];

  const cashInvested = calculateAnnualGeneral(specificProperty.listing_price * 0.25, 1);

  // Set cumulative cash flow
  const cumulativeCashFlow = {};
  for (let i = 1; i <= 10; i++) {
    cumulativeCashFlow[`year${i}`] = 0;
    for (let j = 1; j <= i; j++) {
      cumulativeCashFlow[`year${i}`] += cumulativeCashFlow[`year${j}`];
    }
  }

  // Set total profits
  const totalProfit = {};
  for (let i = 1; i <= 10; i++) {
    totalProfit[`year${i}`] = totalEquity[`year${i}`] + cumulativeCashFlow[`year${i}`] - cashInvested[`year${i}`];
  }

  const salesAnalysisRows = [
    {
      id: 1, lineItem: 'Total Equity', ...formatTableValues(totalEquity, 'dollar'),
    },
    {
      id: 2, lineItem: 'Cumulative Cash Flow', ...formatTableValues(cumulativeCashFlow, 'dollar'),
    },
    {
      id: 3, lineItem: 'Total Cash Invested', ...formatTableValues(cashInvested, 'dollar'),
    },
    {
      id: 4, lineItem: 'Total Profit', ...formatTableValues(totalProfit, 'dollar'),
    },

  ];

  // Set cap rates
  const capRate = {};
  for (let i = 1; i <= 10; i++) {
    capRate[`year${i}`] = (netOperatingIncome[`year${i}`] / specificProperty.listing_price) * 100;
  }

  // Set cap rates
  const cashOnCashReturn = {};
  for (let i = 1; i <= 10; i++) {
    cashOnCashReturn[`year${i}`] = (beforeTaxLeveredCashFlow[`year${i}`] / downPayment) * 100;
  }

  // Set cap rates
  const returnOnInvestment = {};
  for (let i = 1; i <= 10; i++) {
    returnOnInvestment[`year${i}`] = (totalProfit[`year${i}`] / downPayment) * 100;
  }

  // Set IRR for every year
  const IRRObject = {};
  for (let i = 1; i <= 10; i++) {
    const IRRArray = [-downPayment];
    for (let j = 1; j <= i; j++) {
      IRRArray.push(beforeTaxLeveredCashFlow[`year${j}`]);
    }
    const lastIndex = IRRArray.length - 1;
    IRRArray[lastIndex] += totalEquity[`year${i}`];
    IRRObject[`year${i}`] = irr(IRRArray) * 100;
  }

  // const IRRObject = {
  //   year1: irr([
  //     -downPayment,
  //     totalEquity.year1 + beforeTaxLeveredCashFlow.year1]) * 100,
  //   year2: irr([
  //     -downPayment,
  //     beforeTaxLeveredCashFlow.year1,
  //     totalEquity.year2 + beforeTaxLeveredCashFlow.year2]) * 100,
  //   year3: irr([
  //     -downPayment,
  //     beforeTaxLeveredCashFlow.year1,
  //     beforeTaxLeveredCashFlow.year2,
  //     totalEquity.year3 + beforeTaxLeveredCashFlow.year3]) * 100,
  //   year4: irr([
  //     -downPayment,
  //     beforeTaxLeveredCashFlow.year1,
  //     beforeTaxLeveredCashFlow.year2,
  //     beforeTaxLeveredCashFlow.year3,
  //     totalEquity.year4 + beforeTaxLeveredCashFlow.year4]) * 100,
  //   year5: irr([
  //     -downPayment,
  //     beforeTaxLeveredCashFlow.year1,
  //     beforeTaxLeveredCashFlow.year2,
  //     beforeTaxLeveredCashFlow.year3,
  //     beforeTaxLeveredCashFlow.year4,
  //     totalEquity.year5 + beforeTaxLeveredCashFlow.year5]) * 100,
  //   year10: 0,
  //   // year10: irr([-downPayment, totalEquity.year1 + beforeTaxLeveredCashFlow.year1]),
  // };

  const investmentReturnsRows = [
    {
      id: 1, lineItem: 'Cap Rate', ...formatTableValues(capRate, 'percent'),
    },
    {
      id: 2, lineItem: 'Cash on Cash Return', ...formatTableValues(cashOnCashReturn, 'percent'),
    },
    {
      id: 3,
      lineItem: 'IRR',
      ...formatTableValues(IRRObject, 'percent'),
    },
    {
      id: 4, lineItem: 'Return on Investment', ...formatTableValues(returnOnInvestment, 'percent'),
    },

  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Income
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={incomeRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Expenses
          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={expensesRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Cash Flow
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={cashflowRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Tax Benefits and Deductions
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={taxRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Equity Accumulation
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={equityRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Sale Analysis
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={salesAnalysisRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
      <div
        variant="outlined"
        style={{
          borderRadius: '10px', padding: '1em', width: '100%',
        }}
      >
        <Typography>
          <b>
            Investment Returns
            {' '}

          </b>
        </Typography>
        <Paper sx={{
          width: '100%',
          marginTop: '10px',
          '& .super-app-theme--header': {
            backgroundColor: '#eee',
          },
        }}
        >
          <DataGrid
            className={classes.hidePagination}
            disableSelectionOnClick
            autoHeight
            rows={investmentReturnsRows}
            columns={incomeColumns}
          />
        </Paper>
      </div>
    </div>
  );
}

export default PropertyAnalysis;
