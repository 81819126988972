import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import NewsCard from './newsCard';

function news() {
  return (
    <Paper sx={{ borderRadius: '10px', padding: '1em' }}>
      <Typography sx={{ marginBottom: '0.5em' }}>
        <b>
          Market Updates
        </b>

      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <NewsCard date="January 6, 2022" headline="Walmart opens new facility." source="Reuters" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NewsCard date="October 12, 2021" headline="New school opened for elementary school students." source="Reuters" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NewsCard date="August 3, 2021" headline="Big park announced in northern town." source="Reuters" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NewsCard date="September 20, 2020" headline="New mayor elected." source="Reuters" />
        </Grid>
      </Grid>
    </Paper>

  );
}

export default news;
