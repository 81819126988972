import React, { useState, useEffect, useContext } from 'react';
import {
  Card, CardActionArea, CardContent, Typography, CircularProgress, Box,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { axiosFindRegionRecord } from '../modules/api';
import { ResearchContext } from './ResearchContext';
import BookmarkButton from './bookmarkButton';
import { formatMetricValue } from '../utils/allMetrics';

export default function MarketCard({ regionId }) {
  const { regions } = useContext(ResearchContext);
  const [regionRecord, setRegionRecord] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();

    axiosFindRegionRecord(regionId, source).then((result) => setRegionRecord(result.data));
  }, []);

  return (
    <Card elevation={3} sx={{ minWidth: 0, borderRadius: '10px', height: '100%' }}>
      {(!regionRecord || !regions || regions.length === 0) ? (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 100,
          alignItems: 'center',
        }}
        >
          <CircularProgress />
        </Box>
      )
        : (
          <>
            <CardActionArea onClick={() => navigate(`/explore/${regionId}`)}>
              <CardContent sx={{ height: '100%' }}>
                <div className="sideBySide">
                  <div>
                    <div className="centeredRow">
                      <Typography variant="h5" component="div">
                        <b>{regions.filter((region) => region._id === regionId)[0].region_name}</b>
                      </Typography>
                      <BookmarkButton selectedRegion={regionId} sizeInPixels={20} />
                    </div>
                    <Typography gutterBottom variant="h6" color="#1d537f">
                      {regions.filter((region) => region._id === regionId)[0].region_state}
                    </Typography>
                    <Typography variant="body1" color="#636262" sx={{ marginTop: '25px' }}>
                      <b>Median Sales Price: </b>
                      {(regionRecord.median_sales_price !== undefined) ? formatMetricValue(regionRecord.median_sales_price, 'dollar') : 'Unavailable'}
                    </Typography>
                    <Typography variant="body1" color="#636262">
                      <b>Median Monthly Rent: </b>
                      {(regionRecord.median_rent !== undefined) ? formatMetricValue(regionRecord.median_rent, 'dollar') : 'Unavailable'}
                    </Typography>
                    <Typography variant="body1" color="#636262">
                      <b>Price-to-Rent Ratio:</b>
                      {' '}
                      {(regionRecord.median_sales_price !== undefined && regionRecord.median_rent !== undefined) ? (regionRecord.median_sales_price / (12 * regionRecord.median_rent)).toFixed(2) : 'Unavailable'}
                    </Typography>
                  </div>
                </div>

              </CardContent>
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" variant="contained" color="primary">
                Details
              </Button>
              <Button size="small" variant="outlined" color="primary">
                Key Updates
              </Button>
            </CardActions> */}
          </>
        )}

    </Card>
  );
}
