/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  IconButton, Paper, Chip, Box, Autocomplete,
  Popover, Stack, Pagination, TextField, CircularProgress,
  Divider,
  Button,
  // Select, MenuItem, FormControl, InputLabel,
  // FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import '../assets/main.css';
import '../assets/operations.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { useTheme } from '@mui/system';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
// import Papa from 'papaparse';
import ToolBar from './toolBar';
import PropertyCards from './propertyCards';
import AdvancedSideBar from './advancedSideBar';
import '../assets/properties.css';
import { PropertyContext } from './PropertyContext';
import stateAbbreviations from '../utils/stateAbbreviations';
import { ResearchContext } from './ResearchContext';
import { axiosGetAllCities } from '../modules/api';
import { UserContext } from './UserContext';

const moment = require('moment');

export function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function PropertiesPage() {
  const { cityID } = useParams();
  const [page, setPage] = useState(1);
  const [townData, setTownData] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState();
  const [priceSlider, setPriceSlider] = useState(['', '']);
  const [bedSlider, setBedSlider] = useState(['', '']);
  const [bathSlider, setBathSlider] = useState(['', '']);
  const { locationsPaidFor, subscriptionTier } = useContext(UserContext);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const navigate = useNavigate();

  const loading = autocompleteOpen && options.length === 0;

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: '1em',
    },
  }));

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For enough time to load.

      if (active) {
        setOptions(townData.filter((town) => !(town.city === 'New York' && town.state_name === 'New York')));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!autocompleteOpen) {
      setOptions([]);
    }
  }, [autocompleteOpen]);

  const propertiesPerPage = 12;

  const { properties } = useContext(PropertyContext);
  const [displayedProperties, setDisplayedProperties] = useState();
  const { regions } = useContext(ResearchContext);
  // const { regionID } = useParams();
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  console.log(cityID);

  function getTooltipText() {
    return (selectedSearchItem && (
      <div>
        <div>
          <em>

            The property list of
            {' '}
            <b>
              {selectedSearchItem.city}
              {', '}
              {selectedSearchItem.state_name}
              {' '}
              (
              {selectedSearchItem.county_name}
              {' County'}
              )
            </b>
            {' '}
            on Sailvest was most recently updated on
            {' '}
            <b>{selectedSearchItem.date_last_updated && moment(selectedSearchItem.date_last_updated).format('L')}</b>
            . Properties are typically updated every Sunday.
          </em>
        </div>
        <div style={{ marginTop: '25px' }}>
          <Typography>Attribution:</Typography>
          <em>
            City data retrieved from https://simplemaps.com/data/us-cities.
          </em>
        </div>
      </div>

    ));
  }

  function satisfiesFilters(property) {
    return (
      ((property.listing_price >= priceSlider[0]) || (priceSlider[0] === ''))
    && ((property.listing_price <= priceSlider[1]) || (priceSlider[1] === ''))
    && ((property.beds >= bedSlider[0]) || (bedSlider[0] === ''))
    && ((property.beds <= bedSlider[1]) || (bedSlider[1] === ''))
    && ((property.baths >= bathSlider[0]) || (bathSlider[0] === ''))
    && ((property.baths <= bathSlider[1]) || (bathSlider[1] === '')));
  }

  // useEffect(() => {
  //   // Fetch the CSV file or provide its content as a string
  //   const csvFilePath = '/uscities.csv'; // Replace with your CSV file path or content

  //   // Fetch the CSV file using 'fetch'
  //   fetch(csvFilePath)
  //     .then((response) => response.text())
  //     .then((csvContent) => {
  //       // Parse the CSV content with PapaParse
  //       Papa.parse(csvContent, {
  //         header: true,
  //         complete: (results) => {
  //           console.log(results.data.splice(0, 10));
  //           setTownData(results.data);
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching or parsing CSV:', error);
  //     });
  // }, []);

  // Loading in towns
  useEffect(() => {
    axiosGetAllCities().then((val) => {
      const retrievedTowns = val.data.data;
      setTownData(retrievedTowns);
      // console.log(cityID);
      const defaultCity = retrievedTowns.filter((town) => town._id === cityID);
      // console.log('when url is default');
      // console.log(defaultCity);

      if (defaultCity.length === 0) {
        setSelectedSearchItem(null);
      } else {
        setSelectedSearchItem(defaultCity[0]);
      }
    }).catch((e) => console.log(e));
  }, []);

  const handleChange = useCallback((e, newValue) => {
    setPage(newValue);
  });

  useEffect(() => {
    setDisplayedProperties(null);
    let newDisplayedProperties;
    let userPaidForTown = true;

    if (selectedSearchItem) {
      userPaidForTown = locationsPaidFor.includes(selectedSearchItem._id);
    }

    if (!userPaidForTown) {
      setDisplayedProperties('Unpaid');
    } else if (properties && townData.length > 0) {
      if (!selectedSearchItem) {
        // Filter based on filter sliders and properties user paid for
        newDisplayedProperties = properties.filter((property) => (
          (locationsPaidFor.includes(property.city_id))
        && satisfiesFilters(property)));
        if (subscriptionTier === 'Free') {
          newDisplayedProperties = newDisplayedProperties.slice(0, 3);
        }
      } else if (properties.filter((property) => (
        (property.city_id === selectedSearchItem._id))).length === 0) {
        // Handle case where properties are not covered on our end
        newDisplayedProperties = 'No Active Listings';
      } else if (selectedSearchItem.category === 'State') {
        // Filter based on state name
        newDisplayedProperties = properties.filter((property) => (property.state === stateAbbreviations[selectedSearchItem.item]) && satisfiesFilters(property));
      } else if (selectedSearchItem.category === 'County') {
        const whichRegionID = regions.filter((region) => region.region_name === selectedSearchItem.item.split(',')[0])[0]._id;

        // Filter based on region ID
        newDisplayedProperties = properties.filter((property) => ((property.region_id === whichRegionID)
        && satisfiesFilters(property)));
      } else {
        // Filter based on city ID
        newDisplayedProperties = properties.filter((property) => (
          (property.city_id === selectedSearchItem._id)
          && satisfiesFilters(property)));
        if (subscriptionTier === 'Free') {
          newDisplayedProperties = newDisplayedProperties.slice(0, 3);
        }
      }

      // console.log('New Displayed Properties');
      // console.log(newDisplayedProperties);
      // console.log(selectedSearchItem);
      // console.log(townData);
      // console.log(properties);
      setDisplayedProperties(newDisplayedProperties);
    }

    setPage(1);
  }, [selectedSearchItem, townData, properties, anchorEl]);

  const handleSearch = useCallback((event, newValue) => {
    setSelectedSearchItem(newValue);
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [viewport, setViewport] = useState({
    latitude: 40.8653,
    longitude: -74.4174,
    zoom: 10,
    width: '90%',
    height: '90%',
  });

  useEffect(() => {
    if (selectedSearchItem && selectedSearchItem.lat && selectedSearchItem.lng) {
      setViewport({
        latitude: selectedSearchItem.lat,
        longitude: selectedSearchItem.lng,
        zoom: 10,
        width: '90%',
        height: '90%',
      });
    }
  }, [selectedSearchItem]);

  const propertiesForCards = useCallback(() => {
    // console.log('Properties For Cards running');
    // console.log(displayedProperties);
    // console.log(properties);
    if (displayedProperties === 'Unpaid') {
      return 'Unpaid';
    }
    if (displayedProperties === 'No Active Listings') {
      return 'No Active Listings';
    }
    if (displayedProperties) {
      return displayedProperties.slice((page - 1) * propertiesPerPage, (page - 1) * propertiesPerPage + (propertiesPerPage));
    }
    return null;
  }, [displayedProperties, page]);

  useEffect(() => {
    // console.log('These are all active properties');
    // console.log(properties);
  }, [properties]);

  // function getAllAddresses() {
  //   if (properties === null || properties === undefined) {
  //     return [];
  //   }
  //   return [...new Set(properties.map((property) => ['Property', property.address.split(',')[0]]))];
  // }

  // function getAllZipCodes() {
  //   if (properties === null || properties === undefined) {
  //     return [];
  //   }
  //   return [...new Set(properties.map((property) => ['Zip Code', (property.address.split(',')[2]).replace(/[a-zA-Z ]/g, '')]))];
  // }

  // const getAllTowns = useCallback(() => {
  //   console.log('Town Function Ran');

  //   if (properties === null || properties === undefined) {
  //     return [];
  //   }
  //   return [...new Set(properties.map((property) => ['Town', `${property.address.split(',')[1]}, ${property.state}`]))];
  // }, [properties]);

  // const getAllCounties = useCallback(() => {
  //   if (regions === null || regions === undefined) {
  //     return [];
  //   }
  //   return regions.map((region) => ({ category: 'County', item: `${region.region_name}, ${stateAbbreviations[region.region_state]}` }));
  // }, [regions]);

  // const getAllStates = useCallback(() => Object.keys(stateAbbreviations).map((state) => ({ category: 'State', item: state })), []);

  // const generateAllSearchItems = useCallback(() => [...getAllCounties(), ...getAllStates()], [getAllCounties, getAllStates]);

  // const allItems = generateAllSearchItems();

  useEffect(() => {

    // IGNORING THIS FOR NOW (THIS IS THE CODE THAT GETS THE REGION FROM THE URL)
    // if (properties !== null && properties !== undefined) {
    //   if (regions !== null && regions !== undefined) {
    //     const regionObject = regions.filter((region) => region._id === regionID)[0];
    //     if (regionObject) {
    //       const searchItem = { category: 'County', item: `${regionObject.region_name}, ${stateAbbreviations[regionObject.region_state]}` };
    //       setSelectedSearchItem(searchItem);
    //     }
    //   } else {
    //     setDisplayedProperties(properties);
    //   }
    // }
  }, [properties, regions]);

  // useEffect(() => {
  //   console.log('Displayed Properties Changed');
  // }, [displayedProperties]);

  // useEffect(() => {
  //   console.log('Search Item');
  //   console.log(selectedSearchItem);
  // }, [selectedSearchItem]);

  // useEffect(() => {
  //   console.log('Properties Changed');
  //   console.log(properties);
  // }, [properties]);

  return (
    <div style={{ flex: 'column' }}>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Properties" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody">
            <strong>Properties</strong>
          </Typography>
          <div className="mainBody">
            <div className="sideBySideSpacedOut" style={{ alignItems: 'stretch' }}>
              <div className="propertyCardsContainer">
                <div className="sideBySideSpacedOut propertyControls">
                  <div className="sideBySide propertySearchBar">
                    <div className="centeredRow" style={{ position: 'relative' }}>
                      {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Age</InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectedSearchItem}
                          label="Age"
                          onChange={handleSearch}
                        >
                          {townData.map((row) => (
                            <MenuItem value={row}>
                              {row.city}
                              ,
                              {' '}
                              {row.state_name}
                              {' '}
                              (
                              {row.county_name}
                              {' '}
                              County)
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <Autocomplete
                        id="town-picker"
                        isOptionEqualToValue={(option, value) => (option._id === value._id)}
                        size="small"
                        sx={{ width: '400px' }}
                        open={autocompleteOpen}
                        onOpen={() => {
                          setAutocompleteOpen(true);
                        }}
                        onClose={() => {
                          setAutocompleteOpen(false);
                        }}
                        loading={loading}
                        onChange={handleSearch}
                        value={selectedSearchItem}
                        options={options}
                        // groupBy={(searchItem) => (searchItem.state)}
                        getOptionLabel={(townRow) => {
                          if (townRow.city === 'New York' && townRow.state_name === 'New York') {
                            return (
                              `${townRow.city}, ${townRow.state_name}`);
                          }
                          return (
                            `${townRow.city}, ${townRow.state_name} (${townRow.county_name} County)`);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <Typography>
                              {(option.city === 'New York' && option.state_name === 'New York')

                                ? `${option.city}, ${option.state_name}`

                                : (
                                  `${option.city}, ${option.state_name} (${option.county_name} County)`)}
                            </Typography>
                            {locationsPaidFor.includes(option._id) && (
                            <div
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '5px',
                                backgroundColor: '#6530a2',
                                borderRadius: '50%',
                                border: '2px white solid',
                                // transform: 'translate(-50%, -50%)',
                                // textAlign: 'center',
                              }}
                            />
                            )}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Location"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                      <CustomTooltip
                        title={(
                          <>
                            {/* <Typography color="inherit">Additional Info</Typography> */}
                            {getTooltipText()}
                          </>
        )}
                        placement="right"
                      >
                        <InfoOutlinedIcon sx={{
                          marginLeft: '5px',
                          width: '15px',
                          height: '15px',
                          transform: 'translateY(0.5px)',
                          '&:hover': {
                            color: '#377bba',
                          },
                        }}
                        />
                      </CustomTooltip>
                    </div>
                    {/* <TextField id="outlined-basic" label="Search" variant="outlined" size="small" /> */}
                    {/* <IconButton sx={{
                      backgroundColor: theme.palette.primary.main,
                      transform: 'translateX(0px)',
                      color: 'white',
                      // marginLeft: '5px',
                      '&:hover': { backgroundColor: theme.palette.primary.dark },
                      borderRadius: '5px',
                    }}
                    >
                      <SearchIcon />
                    </IconButton> */}
                  </div>
                  {/* <FormControl size="small" sx={{ width: 200, marginRight: '5px' }}>
                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortComparator}
                      label="Sort By"
                      onChange={(e) => setSortComparator(e.target.value)}
                    >
                      <MenuItem value="Price">Price</MenuItem>
                      <MenuItem value="Date Listed">Date Listed</MenuItem>
                      <MenuItem value="Square Ft">Square Ft</MenuItem>
                    </Select>
                  </FormControl> */}
                  <div style={{ position: 'relative' }}>
                    <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                      <TuneIcon />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Paper
                        sx={{
                          borderRadius: '10px', border: '1px gray solid', padding: '10px 25px 10px 25px', zIndex: '5',
                        }}
                      >
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          <Typography><b>Filters</b></Typography>
                        </div>

                        {/* <Checkbox checked={abc} onChange={handleChange} name="abc" sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} /> */}
                        <Box sx={{ width: 300 }}>
                          <Typography id="input-slider" gutterBottom>
                            Price
                          </Typography>
                          <div className="centeredRow">
                            <TextField
                              // label="Min"
                              sx={{ width: 125, marginTop: '5px' }}
                              id="outlined-size-small"
                              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                              placeholder="No Min"
                              value={priceSlider[0]}
                              onChange={(e) => setPriceSlider([e.target.value, priceSlider[1]])}
                              size="small"
                            />
                            <Divider sx={{ flexGrow: 2 }} />
                            <TextField
                              // label="Max"
                              sx={{ width: 125 }}
                              id="outlined-size-small"
                              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                              placeholder="No Max"
                              value={priceSlider[1]}
                              onChange={(e) => setPriceSlider([priceSlider[0], e.target.value])}
                              size="small"
                            />
                          </div>
                          {/* <Slider
                            getAriaLabel={() => 'Price'}
                            name="priceSlider"
                            value={priceSlider}
                            onChange={(e, newValue) => setPriceSlider(newValue)}
                            valueLabelDisplay="auto"
                            step={500000}
                            marks
                            min={0}
                            max={2000000}
                          /> */}
                          <Typography id="input-slider" gutterBottom sx={{ marginTop: '15px' }}>
                            Bedrooms
                          </Typography>
                          <div className="centeredRow">
                            <TextField
                              // label="Min"
                              sx={{ width: 125, marginTop: '5px' }}
                              id="outlined-size-small"
                              placeholder="No Min"
                              value={bedSlider[0]}
                              onChange={(e) => setBedSlider([e.target.value, bedSlider[1]])}
                              size="small"
                            />
                            <Divider sx={{ flexGrow: 2 }} />
                            <TextField
                              // label="Max"
                              sx={{ width: 125 }}
                              id="outlined-size-small"
                              placeholder="No Max"
                              value={bedSlider[1]}
                              onChange={(e) => setBedSlider([bedSlider[0], e.target.value])}
                              size="small"
                            />
                          </div>
                          {/* <Slider
                            getAriaLabel={() => 'Bedrooms'}
                            name="bedSlider"
                            value={bedSlider}
                            onChange={(e, newValue) => setBedSlider(newValue)}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={0}
                            max={10}
                          /> */}
                          <Typography id="input-slider" gutterBottom sx={{ marginTop: '15px' }}>
                            Bathrooms
                          </Typography>
                          <div className="centeredRow" style={{ marginBottom: '15px' }}>
                            <TextField
                              // label="Min"
                              sx={{ width: 125, marginTop: '5px' }}
                              id="outlined-size-small"
                              placeholder="No Min"
                              value={bathSlider[0]}
                              onChange={(e) => setBathSlider([e.target.value, bathSlider[1]])}
                              size="small"
                            />
                            <Divider sx={{ flexGrow: 2 }} />
                            <TextField
                              // label="Max"
                              sx={{ width: 125 }}
                              id="outlined-size-small"
                              placeholder="No Max"
                              value={bathSlider[1]}
                              onChange={(e) => {
                                console.log(e.target.value === '');
                                setBathSlider([bathSlider[0], e.target.value]);
                              }}
                              size="small"
                            />
                          </div>
                          <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2 }}
                            onClick={() => setAnchorEl(null)}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Paper>
                    </Popover>
                  </div>

                </div>
                <div className="sideBySide centeredRow" style={{ justifyContent: 'space-between', marginBottom: '10px' }}>
                  <Typography variant="body2" color="#626262">
                    {selectedSearchItem && selectedSearchItem.date_last_updated && moment(selectedSearchItem.date_last_updated).format('L')}
                  </Typography>
                  <Typography variant="body2" color="#626262">
                    {(displayedProperties === 'Unpaid' || displayedProperties === 'No Active Listings' || !displayedProperties) ? 0 : displayedProperties.length}
                    {' '}
                    Results
                    {displayedProperties && ` (Page ${page} of ${Math.floor(displayedProperties.length / propertiesPerPage) + 1})`}
                  </Typography>
                </div>
                <div style={{ height: '62vh', overflowY: 'scroll' }}>
                  <PropertyCards
                    town={selectedSearchItem && selectedSearchItem.city}
                    countyID={selectedSearchItem && selectedSearchItem.region_id}
                    state={selectedSearchItem && selectedSearchItem.state_id}
                    displayedProperties={propertiesForCards()}
                  />
                </div>
                <Stack spacing={2}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {displayedProperties && <Pagination color="primary" count={Math.floor(displayedProperties.length / propertiesPerPage) + 1} page={page} onChange={handleChange} />}
                  </div>
                </Stack>
              </div>
              <div className="verticalDivider" />
              <div className="propertiesMapContainer">
                <Paper sx={{
                  backgroundColor: '#eee', borderRadius: '10px', width: '100%', height: '100%', padding: '10px',
                }}
                >
                  <ReactMapGL
                    {...viewport}
                    mapStyle="mapbox://styles/neiljohnmathew/clj32h0nc02yw01qpc0z39at0"
                    mapboxAccessToken="pk.eyJ1IjoibmVpbGpvaG5tYXRoZXciLCJhIjoiY2xqMmQzNjR4MGx4NjNsbzR5YTRnYXgzaSJ9.Iv8052Dwij0A00Iqi_j8GQ"
                    onMove={(evt) => setViewport(evt.viewport)}
                  >
                    {displayedProperties && (displayedProperties !== 'Unpaid') && (displayedProperties !== 'No Active Listings') && displayedProperties.slice((page - 1) * propertiesPerPage, (page - 1) * propertiesPerPage + (propertiesPerPage)).map((property) => (
                      <Marker
                        latitude={property.coordinates.lat}
                        longitude={property.coordinates.lng}
                        onClick={() => {
                          navigate(`/property/${property._id}`);
                        }}
                      >
                        <div
                          style={{
                            width: '15px',
                            height: '15px',
                            backgroundColor: '#3ea2fa',
                            borderRadius: '50%',
                            border: '2px white solid',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                          }}
                          onMouseEnter={() => setHoveredMarker(property)}
                          onMouseOutCapture={() => setHoveredMarker(null)}

                        />
                      </Marker>
                    ))}
                    {hoveredMarker ? (
                      <Popup
                        latitude={hoveredMarker.coordinates.lat}
                        longitude={hoveredMarker.coordinates.lng}
                      >
                        <div className="sideBySide" style={{ marginRight: '10px' }}>
                          <div>
                            <Typography component="div" sx={{ fontSize: '1em', marginRight: '15px' }}>
                              {hoveredMarker.address.split(',')[0]}
                            </Typography>
                            <Typography color="#1d537f" component="div" sx={{ fontSize: '1em' }}>
                              {hoveredMarker.address.split(',')[1]}
                              ,
                              {' '}
                              {hoveredMarker.address.split(',')[2].match(/[a-zA-Z]/g)}
                            </Typography>
                            <div style={{ textAlign: 'center' }}>
                              <Chip
                                label={`$ ${(hoveredMarker.listing_price).toLocaleString('en-us')}`}
                                sx={{
                                  fontSize: '1.25em', padding: '0.75em 0.5em 0.75em 0.5em', marginTop: '10px',
                                }}
                              />
                            </div>
                          </div>
                          <div className="propertyImage" style={{ backgroundImage: `url(${hoveredMarker.listing_image})`, width: '150px', marginLeft: '10px' }} />
                        </div>

                      </Popup>
                    ) : null}
                  </ReactMapGL>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
