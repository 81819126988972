/* eslint-disable max-len */
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import {
  getInvestorProfile,
  getSubscriptionByUserEmail,
  axiosUpdateUserSubscriptionDB,
} from '../modules/api';

// Create Property Context for all child elements of provider to access the value of the context
export const UserContext = createContext();

export default function UserContextProvider({ children }) {
  // Get current user
  const username = localStorage.getItem('currUser');
  const userID = localStorage.getItem('currUserId');

  const [targetGeographicRegions, setTargetGeographicRegions] = useState([]);
  const [availableCash, setAvailableCash] = useState(0);
  const [investmentTimeline, setInvestmentTimeline] = useState('');
  const [financingPlan, setFinancingPlan] = useState('');
  const [savedMarkets, setSavedMarkets] = useState([]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [locationsPaidFor, setLocationsPaidFor] = useState([]);
  const [nextLocationsPaidFor, setNextLocationsPaidFor] = useState([]);
  const [email, setEmail] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState([]);
  const [dateUserUpdatedLocations, setDateUserUpdatedLocations] = useState();

  function updateUserProfile() {
    getInvestorProfile(userID).then((result) => {
      const returnedProfile = result.data[0];

      if (returnedProfile.target_geographic_regions) {
        setTargetGeographicRegions(returnedProfile.target_geographic_regions);
      }
      if (returnedProfile.available_cash) {
        setAvailableCash(returnedProfile.available_cash);
      }
      if (returnedProfile.investment_timeline) {
        setInvestmentTimeline(returnedProfile.investment_timeline);
      }
      if (returnedProfile.financing_plan) {
        setFinancingPlan(returnedProfile.financing_plan);
      }
      if (returnedProfile.saved_markets) {
        setSavedMarkets(returnedProfile.saved_markets);
      }
      if (returnedProfile.saved_properties) {
        setSavedProperties(returnedProfile.saved_properties);
      }
      if (returnedProfile.locations_paid_for) {
        setLocationsPaidFor(returnedProfile.locations_paid_for);
      }
      if (returnedProfile.next_locations_paid_for) {
        setNextLocationsPaidFor(returnedProfile.next_locations_paid_for);
      }
      if (returnedProfile.email) {
        setEmail(returnedProfile.email);
      }
      if (returnedProfile.date_user_updated_locations) {
        setDateUserUpdatedLocations(returnedProfile.date_user_updated_locations);
      }
      return returnedProfile;
    }).then(async (returnedProfile) => {
      const subscription = await getSubscriptionByUserEmail(returnedProfile.email);
      return [subscription, returnedProfile];
    }).then((result) => {
      const subscriptionStripe = result[0].data.data;
      const returnedProfile = result[1];
      // if we had manually made a user unlimited then we ignore what stripe says
      if (returnedProfile.subscription_tier === 'Unlimited') {
        setSubscriptionTier('Unlimited');
      } else {
        setSubscriptionTier(subscriptionStripe);

        if (returnedProfile.subscription_tier) {
          // update the db w the subscription if not yet updated
          if (returnedProfile.subscription_tier !== subscriptionStripe) {
            axiosUpdateUserSubscriptionDB(returnedProfile.email, subscriptionStripe);
          }
        } else {
          axiosUpdateUserSubscriptionDB(returnedProfile.email, subscriptionStripe);
        }
      }
    });
  }

  // Load user's properties when component renders
  useEffect(() => {
    try {
      updateUserProfile();
    } catch (e) {
      // console.log(val.data)
    }
  }, []);

  const memoValue = useMemo(() => ({
    username,
    userID,
    targetGeographicRegions,
    availableCash,
    investmentTimeline,
    financingPlan,
    savedMarkets,
    savedProperties,
    updateUserProfile,
    setLocationsPaidFor,
    locationsPaidFor,
    setNextLocationsPaidFor,
    nextLocationsPaidFor,
    subscriptionTier,
    email,
    dateUserUpdatedLocations,
    setDateUserUpdatedLocations,
  }), [targetGeographicRegions,
    availableCash,
    investmentTimeline,
    financingPlan,
    savedMarkets,
    savedProperties,
    locationsPaidFor,
    setLocationsPaidFor,
    nextLocationsPaidFor,
    setNextLocationsPaidFor,
    subscriptionTier,
    email,
    dateUserUpdatedLocations,
    setDateUserUpdatedLocations]);

  return (
    <UserContext.Provider value={memoValue}>
      {children}
    </UserContext.Provider>
  );
}
