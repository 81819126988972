import React, { useContext } from 'react';
import '../assets/properties.css';
import {
  Grid, CircularProgress, Alert, Button,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import PropertyCard from './propertyCard';
import { UserContext } from './UserContext';

function PropertyCards({
  displayedProperties, town, countyID, state,
}) {
  // console.log('Properties');
  // console.log(displayedProperties);
  const { subscriptionTier } = useContext(UserContext);
  const navigate = useNavigate();
  // If displayedProperties haven't loaded yet, show loading sign
  if (displayedProperties == null) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (displayedProperties === 'Unpaid') {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert
          // color="#a52e2e"
          sx={{
            backgroundColor: '#6530a2',
            border: '2px solid white',
            color: '#eee',
          }}
          icon={<LockIcon fontSize="inherit" sx={{ color: '#eee' }} />}
          action={(
            <Button
              variant="outlined"
              onClick={() => navigate('/account')}
              sx={{
                marginLeft: '25px',
                backgroundColor: '#eee',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#eee',
                  color: '#000000',
                  transform: 'scale(1.05)',
                },
              }}
              size="small"
            >
              Account
            </Button>
          )}
        >
          Unlock
          {' '}
          <b>
            {town}
            ,
            {' '}
            {state}
          </b>
          {' '}
          in your Account page.
        </Alert>
      </div>
    );
  }

  if (displayedProperties === 'No Active Listings') {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert severity="warning">
          No active listings retrieved for this region. Contact us at
          {' '}
          <u>contact.sailvest@gmail.com</u>
          {' '}
          if properties are not updated after a week.
        </Alert>
      </div>
    );
  }

  if (displayedProperties.length === 0) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert severity="warning">
          No results
        </Alert>
      </div>
    );
  }

  if (displayedProperties.length <= 3 && subscriptionTier === 'Free') {
    return (
      <div>
        <Grid container spacing={2}>
          {displayedProperties
          && displayedProperties.length > 0
          && displayedProperties.map((property) => (
            <Grid item key={property._id} xs={12} lg={6}>
              <PropertyCard countyID={countyID} property={property} />
            </Grid>
          ))}
        </Grid>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Alert
            sx={{
              backgroundColor: '#6530a2',
              border: '2px solid white',
              color: '#eee',
            }}
            icon={<LockIcon fontSize="inherit" sx={{ color: '#eee' }} />}
            action={(
              <Button
                variant="outlined"
                onClick={() => navigate('/account')}
                sx={{
                  marginLeft: '25px',
                  backgroundColor: '#eee',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#eee',
                    color: '#000000',
                    transform: 'scale(1.05)',
                  },
                }}
                size="small"
              >
                Account
              </Button>
            )}
          >
            Unlock more properties in
            {' '}
            <b>
              {town}
              ,
              {' '}
              {state}
            </b>
            {' '}
            on your Account page.
          </Alert>
        </div>
      </div>
    );
  }

  // Grid of property cards
  return (
    <Grid container spacing={2}>
      {displayedProperties
        && displayedProperties.length > 0
        && displayedProperties.map((property) => (
          <Grid item key={property._id} xs={12} lg={6}>
            <PropertyCard countyID={countyID} property={property} />
          </Grid>
        ))}
    </Grid>
  );
}

export default React.memo(PropertyCards);
