/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from 'react';
import {
  Paper, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import axios from 'axios';
import { allMetrics } from '../utils/allMetrics';
import ComparisonBarChart from './comparisonBarChart';
import { axiosFindAverageRegionLevelData } from '../modules/api';
import { ResearchContext } from './ResearchContext';

function metricComparisonCard({ metric, regionRecord }) {
  // State variables
  const [timeline, setTimeline] = useState('max');
  const [averageRegionMetricData, setAverageRegionMetricData] = useState([]);
  const { regions } = useContext(ResearchContext);

  // Load in historical region metric data and averages across state and nation
  useEffect(() => {
    if (regionRecord.region_id !== '' && metric !== '') {
      const source = axios.CancelToken.source();

      // Temporarily set it to undefined to prevent holding on to previous value
      setAverageRegionMetricData(undefined);

      // Get county, national, average for metric
      axiosFindAverageRegionLevelData(regionRecord.region_id, metric, timeline, source).then(
        (result) => {
          setAverageRegionMetricData(result.data);
        },
      );
    }
  }, [metric, timeline, regionRecord]);

  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: '10px', padding: '1em', height: 'auto',
      }}
    >
      <div className="sideBySide" style={{ justifyContent: 'space-between', width: '100%' }}>
        <Typography>
          <b>
            {(metric !== undefined) ? allMetrics[metric].display_name : '...'}
            {allMetrics[metric].comparison === 'Growth' ? ' Growth' : ' '}
            {' '}
            Comparison
            {' '}
          </b>

        </Typography>
        {allMetrics[metric].comparison === 'Growth' && (
        <FormControl sx={{ minWidth: '125px', marginRight: '15px' }} size="small">
          <InputLabel id="demo-simple-select-label">Timeline</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="metric-select"
            value={timeline}
            onChange={(event) => setTimeline(event.target.value)}
            label="Timeline"
          >
            <MenuItem value={5}>5 years</MenuItem>
            <MenuItem value={10}>10 years</MenuItem>
            <MenuItem value="max">Max</MenuItem>
          </Select>
        </FormControl>
        )}
      </div>
      <div style={{ width: '100%', height: '500px' }}>
        <ComparisonBarChart
          metric={metric}
          data={averageRegionMetricData}
          regionName={(regions.filter(
            (region) => (region._id === regionRecord.region_id),
          ))[0].region_name}
          regionState={(regions.filter(
            (region) => (region._id === regionRecord.region_id),
          ))[0].region_state}
        />
      </div>
    </Paper>
  );
}

export default metricComparisonCard;
