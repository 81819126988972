import React, {
  createContext, useEffect, useState, useMemo,
} from 'react';
// import {
//   axiosUpdateProperty,
//   getAllProperties, axiosAddProperty, axiosDeleteProperty,
// } from '../modules/api';
import axios from 'axios';
import {
  axiosFindProperty,
  getAllProperties,
} from '../modules/api';

const moment = require('moment');

// const Promise = require('bluebird');

// Create Property Context for all child elements of provider to access the value of the context
export const PropertyContext = createContext();

export default function PropertyContextProvider({ children }) {
  // Get current user
  // const currentUser = localStorage.getItem('currUser');
  // let userId;

  // Keep track of user's properties
  const [properties, setProperties] = useState();

  // Load all properties when component renders
  useEffect(() => {
    try {
      getAllProperties().then(async (val) => {
        const allProperties = val.data;
        // Only show the listings which haven't been removed from Zillow
        const activeProperties = allProperties.filter((propertyObject) => (
          propertyObject.date_removed === null
          || moment(propertyObject.date_added).diff(moment(propertyObject.date_removed), 'seconds') > 0));
        // console.log('Actv proprts');
        // console.log(activeProperties);
        setProperties(activeProperties);
      });
    } catch (e) {
      // console.log(val.data)
    }
  }, []);

  const findProperty = (id) => {
    const source = axios.CancelToken.source();

    axiosFindProperty(id, source);
  };
  // const addProperty = (newProperty) => {
  //   axiosAddProperty(newProperty);
  // };

  // const deleteProperty = (id) => {
  //   axiosDeleteProperty(id);
  // };

  // const updateProperty = (id, updatedProperty) => {
  //   axiosUpdateProperty(updatedProperty);
  // };

  const memoValue = useMemo(() => ({
    properties, findProperty,
  }), [properties, findProperty]);

  return (
    <PropertyContext.Provider value={memoValue}>
      {children}
    </PropertyContext.Provider>
  );
}
