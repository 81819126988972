/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import axios from 'axios';
import {
  Paper, CircularProgress, Popper, MenuItem, MenuList, Grow,
  // FormControl, Select, MenuItem, InputLabel,
  Button, styled, Chip, Typography, Modal, Box,
  Autocomplete, TextField, ButtonGroup, ClickAwayListener,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Map, Marker } from 'react-map-gl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ResearchContext } from './ResearchContext';
import { axiosFindCitiesWithinCounty, axiosFindHistoricalRegionData, axiosFindRegionRecord } from '../modules/api';
import CurrentMarketSnapshot from './currentMarketSnapshot';
import MarketFundamentals from './marketFundamentals';
import Liquidity from './liquidity';
import News from './news';
import Network from './network';
import BookmarkButton from './bookmarkButton';
import '../assets/researchPage.css';

// Styling the tabs within the Single Market View (snapshot, fundamentals, etc)
const MyTab = styled(Button)(() => ({
  color: '#555454',
  paddingRight: '15px',
  paddingLeft: '15px',
  borderRadius: '10px',
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#e2e2e2',
    color: '#000000',
  },
}));
const selectedTabStyling = {
  color: 'white',
  '&:hover': {
    backgroundColor: '#144a77',
    color: 'white',
  },
};

export default function singlemarketView({ defaultRegionID }) {
  // Important state variables
  const { regions } = useContext(ResearchContext);

  const [tab, setTab] = useState('Current Snapshot');
  const [townButtonOpen, setTownButtonOpen] = useState(false);
  const townButtonRef = useRef(null);
  const [selectedTownButtonIndex, setSelectedTownButtonIndex] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState(defaultRegionID || '63e4478fe9a9a32574f1b0ac');
  const [regionRecord, setRegionRecord] = useState('');
  const [citiesWithinCounty, setCitiesWithinCounty] = useState([]);
  const [centerCoordinatesForMap, setCenterCoordinatesForMap] = useState();
  const [viewport, setViewport] = React.useState({
    latitude: 39,
    longitude: -98,
    zoom: 3,
    width: '90%',
    height: '90%',
  });
  const [mapShow, setMapShow] = useState(false);
  const navigate = useNavigate();

  const cityOptions = citiesWithinCounty.map((cityInfo) => `View Properties in ${cityInfo.city}, ${cityInfo.state_id}`);

  const handleTownButtonClick = () => {
    navigate(`/properties/${citiesWithinCounty[selectedTownButtonIndex]._id}`);
  };

  const handleMenuItemClick = (e, index) => {
    setSelectedTownButtonIndex(index);
    setTownButtonOpen(false);
  };

  const handleTownButtonToggle = () => {
    setTownButtonOpen((prevOpen) => !prevOpen);
  };

  const handleTownButtonClose = (e) => {
    if (townButtonRef.current && townButtonRef.current.contains(e.target)) {
      return;
    }
    setTownButtonOpen(false);
  };

  // Sets center coordinates of Google Map
  useEffect(async () => {
    const region = regions.filter((tempRegion) => tempRegion._id === selectedRegion)[0];

    if (region != null) {
      setCenterCoordinatesForMap([region.coordinates.lat, region.coordinates.lng]);
    }
  }, [regions, selectedRegion]);

  useEffect(() => {
    axiosFindCitiesWithinCounty(selectedRegion).then((result) => {
      setCitiesWithinCounty(result.data);
    });
  }, [selectedRegion]);

  // Finds latest info of selected region
  useEffect(() => {
    // Temporarily set so that previous county data isn't displayed
    // This prevents confusion since sometimes previous data stays on screen while new data loads
    setRegionRecord('');

    if (selectedRegion !== '') {
      const source = axios.CancelToken.source();

      axiosFindRegionRecord(selectedRegion, source).then(
        (regionRecordResult) => {
          axiosFindHistoricalRegionData(selectedRegion, 'fed_funds_rate', source).then((fedFunds) => {
            const preData = regionRecordResult.data;
            const fedFundsData = fedFunds.data;

            if (preData === undefined) {
              setRegionRecord({
                region_id: selectedRegion,
                fed_funds_rate: fedFundsData[fedFundsData.length - 1].fed_funds_rate,
              });
            } else {
              preData.fed_funds_rate = fedFundsData[fedFundsData.length - 1].fed_funds_rate;
              setRegionRecord(preData);
            }
          });
        },
      ).catch((e) => {
        console.log(e);
      });
    }
  }, [selectedRegion]);

  // Shows different tabs within Single Market View
  function TabPanel() {
    switch (tab) {
      case 'Current Snapshot':
        return <CurrentMarketSnapshot regionRecord={regionRecord} />;
      case 'Market Fundamentals':
        return <MarketFundamentals regionRecord={regionRecord} />;
      case 'Liquidity':
        return <Liquidity regionRecord={regionRecord} />;
      case 'News':
        return <News />;
      case 'Network':
        return <Network />;
      default:
        return <CurrentMarketSnapshot />;
    }
  }

  // Show loading symbol when data still being retrieved
  if (regions === undefined || regions.length === 0) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="mainBody">
      <div className="centeredRow">
        <Autocomplete
          id="grouped-demo"
          value={selectedRegion}
          // eslint-disable-next-line max-len
          onChange={(e, newValue) => { if (newValue !== '' && newValue !== null) { setSelectedRegion(newValue); } }}
          // eslint-disable-next-line max-len
          options={regions.sort((a, b) => -b.region_state.localeCompare(a.region_state)).map((region) => region._id)}
          // eslint-disable-next-line max-len
          groupBy={(regionId) => (regions.filter((region) => region._id === regionId))[0].region_state}
          getOptionLabel={(regionId) => `${(regions.filter((region) => region._id === regionId))[0].region_name}, ${(regions.filter((region) => region._id === regionId))[0].region_state}`}
          sx={{ minWidth: '500px', marginRight: '5px' }}
          renderInput={(params) => <TextField {...params} label="Region" />}
          size="small"
        />
        {/* <FormControl sx={{ minWidth: '500px', marginRight: '5px' }}>
          <InputLabel id="demo-simple-select-label">Area</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="select-1"
            value={selectedRegion}
            onChange={(e) => setSelectedRegion(e.target.value)}
            label="Area 1"
            size="small"
          >
            {regions.map((region) => (
              <MenuItem key={region._id} value={region._id}>
                {region.region_name}
                ,
                {' '}
                {region.region_state}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <BookmarkButton selectedRegion={selectedRegion} />
        <Chip
          label="View Map"
          onClick={() => setMapShow(true)}
        />
        <ButtonGroup variant="contained" sx={{ textTransform: 'none', borderRadius: '10px', marginLeft: 'auto' }} ref={townButtonRef} aria-label="split button">
          <Button sx={{ textTransform: 'none', borderRadius: '10px', marginLeft: 'auto' }} onClick={handleTownButtonClick}>{cityOptions[selectedTownButtonIndex]}</Button>
          <Button
            size="small"
            aria-controls={townButtonOpen ? 'split-button-menu' : undefined}
            aria-expanded={townButtonOpen ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleTownButtonToggle}
          >
            <ArrowDropDownIcon />

          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 5,
          }}
          open={townButtonOpen}
          anchorEl={townButtonRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleTownButtonClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {cityOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedTownButtonIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {/* <Button variant="contained" sx={{ textTransform: 'none', borderRadius: '10px', marginLeft: 'auto' }} onClick={() => navigate(`/properties/${selectedRegion}`)}>{`View Properties in ${(regions.filter((region) => region._id === selectedRegion))[0].region_name}`}</Button> */}
      </div>
      <div className="sideBySide smvTabs">
        <MyTab
          variant={(tab === 'Current Snapshot') ? 'contained' : 'text'}
          sx={(tab === 'Current Snapshot') ? selectedTabStyling : {}}
          value="Current Snapshot"
          onClick={(e) => setTab(e.target.value)}
        >
          Current Snapshot

        </MyTab>
        <div className="vl" />
        <MyTab
          variant={(tab === 'Market Fundamentals') ? 'contained' : 'text'}
          sx={(tab === 'Market Fundamentals') ? selectedTabStyling : {}}
          className={(tab === 'Market Fundamentals') ? 'tabSelected' : 'tabNotSelected'}
          value="Market Fundamentals"
          onClick={(e) => setTab(e.target.value)}
        >
          Market Fundamentals

        </MyTab>
        <div className="vl" />
        <MyTab
          variant={(tab === 'Liquidity') ? 'contained' : 'text'}
          sx={(tab === 'Liquidity') ? selectedTabStyling : {}}
          className={(tab === 'Liquidity') ? 'tabSelected' : 'tabNotSelected'}
          value="Liquidity"
          onClick={(e) => setTab(e.target.value)}
        >
          Liquidity

        </MyTab>
        {/* <div className="vl" /> */}
        {/* <MyTab
          variant={(tab === 'News') ? 'contained' : 'text'}
          sx={(tab === 'News') ? selectedTabStyling : {}}
          className={(tab === 'News') ? 'tabSelected' : 'tabNotSelected'}
          value="News"
          onClick={(e) => setTab(e.target.value)}
        >
          News

        </MyTab>
        <div className="vl" />
        <MyTab
          variant={(tab === 'Network') ? 'contained' : 'text'}
          sx={(tab === 'Network') ? selectedTabStyling : {}}
          className={(tab === 'Network') ? 'tabSelected' : 'tabNotSelected'}
          value="Network"
          onClick={(e) => setTab(e.target.value)}
        >
          Network

        </MyTab> */}
      </div>
      <TabPanel sx={{ marginTop: '1em' }} />
      <Modal
        open={mapShow}
        onClose={() => setMapShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          variant="outlined"
          className="mainBody myModal"
          sx={{ borderRadius: '5px', padding: '15px' }}
        >
          <Typography variant="h6">
            <strong>
              Location of
              {' '}
              {regions.length === 0 ? '' : regions.filter((tempRegion) => tempRegion._id === selectedRegion)[0].region_name}
              ,
              {' '}
              {regions.length === 0 ? '' : regions.filter((tempRegion) => tempRegion._id === selectedRegion)[0].region_state}
            </strong>
          </Typography>

          <Paper
            variant="outlined"
            sx={{
              height: 'calc(100% - 60px)', padding: '1.25em', backgroundColor: '#eee', marginTop: '15px',
            }}
          >
            <Map
              {...viewport}
              mapStyle="mapbox://styles/neiljohnmathew/clj32h0nc02yw01qpc0z39at0"
              mapboxAccessToken="pk.eyJ1IjoibmVpbGpvaG5tYXRoZXciLCJhIjoiY2xqMmQzNjR4MGx4NjNsbzR5YTRnYXgzaSJ9.Iv8052Dwij0A00Iqi_j8GQ"
              onMove={(evt) => setViewport(evt.viewport)}
            >
              {centerCoordinatesForMap && (
              <Marker
                latitude={centerCoordinatesForMap[0]}
                longitude={centerCoordinatesForMap[1]}
              >
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: '#3ea2fa',
                    zIndex: 20,
                    color: 'black',
                    borderRadius: '50%',
                    border: '2px white solid',
                    position: 'relative',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                  }}
                />
              </Marker>
              )}

            </Map>
          </Paper>
        </Paper>
      </Modal>
    </div>
  );
}
