import React, { useContext } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { UserContext } from './UserContext';
import { axiosUpdateUserSavedProperties } from '../modules/api';

function PropertyBookmarkButton({ propertyID, sizeInPixels }) {
  const { userID, savedProperties, updateUserProfile } = useContext(UserContext);

  const DEFAULT_SIZE = '30px';

  function handleBookmarkClick(e) {
    // console.log('bookmark clicked');
    if (savedProperties.includes(propertyID)) {
      // Bookmark is on
      // console.log('bnookmark turning off');
      const newSavedProperties = savedProperties.filter((property) => property !== propertyID);
      axiosUpdateUserSavedProperties(userID, newSavedProperties).then(() => updateUserProfile());
    } else {
      // console.log('bookmark turning on');
      const newSavedProperties = [...savedProperties];
      newSavedProperties.push(propertyID);
      axiosUpdateUserSavedProperties(userID, newSavedProperties).then(() => updateUserProfile());
    }

    e.stopPropagation();
  }

  return (
    <Tooltip title="Save Property">
      <IconButton aria-label="favorite" onClick={(e) => handleBookmarkClick(e)} disabled={propertyID === undefined || propertyID === ''}>
        {savedProperties.includes(propertyID) ? <TurnedInIcon sx={{ color: '#b96332', fontSize: (!sizeInPixels ? DEFAULT_SIZE : sizeInPixels) }} /> : <TurnedInNotIcon sx={{ fontSize: (!sizeInPixels ? DEFAULT_SIZE : sizeInPixels) }} />}
      </IconButton>
    </Tooltip>
  );
}

export default PropertyBookmarkButton;
