/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import {
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoomIcon from '@mui/icons-material/Room';
// import BarChartIcon from '@mui/icons-material/BarChart';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { useNavigate } from 'react-router-dom';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CompareIcon from '@mui/icons-material/Compare';
import InsightsIcon from '@mui/icons-material/Insights';
import PublicIcon from '@mui/icons-material/Public';
import { UserContext } from './UserContext';

function AdvancedSideBar({ page }) {
  const navigate = useNavigate();
  const { savedMarkets, locationsPaidFor } = useContext(UserContext);
  const [expanded, setExpanded] = useState(['Explore', 'Countrywide', 'Comparison', 'Recommendations'].includes(page) ? 'marketsPanel' : null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme, pagename }) => ({
    backgroundColor:
        (pagename === page || (pagename === 'Markets' && (['Explore', 'Countrywide', 'Comparison', 'Recommendations'].includes(page))))
          ? '#eee'
          : '#fff',
    //   border: '1px solid rgba(0, 0, 0, .125)',
    // borderLeft: '1px solid rgba(0, 0, 0, .125)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: '#eee',
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: `0 0 0 ${theme.spacing(3)}`,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  return (
    <div className="Side">
      <Accordion expanded={expanded === 'marketsPanel'} onChange={handleChange('marketsPanel')} sx={{ border: '0px' }}>
        <AccordionSummary
          pagename="Markets"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <RoomIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
          <Typography sx={{ width: 'auto', flexShrink: 0 }}>
            {['Explore', 'Countrywide', 'Comparison', 'Recommendations'].includes(page) ? <b>Markets</b> : 'Markets'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionSummary
            pagename="Explore"
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => {
              if (savedMarkets.length === 0) {
                navigate('/explore/63e4478fe9a9a32574f1b0ac');
              } else {
                navigate(`/explore/${savedMarkets[0]}`);
              }
            }}
          >
            <ImageSearchIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
            <Typography sx={{ width: 'auto', flexShrink: 0 }}>
              {(page === 'Explore') ? <b>Explore</b> : 'Explore'}
            </Typography>
          </AccordionSummary>
          <AccordionSummary
            pagename="Countrywide"
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => navigate('/countrywide')}
          >
            <PublicIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
            <Typography sx={{ width: 'auto', flexShrink: 0 }}>
              {(page === 'Countrywide') ? <b>Countrywide</b> : 'Countrywide'}
            </Typography>
          </AccordionSummary>
          <AccordionSummary
            pagename="Comparison"
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => navigate('/comparison')}
          >
            <CompareIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
            <Typography sx={{ width: 'auto', flexShrink: 0 }}>
              {(page === 'Comparison') ? <b>Comparison</b> : 'Comparison'}
            </Typography>
          </AccordionSummary>
          <AccordionSummary
            pagename="Recommendations"
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => navigate('/recommendations')}
          >
            <InsightsIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
            <Typography sx={{ width: 'auto', flexShrink: 0 }}>
              {(page === 'Recommendations') ? <b>Recommendations</b> : 'Recommendations'}
            </Typography>
          </AccordionSummary>
        </AccordionDetails>
      </Accordion>
      <AccordionSummary
        pagename="Properties"
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        onClick={() => {
          if (locationsPaidFor.length === 0) {
            navigate('/properties/default');
          } else {
            navigate(`/properties/${locationsPaidFor[0]}`);
          }
        }}
      >
        <OtherHousesIcon sx={{ color: '#adabab', marginRight: '0.25em', transform: 'translateY(-1px)' }} />
        <Typography sx={{ width: 'auto', flexShrink: 0 }}>
          {(page === 'Properties') ? <b>Properties</b> : 'Properties'}
        </Typography>
      </AccordionSummary>
      <AccordionSummary
        pagename="Saved"
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        onClick={() => navigate('/saved')}
      >
        <TurnedInIcon sx={{ color: '#adabab', marginRight: '0.25em' }} />
        <Typography sx={{ width: 'auto', flexShrink: 0 }}>
          {(page === 'Saved') ? <b>Saved</b> : 'Saved'}
        </Typography>
      </AccordionSummary>
    </div>
  );
}

export default AdvancedSideBar;
