import axios from 'axios';

const rootURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5000': '';
const qs = require('qs');

// sailvest mailing list
export async function getEmailList() {
  try {
    const response = await axios.get(`${rootURL}/emails`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// sailvest mailing list
export async function addNewEmail(email) {
  try {
    const response = await axios.post(`${rootURL}/email`, email);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// sailvest website
export async function addNewUser(user) {
  try {
    const response = await axios.post(`${rootURL}/user`, user);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// // sailvest website
// export async function axiosRenameField() {
//   try {
//     const response = await axios.put(`${rootURL}/rename`);
//     return response;
//   } catch (err) {
//     return -1;
//   }
// }

// // sailvest website
// export async function axiosUpdateProperty(property) {
//   try {
//     const response = await axios.put(`${rootURL}/property/${property._id}`, property);
//     return response;
//   } catch (err) {
//     return -1;
//   }
// }

// sailvest website
export async function axiosUpdateRegionIDs(regionName) {
  try {
    const response = await axios.put(`${rootURL}/region`, { regionName });
    return response;
  } catch (err) {
    return -1;
  }
}

// export async function axiosAddProperty(property) {
//   try {
//     const response = await axios.post(`${rootURL}/property`, property);
//     return response;
//   } catch (err) {
//     return -1;
//   }
// }

export async function axiosAddRegionMetricRecords(newRecordsArray) {
  try {
    const response = await axios.post(`${rootURL}/loaddata`, newRecordsArray);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosSetMostRecentLogin(currUserId) {
  try {
    const response = await axios.put(`${rootURL}/setlogin`, { currUserId });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindRegionID(region) {
  try {
    const response = await axios.get(`${rootURL}/regionid/${region.region_name}/${region.region_state}`);
    return response.data.data;
  } catch (err) {
    return -1;
  }
}

export async function axiosAddRegion(region) {
  try {
    const response = await axios.post(`${rootURL}/region`, region);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosAddRegions(newRegions) {
  try {
    const response = await axios.post(`${rootURL}/regions`, newRegions);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosGetAllRegions() {
  try {
    const response = await axios.get(`${rootURL}/regions`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// export async function axiosDeleteProperty(id1) {
//   try {
//     const response = await axios.delete(`${rootURL}/property/${id1}`, { data: { id: id1 } });
//     return response;
//   } catch (err) {
//     return -1;
//   }
// }

export async function axiosDeleteCertainRecord() {
  try {
    const response = await axios.delete(`${rootURL}/region`, { data: { id: 'random' } });
    return response;
  } catch (err) {
    return -1;
  }
}

// sailvest website
export async function getAllUsers() {
  try {
    const response = await axios.get(`${rootURL}/users`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// sailvest website
export async function deleteUser(user) {
  try {
    const response = await axios.delete(`${rootURL}/user`, { data: user });
    return response;
  } catch (err) {
    return -1;
  }
}

// sailvest website
export async function getAllProperties() {
  try {
    const response = await axios.get(`${rootURL}/properties`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

// // sailvest website
// export async function updateCashFlows(propertyId, cashFlows) {
//   try {
//     const response = await axios.put(`${rootURL}/cashflows`, { propertyId, cashFlows });
//     return response;
//   } catch (err) {
//     return -1;
//   }
// }

export async function axiosFindRegionCoverage(metric, source) {
  try {
    const result = axios.get(`${rootURL}/regioncoverage/${metric}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindRegionRankingForMetric(metric, source) {
  try {
    const result = axios.get(`${rootURL}/regionranking/${metric}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

/**
 *
 * @param {String} RegionId region ID of region
 * @param Token needed to cancel API request
 * @returns {object} most recent data for region with specified region ID
 */
export async function axiosFindRegionRecord(regionId, source) {
  try {
    const result = axios.get(`${rootURL}/regionrecord/${regionId}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindHistoricalRegionData(regionId, metric, source) {
  try {
    const result = axios.get(`${rootURL}/regionrecord/${regionId}/${metric}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

// function to find average metrics at various region levels (county, state, country)
export async function axiosFindAverageRegionLevelData(regionId, metric, numYears, source) {
  try {
    const result = axios.get(`${rootURL}/averagemetricrecord/${regionId}/${metric}/${numYears}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

/**
 *
 * @param {String} propertyId  _ID of Property
 * @param Token needed to cancel API request
 * @returns {object} most recent data for region with specified region ID
 */
export async function axiosFindProperty(propertyId, source) {
  try {
    const result = axios.get(`${rootURL}/property/${propertyId}`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

// function to scale a metric for a given id (0 to 1) where 1 would be the highest in the nation
export async function axiosScaleMetricZeroToOne(regionId, metric, source) {
  try {
    const result = axios.get(`${rootURL}/scalemetric/${regionId}/${metric}/`, { cancelToken: source.token }).then((response) => response.data).catch((error) => {
      if (axios.isCancel(error)) {
        // Handling cancellation
        return [];
      }
      // Handling other errors
      return [];
    });
    return result;
  } catch (err) {
    return -1;
  }
}

/**
 *
 * @param {string} username
 * @returns object containing top markets based on username
 */
export async function getTopMarkets(username) {
  try {
    const response = await axios.get(`${rootURL}/topmarkets/${username}`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

/**
 *
 * @param {string} regionId
 * @returns object containing general info for specific region
 */
export async function findRegionGeneralInfo(regionId) {
  try {
    const response = await axios.get(`${rootURL}/region/${regionId}`);
    return response.data;
  } catch (err) {
    return -1;
  }
}

//
export async function updateInvestorProfile(investorProfile) {
  try {
    const response = await axios.put(`${rootURL}/users`, investorProfile);
    return response;
  } catch (err) {
    return -1;
  }
}

//
export async function getInvestorProfile(userId) {
  try {
    const response = await axios.get(`${rootURL}/users/${userId}`);
    return response;
  } catch (err) {
    return -1;
  }
}

//
export async function getNationalAverageForMetric(metric) {
  try {
    const response = await axios.get(`${rootURL}/average/${metric}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindUsersWithGivenEmail(email) {
  try {
    const response = await axios.get(`${rootURL}/useremails/${email}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindUsersWithGivenUsername(username) {
  try {
    const response = await axios.get(`${rootURL}/username/${username}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosSendResetCodeEmail(email, subject, OTP) {
  try {
    const response = await axios.post(`${rootURL}/send_recovery_email`, {
      OTP,
      recipientEmail: email,
      emailSubject: subject,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosUpdateUserPassword(userEmail, userPassword) {
  try {
    const response = await axios.post(`${rootURL}/update_password`, {
      email: userEmail,
      password: userPassword,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosUpdateUserSubscriptionDB(userEmail, userSubscription) {
  try {
    const response = await axios.post(`${rootURL}/update_subscription_db`, {
      email: userEmail,
      subscription: userSubscription,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosUpdateUserSavedMarkets(userID, savedMarkets) {
  try {
    const response = await axios.post(`${rootURL}/update_saved_markets`, {
      userID,
      savedMarkets,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosUpdateUserSavedProperties(userID, savedProperties) {
  try {
    const response = await axios.post(`${rootURL}/update_saved_properties`, {
      userID,
      savedProperties,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosGetAllCities() {
  try {
    const response = await axios.get(`${rootURL}/cities`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosGetCity(cityID) {
  try {
    const response = await axios.get(`${rootURL}/city/${cityID}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosGetSomeCities(cityIDArray) {
  try {
    const response = await axios.get(`${rootURL}/somecities`, {
      params: {
        cityIDArray,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosGetSomeProperties(propertyIDArray) {
  try {
    const response = await axios.get(`${rootURL}/some_properties`, {
      params: {
        propertyIDArray,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosFindCitiesWithinCounty(regionID) {
  try {
    const response = await axios.get(`${rootURL}/cities_within_county/${regionID}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosUnsubscribeLocation(userID, cityID) {
  try {
    const response = await axios.post(`${rootURL}/unsubscribe_city`, {
      userID,
      cityID,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosSubscribeLocation(userID, cityID, userArray) {
  try {
    const response = await axios.post(`${rootURL}/subscribe_city`, {
      userID,
      cityID,
      userArray,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

export async function axiosSetCurrentAndNextPaidLocations(userID, cityIDArray) {
  try {
    const response = await axios.post(`${rootURL}/set_paid_locations`, {
      userID,
      cityIDArray,
    });
    return response;
  } catch (err) {
    return -1;
  }
}

// STRIPE functions

export async function getPayingCustomers() {
  try {
    const response = await axios.get(`${rootURL}/paying_customers`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function getComplimentaryCustomers() {
  try {
    const response = await axios.get(`${rootURL}/complimentary_customers`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function getSubscriptions() {
  try {
    const response = await axios.get(`${rootURL}/subscriptions`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function getSubscriptionByUserEmail(userEmail) {
  try {
    const response = await axios.get(`${rootURL}/subscription/${userEmail}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function cancelSubscription(subscriptionId) {
  try {
    const response = await axios.delete(`${rootURL}/cancel_subscription/${subscriptionId}`);
    return response;
  } catch (err) {
    return -1;
  }
}

export async function changeTierStripe(subscriptionId, newTier) {
  try {
    const response = await axios.put(`${rootURL}/change_tier/${subscriptionId}/${newTier}`);
    console.log(response);
    return response;
  } catch (err) {
    return -1;
  }
}
