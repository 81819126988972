/* eslint-disable react/jsx-props-no-spreading */
// React
import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';

// MUI
import {
  Typography, Alert, Button, Stepper, Box, Step, StepLabel, IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// Components
import ToolBar from './toolBar';
import AdvancedSideBar from './advancedSideBar';
import InsightsPage from './insightsPage';

// Database functions
import InvestorProfile from './investorProfile';
import { UserContext } from './UserContext';

export default function RecommendationsPage() {
  const [eligibleForRecommendations, setEligibleForRecommendations] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { subscriptionTier } = useContext(UserContext);

  const navigate = useNavigate();

  // Show stepper
  const TabPanel = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <InvestorProfile />;
      case 1:
        return <InsightsPage />;
      default:
        return <InsightsPage />;
    }
  }, [activeStep]);

  // Check if user is eligible for recommendations
  useEffect(() => {
    if (subscriptionTier === 'Premium' || subscriptionTier === 'Enterprise' || subscriptionTier === 'Unlimited') {
      setEligibleForRecommendations(true);
    } else {
      setEligibleForRecommendations(false);
    }
  }, [subscriptionTier]);

  const steps = ['Input investment preferences', 'View personalized recommendations'];

  const useStyles = makeStyles({
    clickableElement: {
      cursor: 'pointer',
      // Add other styles to indicate that it's clickable, like changing the color, etc.
    },
  });

  const classes = useStyles();

  // Navigates to Stripe payment link
  // const goToPaymentLink = async () => {
  //   // the link prefills the userEmail
  //   // window.location.replace(`https://buy.stripe.com/9AQ7uG9Mn5k94Y8dQQ?prefilled_email=${userEmail}`);
  // };

  // Checks if the user is a Premium subscriber
  // async function checkSubscription(username) {
  //   const result = await getPayingCustomers();

  //   const customerList = result.data.data;
  //   /* eslint-disable */
  //     const customerEmails = customerList.map((customer) => { return customer.email; });

  //     const resultCheckByUsername = await axiosFindUsersWithGivenUsername(username);

  //     let tempUserEmail = resultCheckByUsername.data[0].email;
  //     setUserEmail(tempUserEmail);
  //     let complimentaryUsers = await getComplimentaryCustomers();
  //     complimentaryUsers = complimentaryUsers.data.data;

  //     if (customerEmails.includes(tempUserEmail) || complimentaryUsers.includes(username)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  // }

  // return this if paidUser hasn't loaded
  if (eligibleForRecommendations === null) {
    return (
      <div>
        <ToolBar isLoggedIn />
        <div className="Main">
          <AdvancedSideBar page="Recommendations" />
          <div className="RightSide" style={{ position: 'relative', height: '80vh' }}>
            <Typography variant="h3" className="mainBody"><strong>Recommendations</strong></Typography>
          </div>
        </div>
      </div>
    );
  }

  // To display if user doesn't pay for Premium
  if (!eligibleForRecommendations) {
    return (
      <div>
        <ToolBar isLoggedIn />
        <div className="Main">
          <AdvancedSideBar page="Recommendations" />
          <div className="RightSide" style={{ position: 'relative', height: '80vh' }}>
            <Typography variant="h3" className="mainBody"><strong>Recommendations</strong></Typography>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Alert
                sx={{
                  backgroundColor: '#6530a2',
                  border: '2px solid white',
                  color: '#eee',
                }}
                icon={<LockIcon fontSize="inherit" sx={{ color: '#eee' }} />}
                action={(
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/account')}
                    sx={{
                      marginLeft: '25px',
                      backgroundColor: '#eee',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#eee',
                        color: '#000000',
                        transform: 'scale(1.05)',
                      },
                    }}
                    size="small"
                  >
                    Account
                  </Button>
          )}
              >
                Upgrade your subscription tier to Premium or above to
                view personalized recommendations.
              </Alert>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ToolBar isLoggedIn />
      <div className="Main">
        <AdvancedSideBar page="Recommendations" />
        <div className="RightSide">
          <Typography variant="h3" className="mainBody"><strong>Recommendations</strong></Typography>
          <div className="mainBody">
            <Box sx={{ width: '100%', marginBottom: '15px' }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step
                      {...stepProps}
                      key={label}
                      className={classes.clickableElement}
                      onClick={() => { setActiveStep(index); }}
                    >
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', transform: 'translate(50%, -70px)', left: '50%',
            }}
            >
              <IconButton
                color="primary"
                disabled={(activeStep === 0)}
                onClick={() => setActiveStep(0)}
              >
                <ArrowBackIosIcon />

              </IconButton>
              <IconButton
                color="primary"
                disabled={(activeStep === 1)}
                onClick={() => setActiveStep(1)}
              >
                <ArrowForwardIosIcon />

              </IconButton>
            </div>
            <TabPanel />
          </div>
        </div>
      </div>
    </div>
  );
}
