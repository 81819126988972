import React from 'react';
import '../assets/landing.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import ToolBar from './toolBar';

export default function ErrorPage() {
  return (
    <div className="Landing">
      <ToolBar />
      <div style={{ marginTop: '15%' }}>
        <strong>
          Error
        </strong>
      </div>
      <div style={{ fontSize: 'calc(10px + 2vmin)' }}>
        Page Not Found
      </div>
    </div>
  );
}
