import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ToolBar from './toolBar';
import '../assets/login.css';
import { logIn } from '../modules/accounts';
import { hash } from '../modules/hash';
import {
  getAllUsers,
} from '../modules/api';

export default function Recovered() {
  const paperStyle = {
    padding: '20px',
    width: '20%',
    margin: '20px auto',
    justifyContent: 'center',
    flex: 'column',
  };

  let userId;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const users = React.useRef([]);
  const [loginShow, setLoginShow] = useState(false);
  const [loginText, setLoginText] = useState('');

  async function fetchUsers() {
    try {
      const val = await getAllUsers();
      users.current = val.data;
      return val;
    } catch (e) {
      return e;
    }
  }

  function validateLogin() {
    let i;
    for (i = users.current.length - 1; i >= 0; i -= 1) {
      if (users.current[i].username === username) {
        if (users.current[i].password === hash(password)) {
          userId = users.current[i]._id;
          return true;
        }
        setLoginText('Password doesn\'t match username');
        setLoginShow(true);
        return false;
      }
    }
    setLoginText('Invalid username');
    setLoginShow(true);
    return false;
  }

  async function handleLogin() {
    if (validateLogin()) {
      logIn(username, userId);
      window.location.href = '/account';
    }
  }

  const tripleLoginCall = async () => {
    await fetchUsers();
    await handleLogin();
  };

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { margin: '5px 0', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Grid style={{ marginTop: '15%', width: '100vw' }}>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>Testing: Recovered</h2>
          </Grid>
          <TextField
            label="Username"
            // placeholder="Enter username"
            style={textFieldStyle}
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            // placeholder="Enter password"
            style={textFieldStyle}
            type="password"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            onClick={tripleLoginCall}
          >
            Sign in
          </Button>
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Collapse in={loginShow}>
              <Alert
                severity="error"
                style={{ marginTop: '10px' }}
                action={
                  (
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setLoginShow(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                sx={{ mb: 2 }}
              >
                {loginText}
              </Alert>
            </Collapse>
          </Stack>
        </Paper>
      </Grid>
    </div>
  );
}
