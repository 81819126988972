/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ToolBar from './toolBar';
import { hash } from '../modules/hash';
import '../assets/login.css';
import { RecoveryContext } from './loginPage';
import { axiosUpdateUserPassword } from '../modules/api';

export default function Reset() {
  const { email, setPage } = useContext(RecoveryContext);

  const paperStyle = {
    padding: '20px',
    width: '20%',
    minWidth: '300px',
    margin: '20px auto',
    justifyContent: 'center',
    flex: 'column',
  };

  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  function validateNewPassword() {
    if (password.length <= 7
        || password.toLowerCase() === password
        || password.toUpperCase() === password) {
      alert('Password doesn\'t meet requirements ( More than 7 characters and contains upper and lowercase )');
      return false;
    }
    if (password !== secondPassword) {
      alert('Inputted passwords do not match');
      return false;
    }
    return true;
  }

  const avatarStyle = { backgroundColor: '#000000', margin: '10px 0' };
  const btnstyle = { margin: '5px 0', backgroundColor: 'black' };
  const textFieldStyle = { margin: '7.5px 0' };

  const handlePasswordUpdate = () => {
    if (!validateNewPassword()) {
      alert('Inputted password is invalid.');
    } else {
      axiosUpdateUserPassword(email, hash(password)).then(() => {
        alert('Password successfully updated');
        setPage('login');
      }).catch(() => {
        console.log('Password could not be updated. Plase try again later.');
      });
    }
  };

  return (
    <div className="LoginNew">
      <ToolBar />
      <Grid style={{ marginTop: '15%', width: '100vw' }}>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" sx={{ marginBottom: '2em' }}>
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <h2>Reset Password</h2>
          </Grid>
          <TextField
            label="Password"
            placeholder="Enter password"
            style={textFieldStyle}
            type="password"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            placeholder="Confirm password"
            style={textFieldStyle}
            type="password"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={secondPassword}
            onChange={(e) => setSecondPassword(e.target.value)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            onClick={handlePasswordUpdate}
          >
            Update Password
          </Button>
        </Paper>
      </Grid>
    </div>
  );
}
