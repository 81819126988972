/* eslint-disable max-len */
import React from 'react';
import {
  Card, CardActionArea, CardContent, Typography, Chip,
} from '@mui/material';
import '../assets/properties.css';
import { useNavigate } from 'react-router-dom';

import PropertyBookmarkButton from './propertyBookmarkButton';

export default function PropertyCard({ property }) {
  const navigate = useNavigate();
  // console.log(property);
  const handleCardClick = () => {
    navigate(`/property/${property._id}`);
  };

  return (
    <Card elevation={3} sx={{ borderRadius: '10px', height: '100%', position: 'relative' }}>
      <CardActionArea onClick={handleCardClick} sx={{ height: '100%' }}>
        <div className="propertyImage" style={{ backgroundImage: `url(${property.listing_image})` }} />
        <CardContent sx={{ height: '100%' }}>
          <div className=" sideBySideSpacedOut" style={{ flexWrap: 'wrap' }}>
            <div>
              <div className="centeredRow">
                <Typography component="div" sx={{ fontSize: '1.5em' }}>
                  <b>
                    {property.house_number}
                    {' '}
                    {property.street}
                  </b>
                </Typography>
                <PropertyBookmarkButton propertyID={property._id} sizeInPixels={20} />
              </div>
              <Typography color="#1d537f" component="div" sx={{ fontSize: '1.25em' }}>
                {property.town}
                ,
                {' '}
                {property.state}
              </Typography>
              <Typography color="#585858" component="div" sx={{ fontSize: '1.25em', marginBottom: '10px' }}>
                {property.zip_code}
              </Typography>
            </div>
          </div>
          <div style={{ textAlign: 'left', marginBottom: '45px' }}>
            <Typography variant="overline">
              <b>{property.beds}</b>
              {' '}
              bed
              {' | '}
              <b>{property.baths}</b>
              {' '}
              bath
              {' | '}
              <b>{property.size ? property.size : '---'}</b>
              {' '}
              sqft
            </Typography>
            <div className="centeredRow">
              <Typography variant="subtitle2" sx={{ marginTop: '10px' }}>
                Monthly Rent:
                {' '}
                <b>{`$${(property.rent_zestimate).toLocaleString('en-us')}`}</b>
              </Typography>
            </div>
            {/* <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
            <div>
              <div className="centeredRow">
                <Typography sx={{ fontSize: '1.25em' }} color="#318923">
                  <b>27.12%</b>
                </Typography>
                <Typography color="#636262" sx={{ fontSize: '1em', marginLeft: '5px' }}><em>Cap Rate</em></Typography>
              </div>
              <div className="centeredRow">
                <Typography sx={{ fontSize: '1.25em' }} color="#318923">
                  <b>5.12%</b>
                </Typography>
                <Typography color="#636262" sx={{ fontSize: '1em', marginLeft: '5px' }}><em>1st Yr IRR</em></Typography>
              </div>
              <div className="centeredRow">
                <Typography sx={{ fontSize: '1.25em' }} color="#318923">
                  <b>3.78%</b>
                </Typography>
                <Typography color="#636262" sx={{ fontSize: '1em', marginLeft: '5px' }}><em>1st Yr COC</em></Typography>
              </div>
            </div> */}
            {/* <Typography variant="subtitle2" color="#636262">
              <em>
                Cap Rate:
                {' '}
                {property.beds}
              </em>
            </Typography>
            <Typography variant="subtitle2" color="#636262">
              <em>
                1st Yr IRR:
                {' '}
                {property.baths}
              </em>
            </Typography>
            <Typography variant="subtitle2" color="#636262">
              <em>
                1st Yr CoC:
                {' '}
                {property.size ? property.size : 'N/A'}
              </em>

            </Typography> */}
          </div>
          <div style={{
            textAlign: 'right', position: 'absolute', bottom: '15px', right: '15px',
          }}
          >
            <Chip
              label={`$${(property.listing_price).toLocaleString('en-us')}`}
              sx={{
                fontSize: '1.5em', padding: '0.75em 0.5em 0.75em 0.5em',
              }}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
