import React, { useContext } from 'react';
import { Paper, Typography } from '@mui/material';
import { ResearchContext } from './ResearchContext';

function SMVTitleCard({ regionRecord }) {
  const { regions } = useContext(ResearchContext);
  const regionName = (regionRecord !== undefined && regionRecord !== '' && regions !== undefined && regions.length !== 0) ? (regions.filter((region) => region._id === regionRecord.region_id))[0].region_name : '...';
  const regionState = (regionRecord !== undefined && regionRecord !== '' && regions !== undefined && regions.length !== 0) ? (regions.filter((region) => region._id === regionRecord.region_id))[0].region_state : '...';
  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: '10px', padding: '1em', height: '100%', textAlign: 'center',
      }}
    >
      <Typography variant="h5">
        <b>
          {regionName}
        </b>
      </Typography>
      <Typography variant="h6" color="primary">
        <b>
          {regionState}
        </b>
      </Typography>
    </Paper>
  );
}

export default SMVTitleCard;
