/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Pie, Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { AxisBottom, AxisLeft } from '@visx/axis';
// import { GradientPinkBlue } from '@visx/gradient';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';

const coins = [
  {
    symbol: 'ADA', amount: 200, color: '#0033ad', inUSD: 1.48,
  },
  {
    symbol: 'SOL', amount: 5, color: '#00ffbd', inUSD: 37.6,
  },
  {
    symbol: 'BTC', amount: 0.005, color: '#F7931A', inUSD: 37363,
  },
];

const barChartData = [
  {
    regionLevel: 'County',
    growthValue: 5,
  },
  {
    regionLevel: 'State',
    growthValue: 17,
  },
  {
    regionLevel: 'National',
    growthValue: 13,
  },
];

const xAccessor = (d) => d.regionLevel;
const yAccessor = (d) => d.growthValue;

// Adding the function within the Pie isn't technically necessary but it helps you modify lower
// level attributes of Pie Chart (like color of individual arcs, for example)

function network() {
  const [active, setActive] = useState();

  const verticalMargin = 150;
  const horizontalMargin = 175;

  const width = 400;
  const half = width / 2;

  const barChartWidth = 800;
  const barChartHeight = 600;

  const barChartXMax = barChartWidth - horizontalMargin;
  const barChartYMax = barChartHeight - verticalMargin;

  // scales, memoize for performance
  const barChartXScale = scaleBand({
    domain: barChartData.map(xAccessor),
    range: [0, barChartXMax],
    round: true,
    padding: 0.2,
  });

  const barChartYScale = scaleLinear({
    domain: [0, Math.max(...barChartData.map(yAccessor))],
    range: [barChartYMax, 0],
    round: true,
  });

  const colorScale = scaleOrdinal({
    domain: barChartData.map(xAccessor),
    range: ['#ff9543', '#7c1ba6', '#073264'],
  });

  return (
    <main>
      <svg width={width} height={width}>

        <Group top={half} left={half}>
          <Pie
            data={coins}
            pieValue={(data) => data.amount * data.inUSD}
            outerRadius={half}
            innerRadius={({ data }) => {
              const gurth = active && active.symbol === data.symbol ? 12 : 8;
              return half - gurth;
            }}
            padAngle={0.01}
          >
            {(pie) => pie.arcs.map(
              (arc) => (
                <g
                  key={arc.data.symbol}
                  onMouseEnter={() => setActive(arc.data)}
                  onMouseLeave={() => setActive(null)}
                >
                  <path d={pie.path(arc)} fill={arc.data.color} />
                </g>
              ),
            )}
          </Pie>
          {active ? (
            <>
              <Text
                textAnchor="middle"
                fill="#000"
                fontSize={40}
                dy={-20}
              >
                {`$${Math.floor(active.amount * active.inUSD)}`}

              </Text>
              <Text
                textAnchor="middle"
                fill={active.color}
                fontSize={20}
                dy={20}
              >
                {`${active.amount} ${active.symbol}`}

              </Text>
            </>
          ) : (
            <>
              <Text
                textAnchor="middle"
                fill="#000"
                fontSize={40}
                dy={-20}
              >
                {`$${Math.floor(coins.reduce((acc, coin) => acc + coin.amount * coin.inUSD, 0))}`}

              </Text>
              <Text
                textAnchor="middle"
                fill="#aaa"
                fontSize={20}
                dy={20}
              >
                {`${coins.length} Assets`}

              </Text>
            </>
          )}

        </Group>
      </svg>
      <svg width={barChartWidth} height={barChartHeight}>
        {/* <GradientPinkBlue id="bar-chart-background" /> */}
        <rect fill="#b9b6b6" width={barChartWidth} height={barChartHeight} rx={14} />
        <Group top={verticalMargin / 2} left={horizontalMargin / 2}>
          {barChartData.map((d) => {
            const regionLevel = xAccessor(d);
            const growthValue = yAccessor(d);
            const barWidth = barChartXScale.bandwidth();
            // We do 'YMax -' because we reversed our y scale
            const barHeight = barChartYMax - barChartYScale(growthValue);
            const barX = barChartXScale(regionLevel);
            const barY = barChartYMax - barHeight;
            return (
              <Bar
                key={`bar-${regionLevel}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={colorScale(regionLevel)}
              />
            );
          })}
          <AxisLeft
            label="Growth"
            scale={barChartYScale}
            tickFormat={(value) => `${value} %`}
            stroke="#000000"
            tickStroke="#000000"
            labelProps={{
              fill: '#515151',
              fontSize: 16,
              textAnchor: 'middle',
              dx: '-1em',
            }}
            tickLabelProps={() => ({
              fill: '#515151',
              fontSize: 16,
              textAnchor: 'end',
            })}
          />
          <AxisBottom
            label="Region Level"
            hideTicks
            top={barChartYMax}
            scale={barChartXScale}
            stroke="#000000"
            tickStroke="#000000"
            labelProps={{
              fill: '#515151',
              fontSize: 16,
              textAnchor: 'middle',
              dy: '0.33em',
            }}
            tickLabelProps={() => ({
              fill: '#515151',
              fontSize: 16,
              textAnchor: 'middle',
            })}
          />
        </Group>
      </svg>
    </main>
  );
}

export default network;
