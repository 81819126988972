/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import * as allCurves from '@visx/curve';
import { Group } from '@visx/group';
import { LinePath, Line } from '@visx/shape';
import { scaleUtc, scaleLinear, scaleOrdinal } from '@visx/scale';
import {
  MarkerArrow, MarkerCircle,
} from '@visx/marker';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
// import { LinearGradient } from '@visx/gradient';
import { CircularProgress, Typography, Box } from '@mui/material';
import { GridColumns } from '@visx/grid';
import {
  useTooltip,
  useTooltipInPortal,
  defaultStyles,
  TooltipWithBounds,
  Tooltip,
} from '@visx/tooltip';
import {
  extent, min, max, bisector,
} from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { allMetrics } from '../utils/allMetrics';

const curveType = 'curveCardinal';
const markerCircleRadius = 1;
// const fromGradient = '#fff';
// const toGradient = '#fff';
const accentColor = '#545d65';
const accentColorDark = '#626973';

// Defining mock data
// const numPoints = 10;
// const seriesA = new Array(numPoints).fill(null)
//   .map((_, i) => ({ date: i, value: Math.floor(Math.random() * 50) }));
// const seriesB = new Array(numPoints).fill(null)
//   .map((_, i) => ({ date: i, value: Math.floor(Math.random() * 25) }));

export default function CustomLineChart({ seriesArray, nice }) {
  const seriesAData = seriesArray[0].data;
  const seriesAMetric = seriesArray[0].metric;

  if (seriesArray === undefined
    || seriesAData === undefined
    || seriesArray.length === 0
    || seriesAData[0][seriesAMetric] === undefined) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: 100,
        height: '90%',
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ParentSize>
      {({ width, height }) => (
        <Example
          allSeries={seriesArray}
          width={width}
          height={height}
          nice={nice}
        />
      )}
    </ParentSize>
  );
}

function Example({
  width, height, allSeries, nice,
}) {
  // Define svg constants and state variables
  const [showPoints, setShowPoints] = useState(allSeries[0].metric !== 'fed_funds_rate');
  const svgHeight = height;
  const svgWidth = width;

  const seriesA = allSeries[0].data;
  const seriesB = (allSeries[1] === undefined) ? undefined : allSeries[1].data;

  // Util
  const formatDate = timeFormat('%m-%d-%Y');

  // Defining data accessors
  const getX = (d) => {
    const date = new Date(d.period_end);
    date.setUTCHours(23, 59, 59, 999);
    return date;
  };

  // Defining domain of scales
  const seriesXScale = scaleUtc({
    domain: (allSeries[1] === undefined)
      ? extent(seriesA, getX)
      : extent([...seriesA, ...seriesB], getX),
  });
  const seriesAYScale = scaleLinear({
    domain: [nice
      ? min(seriesA, allSeries[0].yAccessor) - 0.05 * Math.abs(min(seriesA, allSeries[0].yAccessor))
      : 0, max(seriesA, allSeries[0].yAccessor)],
  });
  const seriesBYScale = (allSeries[1] === undefined) ? undefined : scaleLinear({
    domain: [nice
      ? min(seriesB, allSeries[1].yAccessor) - 0.05 * Math.abs(min(seriesB, allSeries[1].yAccessor))
      : 0, max(seriesB, allSeries[1].yAccessor)],
  });

  const colorScale = scaleOrdinal({
    domain: allSeries,
    range: ['#5089c7', '#743998'],
  });

  let axisLeftSpace = 65;
  const additionalAxisLeftPadding = max(seriesA, allSeries[0].yAccessor) === undefined ? 0
    : (Math.floor(max(seriesA, allSeries[0].yAccessor)).toString().length - 2) * 10;

  axisLeftSpace += additionalAxisLeftPadding;
  let axisRightSpace = (allSeries[1] === undefined) ? 35 : 65;
  const additionalAxisRightPadding = (allSeries[1] === undefined) ? 0
    : max(seriesB, allSeries[1].yAccessor) === undefined ? 0
      : (Math.floor(max(seriesB, allSeries[1].yAccessor)).toString().length - 2) * 10;
  axisRightSpace += additionalAxisRightPadding;
  const axisBottomSpace = 75;
  const axisTopSpace = 35;

  // Get variables to be able to use tooltip in portal without any z-index issues
  const { containerRef, containerBounds } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  // Create tooltip functions and attributes for you to use
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip();

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: '#4b4b4b',
    borderRadius: '5px',
    color: 'white',
    opacity: 1,
    width: 'auto',
    height: 'auto',
    padding: 12,
  };

  // Can be set to TooltipPortal as well
  const TooltipComponent = TooltipWithBounds;

  const bisectXValue = bisector((d) => getX(d)).left;

  // event handlers
  const handlePointerMove = useCallback(
    (event) => {
    // coordinates should be relative to the container in which Tooltip is rendered
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      if (containerX >= axisLeftSpace && containerX <= (svgWidth - axisRightSpace) + 25) {
        const x0 = seriesXScale.invert(containerX);

        const seriesAIndex = bisectXValue(seriesA, x0, 1);
        const seriesBIndex = (allSeries[1] === undefined) ? undefined
          : bisectXValue(seriesB, x0, 1);

        // These are data points (objects)
        const prevSeriesADataPoint = seriesA[seriesAIndex - 1];
        let d;
        if (seriesBIndex === undefined) {
          d = prevSeriesADataPoint;
        } else {
          const prevSeriesBDataPoint = seriesB[seriesBIndex - 1];

          d = Math.abs(containerY
          - seriesAYScale(allSeries[0].yAccessor(prevSeriesADataPoint)).valueOf())
          <= Math.abs(seriesBYScale(allSeries[1].yAccessor(prevSeriesBDataPoint)).valueOf()
          - containerY)
            ? prevSeriesADataPoint
            : prevSeriesBDataPoint;
        }

        showTooltip({
          tooltipLeft: {
            nearestX: seriesXScale(getX(d)),
            actualX: containerX,
          },
          tooltipTop: {
            nearestY: (d === prevSeriesADataPoint)
              ? seriesAYScale(allSeries[0].yAccessor(d)) : seriesBYScale(allSeries[1].yAccessor(d)),
            actualY: containerY,
          },
          tooltipData: d,
        });
      } else {
        hideTooltip();
      }
    },
    [seriesA, seriesB, showTooltip, containerBounds],
  );

  // update scale output ranges
  seriesXScale.range([axisLeftSpace, width - axisRightSpace]);
  seriesAYScale.range([svgHeight - axisBottomSpace, axisTopSpace]);
  if (allSeries[1] !== undefined) {
    seriesBYScale.range([svgHeight - axisBottomSpace, axisTopSpace]);
  }

  return (
    <div
      style={{ position: 'relative' }}
    >
      {/* This HTML part is for showing the dropdown and the checkbox */}
      <label style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        fontSize: '16px',
        fontFamily: 'Noto Sans',
      }}
      >
        Show points&nbsp;
        <input
          type="checkbox"
          checked={showPoints}
          onChange={() => setShowPoints(!showPoints)}
        />
      </label>
      <svg
        width={svgWidth}
        height={svgHeight}
        ref={containerRef}
        onPointerMove={handlePointerMove}
        onMouseOut={hideTooltip}
      >
        <MarkerCircle id="marker-circle" fill="#000" size={markerCircleRadius} refX={2} />
        <MarkerArrow id="marker-arrow" fill="#000" size={6} refX={2} />
        {/* <LinearGradient id="area-background-gradient" from={fromGradient} to={toGradient} /> */}
        <rect
          width={svgWidth}
          height={svgHeight}
          fill="transparent"
          rx={14}
          ry={14}
        />
        <GridColumns
          top={axisTopSpace}
          scale={seriesXScale}
          height={svgHeight - axisTopSpace - axisBottomSpace}
          // strokeDasharray="1,1"
          stroke={accentColor}
          strokeWidth={2}
          strokeOpacity={0.2}
          pointerEvents="none"
        />
        <AxisLeft
          left={axisLeftSpace}
          scale={seriesAYScale}
          hideZero
          stroke="#000"
          strokeWidth={2}
          label={allMetrics[allSeries[0].metric].display_name}
          tickLabelProps={() => ({
            fontSize: 15,
            dy: 5,
            dx: -5,
            textAnchor: 'end',
          })}
          labelOffset={axisLeftSpace - 25}
          labelProps={{
            fontSize: 15,
            fontWeight: 'bold',
            fontFamily: 'Noto Sans',
            textAnchor: 'middle',
          }}
        />
        {
          (allSeries.length > 1) && (
          <AxisRight
            left={svgWidth - axisRightSpace}
            hideZero
            scale={seriesBYScale}
            stroke="#000"
            strokeWidth={2}
            label={allMetrics[allSeries[1].metric].display_name}
            tickLabelProps={() => ({
              fontSize: 15,
              dy: 5,
              dx: 5,
              textAnchor: 'start',
            })}
            labelOffset={axisRightSpace - 25}
            labelProps={{
              fontSize: 15,
              fontWeight: 'bold',
              fontFamily: 'Noto Sans',
              textAnchor: 'middle',
            }}
          />
          )
        }

        <AxisBottom
          top={svgHeight - axisBottomSpace}
          hideZero
          scale={seriesXScale}
          stroke="#000"
          numTicks={Math.floor(svgWidth / 150)}
          strokeWidth={2}
          label="Date"
          tickFormat={formatDate}
          tickLabelProps={() => ({
            fontSize: 15,
            dy: 5,
            textAnchor: 'middle',
          })}
          labelOffset={20}
          labelProps={{
            fontSize: 15,
            fontWeight: 'bold',
            fontFamily: 'Noto Sans',
            textAnchor: 'middle',
          }}
        />
        {width > 8
          && allSeries.map((tempSeriesInfo, i) => {
            const markerStart = 'url(#marker-circle)';
            const markerMiddle = 'url(#marker-circle)';
            const markerEnd = 'url(#marker-circle)';

            return (
              <Group
                key={`lines-${i}`}
                top={0}
                left={0}
              >
                <LinePath
                  curve={allCurves[curveType]}
                  data={tempSeriesInfo.data}
                  x={(d) => seriesXScale(getX(d)) ?? 0}
                  y={(d) => {
                    if (i === 0) {
                      return seriesAYScale(tempSeriesInfo.yAccessor(d)) ?? 0;
                    }
                    return seriesBYScale(tempSeriesInfo.yAccessor(d)) ?? 0;
                  }}
                  stroke={colorScale(tempSeriesInfo)}
                  strokeWidth={3}
                  strokeOpacity={1}
                  shapeRendering="geometricPrecision"
                  markerMid={showPoints ? markerMiddle : undefined}
                  markerStart={showPoints ? markerStart : undefined}
                  markerEnd={showPoints ? markerEnd : undefined}
                />
              </Group>
            );
          })}
        {tooltipOpen && tooltipData && (
        <g>
          <Line
            from={{ x: tooltipLeft.actualX, y: 35 }}
            to={{ x: tooltipLeft.actualX, y: svgHeight - axisBottomSpace }}
            stroke={accentColorDark}
            strokeWidth={2}
            pointerEvents="none"
            opacity={0.3}
            // strokeDasharray="5,2"
          />
          <circle
            cx={tooltipLeft.nearestX}
            cy={tooltipTop.nearestY}
            r={5}
            fill="#b96332"
            fillOpacity={1}
            stroke="white"
            strokeOpacity={1}
            strokeWidth={2}
            pointerEvents="none"
          />
        </g>
        )}
      </svg>
      {tooltipOpen && (
        <TooltipComponent
          // set this to random so it correctly updates with parent bounds
          style={tooltipStyles}
          key={Math.random()}
          top={tooltipTop.actualY}
          left={tooltipLeft.actualX}
        >
          <Typography variant="body">
            <b>
              {(seriesA.includes(tooltipData))
                ? allMetrics[allSeries[0].metric].display_name
                : allMetrics[allSeries[1].metric].display_name}
              {}
              :
              {' '}
            </b>
            {(seriesA.includes(tooltipData))
              ? allSeries[0].yAccessor(tooltipData)
              : allSeries[1].yAccessor(tooltipData)}
          </Typography>
        </TooltipComponent>
      )}
      {tooltipOpen && (
        <Tooltip
          // set this to random so it correctly updates with parent bounds
          style={{
            ...defaultStyles,
            background: '#4b4b4b',
            color: '#ffffff',
            fontSize: '15px',
            minWidth: 100,
            textAlign: 'center',
            transform: 'translate(-50%, -150%)',
          }}
          key={Math.random()}
          top={axisTopSpace}
          left={tooltipLeft.actualX - 10}
        >
          {formatDate(getX(tooltipData))}
        </Tooltip>
      )}
    </div>
  );
}
