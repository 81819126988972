/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Paper, Typography, FormControl, InputLabel, Select, MenuItem, Box,
} from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import axios from 'axios';
import { allMetrics } from '../utils/allMetrics';
import { generateCorrectTimelineData } from '../utils/explorePageFunctions';
import CustomLineChart from './customLineChart';
import { axiosFindHistoricalRegionData } from '../modules/api';

const moment = require('moment');

export default function MetricGrowthCard({ metric, regionRecord, setHistoricalData }) {
  // State variables
  const [timeline, setTimeline] = useState('max');
  const [historicalRegionMetricData, setHistoricalRegionMetricData] = useState([]);
  const [historicalRegionMetricData2, setHistoricalRegionMetricData2] = useState([]);

  // Formatting data for comparison line chart
  const generateSeriesArray = useCallback(() => {
    switch (metric) {
      case 'price_to_rent_ratio':
        return [
          {
            data: generateCorrectTimelineData(historicalRegionMetricData, timeline), xAccessor: 'period_end', yAccessor: (d) => d.median_sales_price, metric: 'median_sales_price',
          },
          {
            data: generateCorrectTimelineData(historicalRegionMetricData2, timeline), xAccessor: 'period_end', yAccessor: (d) => d.median_rent, metric: 'median_rent',
          },
        ];
      default:
        return [{
          data: generateCorrectTimelineData(historicalRegionMetricData, timeline), xAccessor: 'period_end', yAccessor: (d) => d[metric], metric,
        }];
    }
  }, [metric, historicalRegionMetricData, historicalRegionMetricData2, timeline]);

  // Calculate growth percentage of metric across timeline
  const calculateGrowth = useCallback(() => {
    const presentDate = moment();

    let subset = historicalRegionMetricData;
    if (timeline !== 'max') {
      subset = historicalRegionMetricData.filter((record) => (presentDate.diff(moment(record.period_end), 'years', true) <= timeline));
    }

    if (subset === undefined
          || subset === null
          || subset.length === 0) {
      return 'Error';
    }

    const lastVal = subset[subset.length - 1];
    const firstVal = subset[0];

    if (lastVal[metric] === undefined || firstVal[metric] === undefined) {
      return 'Error';
    }

    return (100 * ((lastVal[metric] - firstVal[metric]) / firstVal[metric])).toFixed(2);
  }, [historicalRegionMetricData, metric, timeline]);

  // Load in historical region metric data and averages across state and nation
  useEffect(() => {
    if (regionRecord.region_id !== '' && metric !== '') {
      const source = axios.CancelToken.source();

      switch (metric) {
        case 'price_to_rent_ratio':
          axiosFindHistoricalRegionData(regionRecord.region_id, 'median_sales_price', source).then(
            (result) => {
              setHistoricalRegionMetricData(result.data);
              // This gets passed on to the parent
              setHistoricalData(result.data);
            },
          );
          axiosFindHistoricalRegionData(regionRecord.region_id, 'median_rent', source).then(
            (result) => {
              setHistoricalRegionMetricData2(result.data);
            },
          );
          break;
        default:
          axiosFindHistoricalRegionData(regionRecord.region_id, metric, source).then(
            (result) => {
              setHistoricalRegionMetricData(result.data);
              // This gets passed on to the parent
              setHistoricalData(result.data);
            },
          );
      }
    }
  }, [metric, regionRecord]);

  // Return the actual graph
  const RegionHistoricalGraph = useCallback(() => {
    if (metric === '') {
      return <div>No Metric Selected</div>;
    } if (generateCorrectTimelineData(historicalRegionMetricData, timeline).length === 0) {
      return (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: 100,
          height: '90%',
          alignItems: 'center',
        }}
        >
          Unavailable
        </Box>
      );
    }
    return (
      <CustomLineChart
        seriesArray={generateSeriesArray()}
        nice
      />
    );
  }, [metric, timeline, historicalRegionMetricData, historicalRegionMetricData2]);

  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: '10px', padding: '1em', height: '100%', display: 'flex', flexDirection: 'column',
      }}
    >
      <div className="sideBySide" style={{ justifyContent: 'space-between', width: '100%' }}>
        <div>
          <Typography>
            <b>
              {(metric !== undefined) ? allMetrics[metric].display_name : ''}
              {' '}
              Growth
              {' '}
            </b>
          </Typography>
          {(calculateGrowth() === 'Error') ? ''
            : (
              <div className="sideBySide">
                {(calculateGrowth() >= 0) ? (
                  <NorthIcon sx={{
                    fontSize: 16, color: 'green', position: 'relative', right: '5px', marginTop: '5px',
                  }}
                  />
                ) : (
                  <SouthIcon sx={{
                    fontSize: 16, color: 'red', position: 'relative', right: '5px', marginTop: '5px',
                  }}
                  />
                )}
                <Typography
                  color={calculateGrowth() >= 0 ? 'green' : 'red'}
                  sx={{ fontSize: 16 }}
                >
                  {calculateGrowth()}
                  %
                </Typography>
              </div>
            )}
        </div>
        <FormControl sx={{ minWidth: '125px', marginRight: '15px' }} size="small">
          <InputLabel id="demo-simple-select-label">Timeline</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="metric-select"
            value={timeline}
            onChange={(event) => setTimeline(event.target.value)}
            label="Timeline"
          >
            <MenuItem value={5}>5 years</MenuItem>
            <MenuItem value={10}>10 years</MenuItem>
            <MenuItem value="max">Max</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ flexGrow: 1 }}>
        <RegionHistoricalGraph />
      </div>
    </Paper>
  );
}
